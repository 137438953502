import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, Modal, Button, Form, Select, Divider, Row, Col, Tooltip, message } from 'antd';
import { SettingsIcon } from '../../../custom_shared/icons';
import { ClickableIcon } from '../../../custom_shared/components';
import { useAppStore } from '../../stores';
import { useStore } from '../../../common/stores';
import { Utils, InstanceUtils } from '../../misc';
import { SettingsFormValues } from '../../types';
import { LayoutSettingsFactory } from '../../misc';
import { LayoutSettingsBaseModel } from '../../models';
import './SettingsModal.less';

type NewSessionParameters = {
    ForcedLayoutType: string;
    NumberOfLayers?: number;
    NumberOfSections?: number
};

const tenNumberArray = Utils.createNumberArray(10);

const SettingsModal: React.FC = () => {
    const { tabsStore } = useStore();

    const appStore = useAppStore();

    const { tab, settingsStore, actionStore, documentVisualStore, sessionStore } = appStore;

    const [form] = Form.useForm<SettingsFormValues>();

    const [visible, setVisible] = React.useState(false);

    const [staringNewSession, setStartingNewSession] = React.useState(false);

    const formId = React.useMemo(() => `contract-ingestion-settings-${tab.id}`, [tab]);

    const onClose = () => {
        setVisible(false);

        setTimeout(() => {
            settingsStore.setChangedLayoutType(null);
            form.resetFields();
        }, 500);
    };

    const getNewSessionParameters = (layoutSettings: LayoutSettingsBaseModel): NewSessionParameters => {
        const parameters = { ForcedLayoutType: layoutSettings.type };

        if (InstanceUtils.isLayersLayoutSettings(layoutSettings)) {
            return { ...parameters, NumberOfLayers: layoutSettings.numberOfLayers };
        }

        if (InstanceUtils.isLayersWithSectionsLayoutSettings(layoutSettings)) {
            return {
                ...parameters,
                NumberOfLayers: layoutSettings.numberOfLayers,
                NumberOfSections: layoutSettings.numberOfSections
            };
        }

        if (InstanceUtils.isSectionsLayoutSettings(layoutSettings)) {
            return { ...parameters, NumberOfSections: layoutSettings.numberOfSections };
        }

        return parameters;
    };

    const onLayoutChange = async (layoutSettings: LayoutSettingsBaseModel) => {
        setStartingNewSession(true);

        const resp = await sessionStore.startSession(
            appStore.appId,
            appStore.packageId,
            getNewSessionParameters(layoutSettings)
        );

        if (!resp.isOk()) {
            message.error(resp.error.data ? resp.error.data.title : resp.error.text);
            setStartingNewSession(false);
            return;
        }

        resp.map(({ sessionId }) =>
            tabsStore.setSessionTabReloading({
                sessionId,
                packageName: appStore.packageName,
                packageId: appStore.packageId
            })
        );

        sessionStore.updateSession(appStore.sessionStore.sessionId, 'Archived');
        appStore.dispose();
    };

    const onFinish = async (formValues: SettingsFormValues) => {
        const layoutSettings = LayoutSettingsFactory.createSettingFromFormValues(formValues);

        if (settingsStore.layoutSettings && settingsStore.layoutSettings.type !== layoutSettings.type) {
            return await onLayoutChange(layoutSettings);
        }

        settingsStore.setLayoutSettings(layoutSettings);
        documentVisualStore.setShowBlocks(formValues.showBlocks);
        actionStore.rebuildTabs();

        onClose();
    };

    const onValuesChange = (changedValues: Partial<SettingsFormValues>) => {
        if (changedValues.type) {
            settingsStore.setChangedLayoutType(changedValues.type);
        }
    };

    const layoutTypeFormItem = () => (
        <Form.Item key="type" name="type" label="Layout">
            <Select
                options={settingsStore.layoutSettingsOptions.map(settings => ({
                    label: settings.label,
                    value: settings.type
                }))}
            />
        </Form.Item>
    );

    const numberOfLayersFormItem = () => (
        <Form.Item key="layers" name="numberOfLayers" label="Number of layers">
            <Select options={tenNumberArray.map(option => ({ label: option, value: option }))} />
        </Form.Item>
    );

    const numberOfSectionsFormItem = () => (
        <Form.Item key="sections" name="numberOfSections" label="Number of sections">
            <Select options={tenNumberArray.map(option => ({ label: option, value: option }))} />
        </Form.Item>
    );

    const getFormComponents = (layoutSettings: unknown) => {
        const components: JSX.Element[] = [];

        if (!InstanceUtils.isLayoutSettings(layoutSettings)) {
            return [];
        }

        const hasLayers = InstanceUtils.hasLayers(layoutSettings);
        const hasSections = InstanceUtils.hasSections(layoutSettings);

        components.push(layoutTypeFormItem());

        if (hasLayers) {
            components.push(numberOfLayersFormItem());
        }

        if (hasSections) {
            components.push(numberOfSectionsFormItem());
        }

        return components;
    };

    React.useEffect(() => {
        if (settingsStore.selectedLayoutSettings) {
            const showBlocks = form.getFieldValue('showBlocks');
            form.resetFields();
            form.setFieldsValue({ showBlocks });
        }
    }, [form, settingsStore.selectedLayoutSettings]);

    const getInitialValues = () => {
        let initialValues: SettingsFormValues = {
            showBlocks: documentVisualStore.showBlocks,
            type: settingsStore.selectedLayoutSettings?.type ?? 'Basic'
        };

        const hasLayers = InstanceUtils.hasLayers(settingsStore.selectedLayoutSettings);
        const hasSections = InstanceUtils.hasSections(settingsStore.selectedLayoutSettings);

        if (hasLayers) {
            initialValues = {
                ...initialValues,
                numberOfLayers: settingsStore.selectedLayoutSettings.numberOfLayers
            };
        }

        if (hasSections) {
            initialValues = {
                ...initialValues,
                numberOfSections: settingsStore.selectedLayoutSettings.numberOfSections
            };
        }

        return initialValues;
    };

    return (
        <>
            <Modal
                className="contract-ingestion-settings-modal"
                title="Settings"
                visible={visible}
                maskClosable={false}
                closable={false}
                width={600}
                destroyOnClose
                forceRender
                centered
                footer={[
                    <Button
                        key="cancel-button"
                        className="light alpha-dialog-button"
                        onClick={onClose}
                        disabled={staringNewSession}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="save-button"
                        type="primary"
                        htmlType="submit"
                        form={formId}
                        loading={staringNewSession}
                        disabled={staringNewSession}
                    >
                        Save changes
                    </Button>
                ]}
            >
                <Form
                    id={formId}
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                    preserve={false}
                    initialValues={getInitialValues()}
                >
                    {Utils.groupArray(getFormComponents(settingsStore.selectedLayoutSettings), 2).map(
                        (componentRow, index) => (
                            <Row key={index} gutter={20}>
                                {componentRow.map(component => (
                                    <Col key={component.key} span={12}>
                                        {component}
                                    </Col>
                                ))}
                            </Row>
                        )
                    )}

                    <Divider />

                    <Form.Item name="showBlocks" valuePropName="checked">
                        <Checkbox>Toggle found blocks</Checkbox>
                    </Form.Item>
                </Form>
            </Modal>

            <Tooltip title="Settings">
                <ClickableIcon
                    icon={SettingsIcon}
                    onClick={() => setVisible(true)}
                    disabled={actionStore.actionsDisabled}
                />
            </Tooltip>
        </>
    );
};

export default observer(SettingsModal);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomInputNumber, CustomInputNumberProps } from '../../../custom_shared/components';
import { WithInput } from '../../types';
import { useInputHighlighting, useGlobalInputState } from '../../hooks';
import { Regex } from '../../misc';
import { TypeUtils } from '../../../custom_shared/misc';

type Props = WithInput<CustomInputNumberProps>;

const CustomDecimalInputWrapper: React.FC<Props> = (props: Props) => {
    const { input, ...rest } = props;

    const [containerRef, setContainerRef] = React.useState<React.RefObject<HTMLDivElement> | null>(null);

    const { containerStyle } = useInputHighlighting(input, containerRef);

    const globalInputState = useGlobalInputState(props);

    return (
        <CustomInputNumber
            containerStyle={containerStyle}
            getContainerRef={setContainerRef}
            autoComplete="off"
            precision={2}
            formatter={value => (TypeUtils.isString(value) ? value.replace(Regex.decimalRegex, ',') : '')}
            parser={value => (TypeUtils.isString(value) ? value.replace(Regex.commaRegex, '') : '')}
            hideStepperButton
            {...rest}
            {...globalInputState}
        />
    );
};

export default observer(CustomDecimalInputWrapper);

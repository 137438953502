import { CustomRuntimeInput } from '../types';
import { FormInputFieldData, PackageFieldResult } from '../../common/services/types';
import { DocumentBlockProps, HighlightBlockProps } from '../../documents/stores/DocumentVisualStore';
import { TypeUtils } from '.';

type InputWithFieldData = {
    meta: {
        field: FormInputFieldData
    }
} & CustomRuntimeInput;

export default class DocumentHelper {
    static hasValidBlockType<T extends { meta: Record<string, unknown> }>(input: T) {
        return DocumentHelper.hasFormInputFieldData(input) && input.meta.field.bt !== 'PAGE_BLOCK';
    }

    static hasFormInputFieldData<T extends { meta: Record<string, unknown> }>(input: T): input is T & InputWithFieldData {
        return !!(input.meta && typeof input.meta === 'object' && 'field' in input.meta);
    }

    static createDocumentBlocks(
        blockId: string,
        field: FormInputFieldData,
        pageWidth: number,
        packageId: string,
        additionalHighlightBlockProps: Pick<HighlightBlockProps, 'zIndex'> = {}
    ): DocumentBlockProps {
        const sizeCoefficient = pageWidth / field.pw;
        const bottomPos = field.y * sizeCoefficient - field.h * sizeCoefficient;

        return {
            id: blockId,
            blockProps: {
                bottom: bottomPos,
                height: field.h * sizeCoefficient,
                left: field.x * sizeCoefficient,
                page: field.p,
                position: 'absolute',
                visibility: 'visible',
                width: field.w * sizeCoefficient,
                ...additionalHighlightBlockProps
            },
            fieldData: { ...field, pId: packageId }
        };
    }

    static prepareDocumentBlocks(
        inputs: CustomRuntimeInput[],
        pageWidth: number,
        packageId: string,
        blockIdField: 'guid' | 'id' = 'id'
    ): DocumentBlockProps[] {
        return inputs
            .filter(DocumentHelper.hasValidBlockType)
            .map((input: InputWithFieldData & { guid?: string }) =>
                DocumentHelper.createDocumentBlocks(
                    blockIdField === 'guid' && TypeUtils.isString(input.guid) ? input.guid : input.id,
                    input.meta.field,
                    pageWidth,
                    packageId
                )
            );
    }

    static preparePackageFieldBlocks(fieldSearchResults: PackageFieldResult[], pageWidth: number, packageId: string) {
        return fieldSearchResults.map(fieldResult => {
            const field = {
                fieldId: fieldResult.fieldId,
                p: fieldResult.page,
                x: fieldResult.x,
                y: fieldResult.y,
                w: fieldResult.w,
                h: fieldResult.h,
                pw: fieldResult.pageWidth,
                ph: fieldResult.pageHeight,
                bt: fieldResult.blockType,
                pId: fieldResult.packageId,
                area: fieldResult.w * fieldResult.h
            };

            return DocumentHelper.createDocumentBlocks(field.fieldId, field, pageWidth, packageId);
        });
    }
}

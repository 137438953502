import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InstanceUtils } from '../../misc';
import { LayoutRendererProps } from '../../componentTypes';
import {
    BasicLayoutRenderer,
    LayersLayoutRenderer,
    LayersWithSectionsLayoutRenderer,
    SectionsLayoutRenderer
} from '..';

const LayoutRenderer: React.FC<LayoutRendererProps> = ({ tab, ...rest }: LayoutRendererProps) => {
    if (InstanceUtils.isBasicTab(tab)) {
        return <BasicLayoutRenderer tab={tab} {...rest} />;
    }

    if (InstanceUtils.isLayersTab(tab)) {
        return <LayersLayoutRenderer tab={tab} {...rest} />;
    }

    if (InstanceUtils.isLayersWithSectionsTab(tab)) {
        return <LayersWithSectionsLayoutRenderer tab={tab} {...rest} />;
    }

    if (InstanceUtils.isSectionsTab(tab)) {
        return <SectionsLayoutRenderer tab={tab} {...rest} />;
    }

    console.error(`Layout renderer for tab type '${tab.type}' is not registered`);
    return null;
};

export default observer(LayoutRenderer);

import { LayerSettingsDto } from '../../types';

export default class LayerSettingsModel {
    readonly layerNumber: number;
    readonly layerId: string;
    readonly numberOfSections: number;

    constructor(data: LayerSettingsDto) {
        this.layerNumber = data.layerNumber;
        this.layerId = data.layerId;
        this.numberOfSections = data.numberOfSections;
    }

    get dto(): LayerSettingsDto {
        return {
            layerNumber: this.layerNumber,
            layerId: this.layerId,
            numberOfSections: this.numberOfSections
        };
    }
}

import * as React from 'react';
import { DocumentVisualStore } from '../stores';
import { observer } from 'mobx-react-lite';
import * as _ from 'lodash';
import { ImagePreviewSize } from '../types/types';
import { DocumentBlockProps } from '../stores/DocumentVisualStore';

type Props = {
    documentStore: DocumentVisualStore;
    packageId: string;
    packageIndexDate: string;
    sessionId?: string;
    hasLeftContainer?: boolean;
    minimapId?: string;
    defaultScale?: number;
    targetWindow: Window;
    fieldSearchMode?: boolean;
    highlightBlockCallback?: (inputId: string | undefined) => void
};

export const PdfImageViewer: React.FC<Props> = ({
    documentStore,
    packageId,
    packageIndexDate,
    sessionId,
    minimapId,
    hasLeftContainer = true,
    defaultScale,
    targetWindow,
    fieldSearchMode,
    highlightBlockCallback
}) => {
    const initialPageWidth = hasLeftContainer ? 1000 : 1600;
    const initialPageHeight = 1414;
    const [pageHeight, setPageHeight] = React.useState(initialPageHeight);
    const [pageWidth, setPageWidth] = React.useState<number | undefined>(undefined);
    const [loadedImgCount, setLoadedImgCount] = React.useState(0);
    const pageGap = 30;

    
    const calcPageWidth = React.useCallback(() => {
        if (minimapId) {
            var minimapContainer = targetWindow.document.getElementById(minimapId);

            if (minimapContainer) {
                var docContent = minimapContainer.children[0];
                setPageWidth(docContent.clientWidth);
            }
        } else {
            setPageWidth(1000);
        }
    }, [minimapId, targetWindow]);

    const initPageCoodrinates = React.useCallback(() => {
        const element = targetWindow.document.getElementById(`${sessionId}-${packageId}-0`);
        let height: number;
        if (element) {
            const rect = element.getBoundingClientRect();
            height = rect.height;
            setPageHeight(height - pageGap);
        } else {
            return;
        }
        
        let newCoords: number[] = [0];
        documentStore.filteredLargePreviews.slice().sort(documentStore.sortByPageNumber).forEach((p, i) => {
            const previewHeight = p.orientation === 'Portrait' ? height : initialPageWidth;
            const prevCoord = newCoords[i] || 0;
            newCoords.push(prevCoord + previewHeight + pageGap);
        });
        newCoords = newCoords.slice().sort((a, b) => a - b);
        documentStore.setPageCoodinates(newCoords);
    }, [documentStore, initialPageWidth, packageId, sessionId, targetWindow]);

    const setMinimapLoadingFinishedFlag = React.useCallback((flag: boolean) => {
        if (!minimapId) {
            return;
        }
        let minimapContainerChild = targetWindow.document.getElementById(minimapId);

        if (!minimapContainerChild) {
            return;
        }

        let container = minimapContainerChild.parentElement!.parentElement!;

        if (!container) {
            return;
        }

        let minimap = container.children[0];

        if (!minimap) {
            return;
        }

        let minimapDiv = minimap as HTMLDivElement;
        minimapDiv.setAttribute('data-id-finished', `${flag}`);
    }, [minimapId, targetWindow]);

    React.useEffect(() => {
        setMinimapLoadingFinishedFlag(false);
        documentStore.prepareDocumentPages(packageId, packageIndexDate, hasLeftContainer ? ImagePreviewSize.DocumentSize : ImagePreviewSize.LargeDocumentSize, defaultScale);
        calcPageWidth();
    },              [calcPageWidth, documentStore, hasLeftContainer, packageId, packageIndexDate, defaultScale, setMinimapLoadingFinishedFlag]);

    // React.useEffect(() => {
    //     if (documentStore.highlightedInputId) {
    //         initPageCoodrinates();
    //     }
    // },              [documentStore.highlightedInputId]);

    React.useEffect(() => {
        initPageCoodrinates();
    },              [documentStore.scale, initPageCoodrinates]);
    
    // React.useEffect(() => {
    //     setDimensions();
    // },              [documentStore.largePreviews]);

    // const setDimensions = () => {
    //     if (!minimapId) { 
    //         return; 
    //     }

    //     const container = targetWindow.document.getElementById(minimapId);

    //     if (!container) {
    //         return; 
    //     }

    //     const children = container.getElementsByClassName('alpha-doc-content');
    //     const docContainer = children && children.length ? children[0] as HTMLElement : undefined;

    //     if (!docContainer) {
    //         return; 
    //     }

    //     setTimeout(() => {
    //         const newScale = docContainer.offsetWidth / initialPageWidth;
    //         setPageHeight(initialPageHeight * newScale);

    //         if (documentStore.pageCoordinates) {
    //             documentStore.setScale(newScale);
    //         }
    //     },         50);
    // };

    const highlightBlock = (page: number) => {
        const { highlightedBlockProps } = documentStore;

        if (!highlightedBlockProps) {
            return null;
        }

        return (
            documentStore.highlightedBlockProps && page === documentStore.highlightedBlockProps.page && (
                <div
                    id={sessionId ? `${sessionId}-${packageId}-highlight` : `${_.random()}-highlight`}
                    className={fieldSearchMode ? '' : 'field-highlight-block'}
                    onClick={() => handleBlockClick(documentStore.highlightedInputId, undefined)}
                    data-id={`highlight-${documentStore.highlightedInputId}`}
                    style={{
                        ...documentStore.highlightedBlockProps,
                        bottom: highlightedBlockProps.bottom + '%',
                        left: highlightedBlockProps.left + '%',
                        width: highlightedBlockProps.width + '%',
                        height: highlightedBlockProps.height + '%',
                    }}
                />
            )
        );
    };

    const handleBlockClick = (inputId: string | undefined, documentBlock: DocumentBlockProps | undefined) => {
        documentStore.highlightBlockByInputId(inputId, documentBlock);
        if (highlightBlockCallback) {
            highlightBlockCallback(inputId);
        }
    };

    const blocks = (page: number) => {
        if (!documentStore.showBlocks) {
            return null;
        }
        const documentBlocks = documentStore.documentBlocks ? documentStore.documentBlocks.filter(db => db.blockProps.page === page && db.fieldData.pId === packageId) : undefined;
        return (
            documentBlocks && documentBlocks.map(db =>
                <div
                    key={`${db.id}${db.fieldData.x}${db.fieldData.y}`}
                    id={db.id ? `${db.id}-block` : `${_.random()}-block`}
                    onClick={() => {
                        if (!fieldSearchMode) {
                            handleBlockClick(db.id, db);
                        }
                    }}
                    className={fieldSearchMode ? 'field-search-block' : 'field-document-block'}
                    style={{
                        ...db.blockProps,
                    }}
                />
            )
        );
    };

    const handleImageLoading = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const img = event.target as HTMLImageElement;
        const imgContainer = img.parentNode as HTMLDivElement;
        imgContainer.style.height = 'auto';
        
        setLoadedImgCount(loadedImgCount + 1);
        if (loadedImgCount >= documentStore.totalPages - 1 && minimapId) {
            const minimapContainerChild = targetWindow.document.getElementById(minimapId);
            if (minimapContainerChild) {
                const container = minimapContainerChild.parentElement!.parentElement!;
                const minimap = container.children[0];
                const minimapViewport = minimap.children[0];
                // Scroll to trigger minimap refresh and show loaded previews
                minimapViewport.scrollIntoView();
                container.scrollBy(0, 1);
                container.scrollBy(0, -1);
                targetWindow.dispatchEvent(new Event('resize'));
            }
            setMinimapLoadingFinishedFlag(true);
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            {documentStore.filteredLargePreviews && pageWidth != null &&
                documentStore.filteredLargePreviews
                    .slice()
                    .sort(documentStore.sortByPageNumber)
                    .map((preview, index) =>
                        index <= loadedImgCount
                            ? (
                                <div key={preview.page} id={`${sessionId}-${packageId}-${index}`}>
                                    <div 
                                        className="pdf-page-container"
                                        style={{ 
                                            position: 'relative', 
                                            display: 'inline-block',
                                            width: '100%'
                                        }}
                                    >
                                        <img
                                            data-id={`${preview.page}`}
                                            className="pdf-page-img pdf-page"
                                            src={preview.url}
                                            style={{
                                                width: '100%'
                                            }}
                                            onLoad={(e) => handleImageLoading(e)}
                                        />
                                        {highlightBlock(preview.page)}
                                        {blocks(preview.page)}
                                    </div>

                                    <div style={{ height: pageGap * documentStore.scale }} />
                                </div>
                            )
                            : (<div key={preview.page} id={`${sessionId}-${packageId}-${index}`}>
                                <div className="pdf-page-container" style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                    <div
                                        data-id={`${preview.page}`}
                                        className="pdf-page-stub pdf-page"
                                        style={{
                                            height: preview.orientation === 'Portrait' 
                                                ? documentStore.calculatePreviewHeight(preview.imgSize!.width, pageWidth, preview.imgSize!.height) * documentStore.scale 
                                                : pageWidth * documentStore.scale,
                                            width: preview.orientation === 'Portrait' ? pageWidth * documentStore.scale : pageHeight * documentStore.scale,
                                            margin: '0 auto'
                                        }}
                                    />
                                    {highlightBlock(preview.page)}
                                    {blocks(preview.page)}
                                </div>
                                <div style={{ height: pageGap * documentStore.scale }} />
                            </div>)
                    )}
        </div>
    );
};

export default observer(PdfImageViewer);
import * as React from 'react';
import { Utils } from '../../common/misc/Utils';

export const generateDocumentIcon = (documentName: string, isMultiple: boolean) => {
    let colorClass: string = 'default';
    if (documentName) {
        if (documentName.toLowerCase().endsWith('.pdf')) {
            colorClass = 'doc-pdf';
        }

        if (Utils.isExcelByExtension(documentName)) {
            colorClass = 'doc-xls extra-height';
        }

        if (documentName.toLowerCase().endsWith('.doc') || documentName.toLowerCase().endsWith('.docx')) {
            colorClass = 'doc-word';
        }
        if (isMultiple) {
            colorClass = 'multiple-doc-pdf';
        }

        if (documentName.toLowerCase().endsWith('.xml')) {
            colorClass = 'doc-xml';
        }
    }

    return(
        <i className={`alpha-icon sm ${colorClass}`} />
    );
};

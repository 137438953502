import * as React from 'react';
import { TabModel } from '../../common/types/TabModel';
import { useStore } from '../../common/stores';
import AppStore from './AppStore';

const StoreContext = React.createContext<AppStore | null>(null);

type Props = {
    tab: TabModel;
    children: React.ReactNode
};

export const AppStoreProvider: React.FC<Props> = ({ tab, children }) => {
    const rootStore = useStore();

    const store = new AppStore(tab, rootStore);

    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export const useAppStore = () => {
    const store = React.useContext(StoreContext);

    if (!store) {
        throw new Error('You have forgot to use StoreProvider, shame on you.');
    }

    return store;
};

import * as React from 'react';
import MaskInput, { IInputProps } from 'react-maskinput';
import { CustomInputLabel } from '..';
import './CustomMaskedInput.less';

export interface CustomMaskedInputProps extends IInputProps {
    label: string;
    className?: string;
    required?: boolean;
    topRightNode?: React.ReactNode;
    containerStyle?: React.CSSProperties;
    hasError?: boolean;
    disabled?: boolean;
    getContainerRef?: (containerRef: React.RefObject<HTMLDivElement>) => void
}

const CustomMaskedInput: React.FC<CustomMaskedInputProps> = (props: CustomMaskedInputProps) => {
    const { label, required, topRightNode, containerStyle, hasError, getContainerRef, onChange, ...inputProps } = props;

    const containerRef = React.useRef<HTMLDivElement>(null);

    const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);

    const [hasValue, setHasValue] = React.useState(false);

    const updateHasValue = React.useCallback(() => {
        if (!inputRef) {
            return;
        }

        setHasValue(!!inputRef.value);
    }, [inputRef]);

    React.useEffect(() => {
        if (getContainerRef) {
            getContainerRef(containerRef);
        }
    }, [getContainerRef]);

    React.useEffect(() => {
        updateHasValue();
    }, [updateHasValue]);

    const getContainerClassName = () => {
        let className = 'custom-masked-input-container';

        if (hasError) {
            className += ' has-error';
        }

        if (inputProps.disabled) {
            className += ' disabled';
        }

        return className;
    };

    return (
        <div ref={containerRef} className={getContainerClassName()} style={containerStyle}>
            {!!topRightNode && <div className="top-right-node-container">{topRightNode}</div>}

            <MaskInput
                className={`custom-masked-input ${hasValue ? 'not-empty' : ''}`}
                getReference={setInputRef}
                onChange={event => {
                    updateHasValue();

                    if (onChange) {
                        onChange(event);
                    }
                }}
                {...inputProps}
            />

            <CustomInputLabel label={label} required={required} />
        </div>
    );
};

export default CustomMaskedInput;

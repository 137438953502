import * as React from 'react';
import { throttle } from 'lodash';

export default (ref: React.RefObject<HTMLDivElement>, throttleInterval = 200) => {
    const [height, setHeight] = React.useState(0);
    const [width, setWidth] = React.useState(0);

    const throttledSetSize = React.useMemo(
        () =>
            throttle((newHeight, newWidth) => {
                setHeight(newHeight);
                setWidth(newWidth);
            }, throttleInterval),
        [throttleInterval]
    );

    React.useEffect(() => {
        const setInitialSize = () => {
            if (ref.current) {
                throttledSetSize(ref.current.clientHeight, ref.current.clientWidth);
            }
        };

        setInitialSize();

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                throttledSetSize(entry.target.clientHeight, entry.target.clientWidth);
            }
        });

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [ref, throttledSetSize]);

    return {
        height,
        width
    };
};

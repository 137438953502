import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useAppStore } from '../../stores';
import { useElementSize } from '../../../custom_shared/hooks';
import PackageNameLabel from './PackageNameLabel';
import TextSearch from './TextSearch';
import FullScreenButton from './FullScreenButton';
import DocumentViewer from './DocumentViewer';
import './BottomDocumentPreview.less';

const panelKey = 'panelKey';

const BottomDocumentPreview: React.FC = () => {
    const { documentPreviewStore } = useAppStore();

    const toggleExpanded = () => documentPreviewStore.setExpanded(!documentPreviewStore.expanded);

    const containerRef = React.useRef<HTMLDivElement>(null);

    const leftHeaderContainerRef = React.useRef<HTMLDivElement>(null);

    const rightHeaderContainerRef = React.useRef<HTMLDivElement>(null);

    const [activeDiv, setActiveDiv] = React.useState<HTMLDivElement | null>(null);

    const [mouseUpOnSameElement, setMouseUpOnSameElement] = React.useState<boolean>(false);

    const { height } = useElementSize(containerRef);

    const onHeaderMouseDown = (ref: HTMLDivElement | null) => setActiveDiv(ref);

    const onHeaderMouseUp = (ref: HTMLDivElement | null) => {
        if (ref === activeDiv) {
            setMouseUpOnSameElement(true);
        } else {
            setMouseUpOnSameElement(false);
        }

        setActiveDiv(null);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    React.useEffect(() => {
        documentPreviewStore.setBottomPreviewHeight(height);
    }, [height, documentPreviewStore]);

    React.useEffect(() => {
        return () => documentPreviewStore.setBottomPreviewHeight(0);
    }, [documentPreviewStore]);

    const renderHeader = () => {
        return (
            <div className="contract-ingestion-document-preview-header">
                <div
                    ref={leftHeaderContainerRef}
                    className="left-container"
                    onMouseDown={() => onHeaderMouseDown(leftHeaderContainerRef.current)}
                    onMouseUp={() => onHeaderMouseUp(leftHeaderContainerRef.current)}
                >
                    {documentPreviewStore.expanded ? (
                        <DownOutlined className="expand-icon" />
                    ) : (
                        <UpOutlined className="expand-icon" />
                    )}

                    <PackageNameLabel />
                </div>

                {documentPreviewStore.expanded && (
                    <div
                        ref={rightHeaderContainerRef}
                        className="right-container"
                        onClick={e => e.stopPropagation()}
                        onMouseDown={() => onHeaderMouseDown(rightHeaderContainerRef.current)}
                        onMouseUp={() => onHeaderMouseUp(rightHeaderContainerRef.current)}
                    >
                        <TextSearch />
                        <FullScreenButton />
                    </div>
                )}
            </div>
        );
    };

    return (
        <div ref={containerRef} className="contract-ingestion-bottom-document-preview" onKeyDown={onKeyDown}>
            <Collapse
                bordered={false}
                activeKey={documentPreviewStore.expanded ? [panelKey] : []}
                expandIcon={() => null}
                onChange={mouseUpOnSameElement ? toggleExpanded : undefined}
            >
                <Collapse.Panel key={panelKey} header={renderHeader()}>
                    <div className="contract-ingestion-document-preview-container">
                        <DocumentViewer />
                    </div>
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default observer(BottomDocumentPreview);

import { Regex, ValidationMessages } from '.';
import { TypeUtils } from '../../custom_shared/misc';

export default class Validators {
    static unlimitedFieldValidator(_: unknown, value: unknown, setError: (error: string) => void) {
        if (TypeUtils.isString(value) && value.trim().length > 0 && !Regex.unlimitedRegex.test(value)) {
            setError(ValidationMessages.unlimitedFieldValidationMessage);
            return Promise.reject(new Error(ValidationMessages.unlimitedFieldValidationMessage));
        }

        setError('');
        return Promise.resolve();
    }
}

import { Button, Spin, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Utils } from '../misc/Utils';
import { DynamicUiUtils } from '../services';
import { PackageSetSessionPackage } from '../services/types';
import { MultiDocumentTabStore, MultiDocumentTabVisualStore, TabsStore } from '../stores';
import { TabModel } from '../types/TabModel';

type Props = {
    tab: TabModel;
    tabsStore: TabsStore;
    multiDocStore: MultiDocumentTabStore;
    multiDocVisualStore: MultiDocumentTabVisualStore
};

const MultiDocumentTabRenderer: React.FC<Props> = ({tab, multiDocStore, multiDocVisualStore}) => {
    const [switchingTab, setSwitchingTab] = React.useState<boolean>(false);

    React.useEffect(() => {
        multiDocStore.loadTabData();
    },              [multiDocStore]);

    React.useEffect(() => {
        const {ui} = multiDocStore;
        if (ui && ui.layoutConfiguration?.activeLayoutId && switchingTab) {
            multiDocVisualStore.closePreviousTab(tab);
            const packageName = multiDocStore.setSessionData?.packages?.find(p => p.packageId === ui.layoutConfiguration.activeLayoutId)?.packageName;
            multiDocVisualStore.openTabWithLayout(ui, tab, packageName);
        }
    },              [multiDocStore, multiDocStore.ui, multiDocVisualStore, switchingTab, tab]);

    if (!multiDocStore) {
        return null;
    }

    const columns: ColumnsType<PackageSetSessionPackage> = [{
        dataIndex: 'packageName',
        title: 'Document',
        render: (value: string) => (
            <div>
                {<i className={`alpha-icon sm ${Utils.generateDocumentIconClass(value)}`} style={{verticalAlign: 'middle'}}/>} {value}
            </div>
        )
    }, {
        dataIndex: 'createdTime',
        title: 'Created',
        render: (value: string) => (
            <div>
                {Utils.formatDateStringLong(value)}
            </div>
        )
    }, {
        dataIndex: 'updatedTime',
        title: 'Updated',
        render: (value: string) => (
            <div>
                {Utils.formatDateStringLong(value)}
            </div>
        )
    }, {
        dataIndex: 'state',
        title: 'State',
        render: (value: string) => DynamicUiUtils.getStateDisplayVal(value)
    }, {
        key: 'actions',
        title: '',
        width: 120,
        render: (value: unknown, pkg: PackageSetSessionPackage) => (
            <div className="row-actions">
                <Tooltip title="Start from scratch">
                    <i className={'alpha-icon xs reload'}/>
                </Tooltip>
                <Tooltip title="Switch to Analysis form">
                    <i className={'alpha-icon xs session-switch'} onClick={() => switchToLayout(pkg.packageId)}/>
                </Tooltip>
            </div>
        )
    }];

    const switchToLayout = async (pkgId: string) => {
        setSwitchingTab(true);
        multiDocStore.switchToLayout(pkgId);
    };

    if (multiDocStore.isLoading) {
        return (
            <div className="multi-document-loading">
                <Spin />
            </div>
        );
    }

    return (
        <div className="multidocument-tab">
            <div className="tab-header-content">
                <div className="main-header">
                    <div className="header-title">
                        {tab.metadata.packageName}
                    </div>
                    <div className="buttons">
                        <Tooltip title="Reload">
                            <Button 
                                type="link"
                                className="reload-button"
                            >
                                <i className="alpha-icon reload xs" />
                            </Button> 
                        </Tooltip>

                        <Button
                            key={0}
                            className="submit-button"
                            name={'submitButton'}
                            htmlType="submit"
                            type="primary"
                            size="large"
                        >
                            Submit
                        </Button>
                    </div>
                </div>
                <div className="sub-header">
                    <div className="sub-header-item">
                        Project: {tab.metadata.projectName}
                    </div>
                    <div className="sub-header-item">
                        Application: {tab.metadata.applicationData ? tab.metadata.applicationData.appName : null}
                    </div>
                </div>
            </div>
            <Table 
                rowKey={(r) => r.packageId}
                className="multidocument-table"
                rowSelection={{columnWidth: 24}}
                dataSource={multiDocStore.setSessionData?.packages}
                columns={columns}
                pagination={false}
            />
        </div>
    );
};

export default observer(MultiDocumentTabRenderer);
import * as React from 'react';
import { DynamicControlProps } from './types';
import { Divider } from 'antd';

export const DynamicDivider: React.FC<DynamicControlProps> = () => {
    return (
        <Divider/>
    );
};

export default DynamicDivider;
import * as React from 'react';
import UserProfile from './UserProfile';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserProfileControls = () => {
    return (
        <UserProfile/>
    );

};

export default UserProfileControls;
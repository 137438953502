import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Spin } from 'antd';
import { AskAlphaQuestionModel, AskAlphaErrorModel } from '../../models';
import { useAskAlphaStores } from '../../stores/context';
import ChatQuestion from './ChatQuestion';
import ChatErrorMessage from './ChatErrorMessage';
import './ChatDisplayItems.less';

const ChatDisplayItems: React.FC = () => {
    const { askAlphaStore } = useAskAlphaStores();

    const [refs, setRefs] = React.useState<Map<string, React.RefObject<HTMLDivElement>>>(new Map());

    const addRef = React.useCallback((id: string, ref: React.RefObject<HTMLDivElement>) => {
        setRefs(map => new Map(map.set(id, ref)));
    }, []);

    React.useEffect(() => {
        if (!askAlphaStore.lastDisplayItem) {
            return;
        }

        const ref = refs.get(askAlphaStore.lastDisplayItem.id);

        // Set last question position immediately when changing documents or navigating from another page
        if (askAlphaStore.uiBehaviour.scrollIntoViewImmediately) {
            ref?.current?.scrollIntoView({ block: 'start' });
            return;
        }

        // Smooth scrolling when receiving anwser to a question or error
        const timeout = setTimeout(() => {
            ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 50);

        return () => clearTimeout(timeout);
    }, [refs, askAlphaStore.uiBehaviour, askAlphaStore.lastDisplayItem, askAlphaStore.lastQuestion?.processing]);

    return (
        <div className="chat-display-items">
            {askAlphaStore.packagesImportInProcess ? (
                <div className="processing-document-overlay">
                    <Spin tip="Processing document..." />
                </div>
            ) : (
                askAlphaStore.displayItems.map(item => {
                    if (item instanceof AskAlphaQuestionModel) {
                        return <ChatQuestion key={item.id} question={item} addRef={addRef} />;
                    }

                    if (item instanceof AskAlphaErrorModel) {
                        return <ChatErrorMessage key={item.id} error={item} addRef={addRef} />;
                    }

                    return null;
                })
            )}
        </div>
    );
};

export default observer(ChatDisplayItems);

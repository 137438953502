import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';
import { GenericDecimalInput } from '../../components';

const config = {
    label: 'Payment of subsequent instalment'
};

const SubsequentInstalmentCountInput: InputWithoutSource = ({ input }) => (
    <GenericDecimalInput label={config.label} input={input} precision={0} />
);

SubsequentInstalmentCountInput.meta = {
    inputType: InputModel.name
};

export default observer(SubsequentInstalmentCountInput);

import { v4 as uuidv4 } from 'uuid';
import { omit } from 'lodash';
import { action, makeObservable, observable, computed, runInAction } from 'mobx';
import { QuestionResponseDto, QuestionDto } from '../types';
import { AskAlphaVisualStore } from '../stores';
import AskAlphaService from '../services/AskAlphaService';

export default class AskAlphaQuestionModel implements QuestionDto {
    readonly id: string;

    readonly message: string;

    readonly packageId: string;

    readonly createDate: Date;

    response: QuestionResponseDto | null = null;

    processing: boolean = false;

    get nodeIds() {
        if (!this.response || !this.response.packageFields.length) {
            return [];
        }

        return this.response.packageFields.map(f => f.field.id);
    }

    get hasAnswer() {
        return !!this.response;
    }

    get dto(): QuestionDto {
        return {
            id: this.id,
            message: this.message,
            response: this.response,
            packageId: this.packageId,
            createDate: this.createDate
        };
    }

    constructor(
        private readonly store: AskAlphaVisualStore,
        private readonly service: AskAlphaService,
        data: Partial<QuestionDto> & Pick<QuestionDto, 'message' | 'packageId'>
    ) {
        this.id = data.id ?? uuidv4();
        this.message = data.message;
        this.packageId = data.packageId;
        this.createDate = data.createDate ?? new Date();
        this.response = data.response ?? null;

        makeObservable(this, {
            response: observable,
            processing: observable,
            hasAnswer: computed,
            nodeIds: computed,
            setProcessing: action.bound,
            ask: action.bound
        });
    }

    setProcessing(processing: boolean) {
        this.processing = processing;
    }

    async ask(lastQuestionNodeIds: string[]) {
        try {
            this.setProcessing(true);

            const resp = await this.service.askQuestion(
                this.message,
                this.packageId,
                lastQuestionNodeIds,
                omit(this.store.settings.values, ['useContext'])
            );

            if (!resp.isOk()) {
                const message = resp.error.data ? resp.error.data.title : resp.error.text;
                this.store.addError(message);
                return;
            }

            resp.map(response => runInAction(() => (this.response = response)));
        } catch (err) {
            console.error(err);
        } finally {
            this.setProcessing(false);
        }
    }
}

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputModelWithSource } from '../../models';
import { InputWithSource } from '../../componentTypes';
import { GenericSelect } from '../../components';

const config = {
    label: 'NOC Period'
};

const NOCPeriodInput: InputWithSource = ({ input }) => <GenericSelect label={config.label} input={input} />;

NOCPeriodInput.meta = {
    inputType: InputModelWithSource.name
};

export default observer(NOCPeriodInput);

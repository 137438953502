import * as React from 'react';
import { DynamicControlProps } from './types';

export const DynamicLabel: React.FC<DynamicControlProps> = ({inputParams, onAction}) => {
    const actionId = inputParams.behavior && inputParams.behavior.onchange;
    const handleClick = () => {
        if (actionId) {
            onAction(inputParams.id, actionId);
        }
    };    

    const getValue = () => {
        if (inputParams.type === 'decimal') {
            return Number(inputParams.value.toString()).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return inputParams.value;
    };
    
    return (
        <span className="scan-results-label" onClick={handleClick} style={{cursor: actionId ? 'pointer' : 'unset' }}>
            {<span data-id-type="custom-label-name">{inputParams.name}</span>} {<span data-id-value="custom-label-value" style={{fontWeight: 500}}>{getValue()}</span>}
        </span>
    );
};

export default DynamicLabel;
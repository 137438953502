/* eslint-disable max-len */
import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {}

const MenuUnfoldIcon: React.FC<Props> = ({ width = 34, height = 34, style = {}, ...props }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: 'pointer', ...style }}
        {...props}
    >
        <rect width="34" height="34" rx="17" fill="black" fillOpacity="0.2" />
        <path
            d="M11.334 22.6719H21.4707C22.7012 22.6719 23.3105 22.0625 23.3105 20.8555V13.7012C23.3105 12.4941 22.7012 11.8848 21.4707 11.8848H11.334C10.1094 11.8848 9.49414 12.4883 9.49414 13.7012V20.8555C9.49414 22.0684 10.1094 22.6719 11.334 22.6719ZM11.3457 21.7285C10.7598 21.7285 10.4375 21.418 10.4375 20.8086V13.748C10.4375 13.1387 10.7598 12.8281 11.3457 12.8281H13.959V21.7285H11.3457ZM21.459 12.8281C22.0391 12.8281 22.3672 13.1387 22.3672 13.748V20.8086C22.3672 21.418 22.0391 21.7285 21.459 21.7285H14.8789V12.8281H21.459ZM12.8281 15.002C13.0098 15.002 13.1621 14.8438 13.1621 14.6738C13.1621 14.498 13.0098 14.3457 12.8281 14.3457H11.5742C11.3984 14.3457 11.2402 14.498 11.2402 14.6738C11.2402 14.8438 11.3984 15.002 11.5742 15.002H12.8281ZM12.8281 16.5195C13.0098 16.5195 13.1621 16.3613 13.1621 16.1855C13.1621 16.0098 13.0098 15.8633 12.8281 15.8633H11.5742C11.3984 15.8633 11.2402 16.0098 11.2402 16.1855C11.2402 16.3613 11.3984 16.5195 11.5742 16.5195H12.8281ZM12.8281 18.0312C13.0098 18.0312 13.1621 17.8848 13.1621 17.709C13.1621 17.5332 13.0098 17.3809 12.8281 17.3809H11.5742C11.3984 17.3809 11.2402 17.5332 11.2402 17.709C11.2402 17.8848 11.3984 18.0312 11.5742 18.0312H12.8281Z"
            fill="white"
        />
    </svg>
);

export default MenuUnfoldIcon;

import { BehaviorSubject } from 'rxjs';
import * as signalR from '@microsoft/signalr';

export default class PushClient {
    static RECONNECT_INTERVAL: number = 5000;
    public ConnectionStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public ReconnectAttemptCount: number = 0;
    public Connection: signalR.HubConnection;
    private connectionAttempted: boolean = false;    
    private url: string;

    constructor(url: string) {
        this.url = url;
        this.Connection = new signalR.HubConnectionBuilder().withUrl(url).withAutomaticReconnect([1000, 2000, 5000, 5000]).build();

        this.Connection.onreconnecting(() => {
            console.log(`Reconnecting to ${this.url}...`);
        });

        this.Connection.onreconnected(() => {
            console.log(`Reconnected to ${this.url}...`);
        });

        this.Connection.onclose(() => {
            console.log(`Connection closed to ${this.url}...`);
            this.ConnectionStatusSubject.next(false);
            this.connect();
        });

        if (process.env.NODE_ENV !== 'test') {
            if (!this.connectionAttempted) {                
                this.connect();
            }
        }  
    }
    
    private connect() {
        this.connectionAttempted = true;
        console.log(`Connecting to ${this.url}...`);

        this.Connection.start().then(() => {
            console.log(`Connected to ${this.url}...`);
            this.ConnectionStatusSubject.next(true);
            this.ReconnectAttemptCount = 0;
        }).catch(() => {
            this.ConnectionStatusSubject.next(false);
            console.info(`Wasn't able to connect to SignalR service (${this.url})`);
            setTimeout(() => {
                this.ReconnectAttemptCount++; this.connect(); 
            }, PushClient.RECONNECT_INTERVAL);
        });
    }
}
import * as React from 'react';

import { useAppStore } from '../stores';

const defaultHeight = '95%';

export default (tabId: string, rendererRef: React.RefObject<HTMLDivElement>): React.CSSProperties => {
    const { documentPreviewStore, tabStore } = useAppStore();

    const [height, setHeight] = React.useState<string | number>(defaultHeight);

    React.useEffect(() => {
        if (!rendererRef.current || tabStore.activeTabId !== tabId) {
            return;
        }

        if (documentPreviewStore.previewPosition === 'fullScreen') {
            setHeight(defaultHeight);
            return;
        }

        const rendererTopPosition = rendererRef.current.getBoundingClientRect().top;
        setHeight(window.innerHeight - documentPreviewStore.bottomPreviewHeight - rendererTopPosition);
    }, [
        tabId,
        tabStore.activeTabId,
        documentPreviewStore.previewPosition,
        documentPreviewStore.bottomPreviewHeight,
        rendererRef
    ]);

    return {
        height
    };
};

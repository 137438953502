import { DynamicInput } from './dynamiccontrols/DynamicInput';
import { DynamicCombobox } from './dynamiccontrols/DynamicCombobox';
import { DynamicCheckbox } from './dynamiccontrols/DynamicCheckbox';
import { DynamicRadioButton } from './dynamiccontrols/DynamicRadioButton';
import { DynamicDatePicker } from './dynamiccontrols/DynamicDatePicker';
import { DynamicMultiSelect } from './dynamiccontrols/DynamicMultiSelect';
import { DynamicButton } from './dynamiccontrols/DynamicButton';
import DynamicTextEditor from './dynamiccontrols/DynamicTextEditor';
import DynamicVisualTable from './dynamiccontrols/DynamicVisualTable';
import { DynamicResultTable } from './dynamiccontrols/DynamicResultTable';
import { DynamicDivider } from './dynamiccontrols/DynamicDivider';
import { DynamicLabel } from './dynamiccontrols/DynamicLabel';
import { SimpleContainerChildInput } from './dynamiccontrols/SimpleContainerChildInput';
import DynamicExcelPreview from './DocumentExcel';

export default {
    input: DynamicInput,
    dropdown: DynamicCombobox,
    checkbox: DynamicCheckbox,
    radio: DynamicRadioButton,
    datepicker: DynamicDatePicker,
    multipleselect: DynamicMultiSelect,
    multipleTagsSelect: DynamicMultiSelect,
    button: DynamicButton,
    texteditor: DynamicTextEditor,
    visualtable: DynamicVisualTable,
    resulttable: DynamicResultTable,
    divider: DynamicDivider,
    label: DynamicLabel,
    containerInputChild: SimpleContainerChildInput,
    dynamicExcelPreview: DynamicExcelPreview
};
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { TabModel } from '../../../common/types/TabModel';
import security from '../../../common/services/SecurityService';
import { useAppStore } from '../../stores';
import { Spin } from 'antd';

interface Props {
    tab: TabModel
}

const CustomBlazorApp: React.FC<Props> = ({ tab }) => {
    const { sessionStore, blazorFormStore } = useAppStore();

    React.useEffect(() => {
        loadWasm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        if (!sessionStore.isLoading && window.Blazor != null) {
            setTimeout(() => {
                initBlazorComponent();
            }, 100);
        } else {
            blazorFormStore.disposeBlazorComponent();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionStore.isLoading]);

    const loadWasm = async () => {
        const src = process.env.PUBLIC_URL + 'blazor-poc/_framework/blazor.webassembly.js';
        const existingScript = document.querySelector(`script[src="${src}"]`);
        
        if (!existingScript) {
            // @ts-ignore
            window.initializeComponent = function(component, params) {
                console.log('initializeComponent', component, params);
            };

            const script = document.createElement('script');
            script.src = src;
            document.body.appendChild(script);
            const dummyAntJsPlaceholder = document.createElement('div');
            dummyAntJsPlaceholder.id = 'dummyAntJsPlaceholder';
            dummyAntJsPlaceholder.setAttribute('antblazor-js', '');
            document.body.appendChild(dummyAntJsPlaceholder);
        }

        initBlazorComponent();
    };

    const initBlazorComponent = async () => {
        try {
            await loadBlazorComponent();
        } catch {
            setTimeout(() => {
                initBlazorComponent();
            }, 50);
        }
    };

    const loadBlazorComponent = async () => {
        let targetElement = document.getElementById(`${tab.id}-blazor-app-wrapper`);
        // @ts-ignore
        // eslint-disable-next-line no-undef
        const component = await Blazor.rootComponents.add(targetElement, 'blazor-app-form', 
            {
                applicationName: tab.metadata.applicationData.appName,
                sessionId: tab.metadata.runtimeSessionId,
                authToken: security.token
            });
        
        blazorFormStore.setBlazorComponent(component);
    };

    return (
        <>
            {sessionStore.isLoading ? <Spin /> : null}
            <div id={`${tab.id}-blazor-app-wrapper`} style={{ height: '100%' }} />
        </>
    );
};

export default observer(CustomBlazorApp);

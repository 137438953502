import * as React from 'react';
import { useLocalStore } from 'mobx-react-lite';
import { HomeVisualStore, PreviewsVisualStore, AnalyzeVisualStore, SessionPreviewStore } from '.';
import { useStore } from '../../common/stores/context';
import { useSessionsStore } from '../../sessions/stores/context';
import { WorkflowInstanceStore, ProjectsStore, ErrorStore, TabsStore, FileOperationsStore } from '../../common/stores';
import { SessionsVisualStore } from '../../sessions/stores';
import ImageService from '../../common/services/ImageService';
import { SessionService } from '../../common/services';

function createStore(
    sessionsStore: SessionsVisualStore,
    wfInstanceStore: WorkflowInstanceStore, 
    projStore: ProjectsStore, 
    errorStore: ErrorStore, 
    tabsStore: TabsStore,
    fileOperationsStore: FileOperationsStore) {   
    const imageService = new ImageService(); 
    const previewsVisualStore = new PreviewsVisualStore(projStore, imageService);
    const analyzeVisualStore = new AnalyzeVisualStore(
        sessionsStore, wfInstanceStore, projStore, previewsVisualStore, tabsStore, fileOperationsStore);
    const homeVisualStore = new HomeVisualStore(projStore, analyzeVisualStore, tabsStore, fileOperationsStore);
    const sessionService = new SessionService();
    const sessionPreviewStore = new SessionPreviewStore(sessionService, wfInstanceStore);

    return {
        homeVisualStore,
        previewsVisualStore,
        analyzeVisualStore,
        sessionPreviewStore
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(null);
type Props = {
    children: React.ReactNode
};

export const AnalysisStoreProvider: React.FC<Props> = ({ children }) => {
    const root = useStore();
    const sessions = useSessionsStore();
    // eslint-disable-next-line max-len
    const store = useLocalStore(() => createStore(sessions.sessionsVisualStore, root.workflowInstance, root.projectsStore, root.errorStore, root.tabsStore, root.fileStore));
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useHomeStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('You have forgot to use StoreProvider, shame on you.');
    }
    
    return store;
};
import { observable, action, makeObservable } from 'mobx';
import * as _ from 'lodash';
import { ErrorMsg } from '../types/ErrorMsg';

export class ErrorStore {
    errors: Error[] = [];

    
    constructor() {
        makeObservable(this, {
            errors: observable,
            addError: action,
            addBasicError: action,
            removeError: action
        });
    }

    async addError(resp: Response | ErrorMsg | undefined | Error) {
        if (!resp) {
            return;
        }

        console.log(resp);
        if (resp instanceof Error) {
            this.errors.push(new Error(JSON.stringify(resp)));
        } else if (!(resp instanceof Response) && resp.title) {
            const error = new Error(resp.title);
            this.errors.push(error);
            console.error(error.message);
        } else if (resp instanceof Response && resp.headers && resp.headers.get('Content-Type') === 'application/json') {
            const msg = <ErrorMsg> await resp.json();
            const error = new Error(msg.title);
            this.errors.push(error);
            console.error(error.message);
        } else {
            this.errors.push(new Error(JSON.stringify(resp)));
        }
    }

    async addBasicError(error: Error) {
        this.errors.push(error);
        console.error(error.message);
    }

    removeError(error: Error) {
        _.remove(this.errors, function (n: Error) {
            return _.isEqual(error, n);
        });
    }
}

export default ErrorStore;
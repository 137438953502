import { Subject } from 'rxjs';
import { TabBaseDto } from '../../types';
import { InputBaseModel, SourceModelBase } from '..';

export default abstract class TabBaseModel {
    readonly id: string;

    readonly type: string;

    readonly name: string;

    readonly inputValueChangeSubject = new Subject<InputBaseModel>();

    constructor(data: TabBaseDto) {
        this.id = data.id;
        this.type = data.type;
        this.name = data.name;
    }

    getInputByGuid(guid: string) {
        return this.inputs.find(input => input.guid === guid);
    }

    abstract update(data: TabBaseDto, sources: SourceModelBase[]): void;

    abstract get inputs(): InputBaseModel[];

    abstract get dto(): TabBaseDto;
}

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from 'antd';
import { useAppStore } from '../../stores';
import './ContractStatusModal.less';

const ContractStatusModal: React.FC = () => {
    const { sessionStore } = useAppStore();

    const ref = React.useRef<HTMLDivElement>(null);

    return (
        <div ref={ref} className="contract-status-modal-container">
            <Modal
                title="Please wait..."
                visible={sessionStore.contractSending}
                closable={false}
                footer={null}
                getContainer={() => ref.current as HTMLElement}
                centered
            >
                <span>
                    The contract is being meticulously prepared and submitted. Please hold tight, we&apos;re almost
                    there!
                </span>
            </Modal>
        </div>
    );
};

export default observer(ContractStatusModal);

import { LayoutSettingsBaseModel, LayerSettingsModel } from '..';
import { LayersWithSectionsLayoutSettingsDto } from '../../types';
import { maxBy } from 'lodash';

export default class LayersWithSectionsLayoutSettingsModel extends LayoutSettingsBaseModel {
    readonly layersSettings: LayerSettingsModel[];

    constructor(data: LayersWithSectionsLayoutSettingsDto) {
        super(data);

        this.layersSettings = data.layersSettings.map(layerSettings => new LayerSettingsModel(layerSettings));
    }

    get label() {
        return 'Layers With Sections Layout';
    }

    get numberOfLayers() {
        return this.layersSettings.length;
    }

    get numberOfSections() {
        return maxBy(this.layersSettings, s => s.numberOfSections)?.numberOfSections ?? 0;
    }

    get dto(): LayersWithSectionsLayoutSettingsDto {
        return {
            type: this.type,
            layersSettings: this.layersSettings.map(s => s.dto)
        };
    }
}

import { v4 as uuidv4 } from 'uuid';

export default class AskAlphaErrorModel {
    readonly id: string;

    readonly message: string;

    readonly createDate: Date;

    constructor(message: string) {
        this.id = uuidv4();
        this.message = message;
        this.createDate = new Date();
    }
}

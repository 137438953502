import security from '../../common/services/SecurityService';
import { QueryByTagsRequestBody, SearchResultSourceTag } from '../types';
import authProvider, { authenticationParameters } from './authProvider';
import appClient from '../../common/services/AppClient';
export default class SearchService {
    searchDocumentsByQuery(query: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `search?query=${query}`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return appClient.get<any>(url);
    }

    queryDocumentsByTags(query: string, tags: SearchResultSourceTag[]) {
        const requestBody: QueryByTagsRequestBody = {
            query,
            tags
        };
        const url = process.env.REACT_APP_MANAGE_URL + `search?query=${query}`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return appClient.post<any>(url, requestBody);
    }

    async deepSearch(query: string) {
        const info = await authProvider.acquireTokenSilent(authenticationParameters);

        const request = {
            query: query,
            accessToken: info.accessToken,
            sources: ['PEOPLE', 'FILES', 'MESSAGES']
        };

        const url = process.env.REACT_APP_MANAGE_URL + 'search/external';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return appClient.post<any>(url, request);
    }

    handleDownload(id: string) {
        let path = encodeURIComponent(id);
        security.invoke((token) => {            
            let t = '?access_token=' + encodeURIComponent(token);
            const url = `${process.env.REACT_APP_MANAGE_URL}document/${path}${t}`;
            window.open(url);
            return Promise.resolve();
        });
    }
}
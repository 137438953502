import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomDecimalInputWrapper, InputActions, CustomFormItem } from '..';
import { InputModel } from '../../models';
import { InputWithMeta } from '../../componentTypes';

// Please note that this is a generic component if behavior for your input is different - create a new independent component (like BusinessTypeInput.tsx etc.)

interface Props {
    label: string;
    input: InputModel;
    precision?: number
}

const GenericDecimalInput: InputWithMeta<Props> = ({ label, input, precision }) => {
    return (
        <CustomFormItem name={input.guid}>
            <CustomDecimalInputWrapper
                input={input}
                label={label}
                precision={precision}
                topRightNode={<InputActions input={input} />}
            />
        </CustomFormItem>
    );
};

GenericDecimalInput.meta = {
    inputType: InputModel.name
};

export default observer(GenericDecimalInput);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse } from 'antd';
import { LayersWithSectionsTabModel, LayerWithSectionsModel } from '../../models';
import { GetComponentRowsFunction } from '../../componentTypes';
import SectionRenderer from './SectionRenderer';
import './LayerWithSectionsRenderer.less';

interface Props {
    tab: LayersWithSectionsTabModel;
    layer: LayerWithSectionsModel;
    getComponentRows: GetComponentRowsFunction;
    addLayerRef: (layerId: string, layerRef: React.RefObject<HTMLDivElement>) => void
}

const LayerWithSectionsRenderer: React.FC<Props> = (props: Props) => {
    const { tab, layer, getComponentRows, addLayerRef } = props;

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        addLayerRef(layer.id, ref);
    }, [layer.id, addLayerRef]);

    return (
        <div ref={ref} className="layer-with-sections-renderer-container">
            <Collapse
                key={layer.id}
                activeKey={layer.expanded ? [layer.id] : []}
                onChange={layer.toggleExpanded}
                bordered={false}
            >
                <Collapse.Panel
                    className="layer-with-sections-collapse-panel"
                    header={<span className="header-title">{layer.name}</span>}
                    key={layer.id}
                >
                    {layer.sections.map(section => (
                        <SectionRenderer
                            key={section.id}
                            tab={tab}
                            layer={layer}
                            section={section}
                            getComponentRows={getComponentRows}
                        />
                    ))}
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default observer(LayerWithSectionsRenderer);

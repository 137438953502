import { observable, action, runInAction, makeObservable, computed } from 'mobx';
import { TabModel } from '../../common/types/TabModel';
import { IotaSessionsStore } from '../../common/stores';
import { SessionStates } from '../../common/services/types';
import {
    CustomSessionChangeResult,
    CustomSessionState,
    CustomSessionResponseBase,
    CustomSessionRuntimeData
} from '../types';
import { Disposer } from '../../custom_shared/misc';
import CustomSessionService from '../services/CustomSessionService';

export default abstract class SessionStoreBase<TResponse extends CustomSessionResponseBase> extends Disposer {
    isInitializing: boolean = true;

    isLoading: boolean = false;

    inProgress: boolean = false;

    isFinished: boolean = false;

    response: TResponse | null = null;

    readonly sessionService = new CustomSessionService();

    constructor(protected readonly tab: TabModel, protected readonly iotaSessionsStore: IotaSessionsStore) {
        super();

        makeObservable(this, {
            isInitializing: observable,
            isLoading: observable,
            inProgress: observable,
            isFinished: observable,
            response: observable,
            setIsLoading: action,
            setIsInitializing: action,
            initialize: action,
            getSession: action,
            continueSession: action,
            errors: computed
        });

        this.iotaSessionsStore.iotaSessionChanges.subscribe((session: CustomSessionChangeResult<TResponse>) => {
            if (this.sessionId === session.sessionId) {
                this.setData(session);
            }
        });
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    setIsInitializing(isInitializing: boolean) {
        this.isInitializing = isInitializing;
    }

    setData(session: CustomSessionState<TResponse> | CustomSessionChangeResult<TResponse>) {
        runInAction(() => {
            this.inProgress = session.inProgress;
            this.isFinished = !session.response.actions.length;
            this.response = session.response;
        });
    }

    async initialize() {
        try {
            this.setIsInitializing(true);
            await this.getSession();
        } catch (err) {
            console.log(err);
        } finally {
            this.setIsInitializing(false);
        }
    }

    async getSession() {
        try {
            this.setIsLoading(true);

            const response = await this.sessionService.getSessionState<TResponse>(this.sessionExtension, this.sessionId);

            if (response.isOk()) {
                response.map(session => this.setData(session));
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.setIsLoading(false);
        }
    }

    async continueSession(actionId: string, runtimeData: CustomSessionRuntimeData) {
        try {
            this.setIsLoading(true);

            await this.sessionService.continueSession(this.sessionExtension, this.sessionId, actionId, runtimeData);
        } catch (err) {
            console.log(err);
        } finally {
            this.setIsLoading(false);
        }
    }

    async startSession(applicationId: string, packageId: string, parameters: Record<string, unknown> | null = null) {
        return await this.sessionService.startSession(applicationId, packageId, parameters);
    }

    async updateSession(sessionId: string, state: SessionStates) {
        return await this.sessionService.updateSession(sessionId, state);
    }

    async getFileFromIotaTempStorage(filePath: string) {
        await this.sessionService.getFileFromIotaTempStorage(filePath);
    }

    get sessionId() {
        return this.tab.metadata.sessionId as string;
    }

    get sessionExtension() {
        return this.tab.metadata.applicationData.appExtension as string;
    }

    get errors() {
        return this.response?.errors ?? [];
    }
}

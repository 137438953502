import security from '../../common/services/SecurityService';
import { ErrorStore } from '../../common/stores';
import { action, observable, runInAction, makeObservable, computed } from 'mobx';
import UserProfileService from '../services/UserProfileService';
import { UserProfile } from '../services/types';
import { message } from 'antd';
import UserProfileModel from '../../user_profile/models/UserProfileModel';
import { UserProfileInfo } from '../types/UserProfileInfo';
import { AppPermissions } from '../../authorization/Permissions';
export default class UserProfileStore {

    editUserProfileVisible: boolean = false;

    resetPasswordDialogVisible: boolean = false;

    userProfile: UserProfile;

    imageUrl: string;

    currentUserProfileInfo: UserProfileInfo;

    currentUserPermissions: string[] = [];

    get hasAccessToAllEntities() {
        return this.currentUserPermissions.includes(AppPermissions.CanAccessAllEntities);
    }

    constructor(private service: UserProfileService, private errorStore: ErrorStore) {
        makeObservable(this, {
            currentUserPermissions: observable,
            editUserProfileVisible: observable,
            resetPasswordDialogVisible: observable,
            userProfile: observable,
            imageUrl: observable,
            currentUserProfileInfo: observable,
            hasAccessToAllEntities: computed,
            changePassword: action.bound,
            logout: action.bound,
            setEditUserProfileVisible: action.bound,
            setResetPasswordDialogVisible: action.bound,
            setUserPermissions: action.bound,
            getUserProfile: action,
            updateUserProfile: action,
            getAvatarIcon: action
        });

        this.getUserProfile();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        security.loadUserInfo().then((r: any) => {
            runInAction(() => {
                this.currentUserProfileInfo = {
                    userId: r.sub,
                    preferredUsername: r.preferred_username
                };
            });
        }).catch(e => {
            console.error(e);
        });
    }

    setUserPermissions(permissions: string[]) {
        this.currentUserPermissions = permissions;
    }

    async changePassword(newPassword: string, oldPassword: string) {
        try {
            const resp = await this.service.changePassword(newPassword, oldPassword);
            if (resp.status === 200) {
                this.setResetPasswordDialogVisible(false);
                message.success('Password has been successfully reset.');
            } else if (resp.status === 401 || resp.status === 403) { 
                message.error('Old password is incorrect.');
            } else {
                message.error('There were problems resetting your password, please report to administrator');
            }
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }
    
    logout() {
        security.inst.logout();
    }

    setEditUserProfileVisible(isVisible: boolean) {
        this.editUserProfileVisible = isVisible;
    }

    setResetPasswordDialogVisible(isVisible: boolean) {
        this.resetPasswordDialogVisible = isVisible;
    }

    async getUserProfile() {
        const userProfile = await this.service.getUserProfile();
        runInAction(() => this.userProfile = userProfile);
    }

    async updateUserProfile(model: UserProfileModel) {
        const formData = new FormData();
        for (let key in model) {
            if (model[key]) {
                formData.append(key, model[key]);
            }
        }
        const resp = await this.service.updateUserProfile(formData);
        await resp.asyncMap(async () => {
            await this.getUserProfile();
            runInAction(() => {
                this.userProfile.email = model.email;
                this.userProfile.firstName = model.firstName;
                this.userProfile.lastName = model.lastName;
            });
            this.setEditUserProfileVisible(false);
        }).mapErr(err => {
            const errorMsg = err.data?.title.includes('exists') ? 'User with the same email exists' : err.data?.title;
            message.error(errorMsg);
        });
    }

    async getAvatarIcon(fileId: string) {
        const resp = await this.service.getAvatarIcon(fileId);
        if (!resp) {
            return undefined;
        }
        runInAction(() => this.imageUrl = URL.createObjectURL(resp));
        return resp;
    }

    async resetPassword(password: string) {
        const isPasswordReset = await this.service.resetPassword(password);
        this.setResetPasswordDialogVisible(false);
        isPasswordReset.map(success => {
            if (success) {
                message.success('Password has been successfully reset');
            } else {
                message.error('There were problems resetting your password, please report to administrator');
            }
        });        
    }
}
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu, Dropdown, Button } from 'antd';
import { ApplicationDefinitionsStore, ProjectsStore } from '../../common/stores';
import { PackageStateResult, ApplicationDefinitionGroup, ApplicationDefinitionSubgroup } from '../../common/services/types';
import { HomeVisualStore } from '../stores';

type Props = {
    appStore: ApplicationDefinitionsStore;
    projectsStore: ProjectsStore;
    homeStore: HomeVisualStore;
    resetSearch: () => void
};

export const ApplicationsMenu: React.FC<Props> = ({ appStore, projectsStore, homeStore, resetSearch }) => {
    const analyze = async (pkgIds: string[], appId: string, pkgName: string) => {
        var app = appStore.projectApplications ? appStore.projectApplications.find(a => a.id === appId) : undefined;
        if (!app) {
            return;
        }
        
        if (pkgIds.length === 1 && projectsStore.selectedPackage) {
            const type = projectsStore.selectedPackage.type;
            await homeStore.analyzePackage(pkgIds[0], appId, pkgName, app.extension, type, app.name, );
        } else {
            await homeStore.analyzeMultiplePackages(pkgIds, appId, pkgName, app.extension, app.name);
        }

        resetSearch();
    };

    const deepGroupCheck = (group: ApplicationDefinitionGroup | ApplicationDefinitionSubgroup) => {
        let isInPublishedList = false;
        let hasApplicaitonsInList = group.applicationDefinitionIds && group.applicationDefinitionIds.length > 0;

        if (hasApplicaitonsInList) {
            group.applicationDefinitionIds.forEach(a => {
                if (appStore.applicationNamesDictionary[a]) {
                    isInPublishedList = true;
                    return;
                }
            });
        }

        let hasElements: boolean = hasApplicaitonsInList && isInPublishedList;

        if (group.subGroups && group.subGroups.length > 0 && !hasElements) {
            group.subGroups.forEach(g => {
                hasElements = deepGroupCheck(g);
                if (hasElements) {
                    return;
                }
            });
        }

        return hasElements;
    };

    const renderGroup = (group: ApplicationDefinitionGroup | ApplicationDefinitionSubgroup) => {
        let groupHasElements = deepGroupCheck(group);
        return (
            groupHasElements && (
                <Menu.SubMenu data-id={`new-contract-applications-${group.id}`} key={group.id} title={group.name}>
                    {group.applicationDefinitionIds && group.applicationDefinitionIds.map(a => appStore.applicationNamesDictionary[a] && (
                        <Menu.Item 
                            data-id={`new-contract-applications-${group.id}-${a}`} 
                            key={a} 
                            onClick={() => analyze(projectsStore.selectedPackageIds, a, projectsStore.selectedPackageName)}
                            disabled={
                                projectsStore.selectedPackageIds.length > 1 
                                && !appStore.applicationNamesDictionary[a]?.capabilities?.isBulkProcessing
                            }
                        >
                            {appStore.applicationNamesDictionary[a]?.name}
                        </Menu.Item>
                    ))}
                    {group.subGroups && group.subGroups.map(g => (
                        renderGroup(g)
                    ))}
                </Menu.SubMenu>
            )
        );
    };

    const applicationsMenu = (
        <Menu data-id="new-contract-applications">
            {appStore.projectApplicationGroups && appStore.projectApplicationGroups.map(g => (
                renderGroup(g)
            ))}
        </Menu>
    );

    return (
        <Dropdown
            data-id="new-contract-new-dropdown"
            overlay={applicationsMenu}
            placement="topLeft"
            trigger={['click']}
            disabled={
                !appStore.projectApplications 
                || appStore.projectApplications.length === 0 
                || projectsStore.selectedPackageState !== PackageStateResult.Ready
            }
        >
            <Button
                data-id="new-contract-new-dropdown-button"
                type="primary"
                loading={appStore.loading}
            >
                Analyse
            </Button>
        </Dropdown>
    );
};

export default observer(ApplicationsMenu);
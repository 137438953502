import { TabBaseDto, BasicTabDto, LayersTabDto, LayersWithSectionsTabDto, SectionsTabDto } from '../types';
import {
    SourceModelBase,
    BasicTabModel,
    LayersTabModel,
    LayersWithSectionsTabModel,
    SectionsTabModel
} from '../models';

export default class TabFactory {
    static createTab(data: TabBaseDto, sources: SourceModelBase[]) {
        switch (data.type) {
        case 'Basic':
            return new BasicTabModel(data as BasicTabDto, sources);
        case 'Layers':
            return new LayersTabModel(data as LayersTabDto, sources);
        case 'LayersWithSections':
            return new LayersWithSectionsTabModel(data as LayersWithSectionsTabDto, sources);
        case 'Sections':
            return new SectionsTabModel(data as SectionsTabDto, sources);
        default:
            throw new Error('Unsupported tab type');
        }
    }
}

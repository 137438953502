import * as React from 'react';
import { Tabs, FormInstance } from 'antd';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import {
    TabBaseModel,
    BasicTabModel,
    LayersTabModel,
    LayersWithSectionsTabModel,
    SectionsTabModel
} from '../../models';
import { TabRendererRegistry } from '..';
import { InstanceUtils } from '../../misc';
import './TabsRenderer.less';

interface Props {
    addFormRef: (id: string, form: FormInstance) => void
}

const TabsRenderer: React.FC<Props> = ({ addFormRef }) => {
    const { tabStore, inputStore, documentVisualStore } = useAppStore();

    const handleBasicTabExpand = React.useCallback(
        (tab: BasicTabModel, inputGuid: string) => {
            let addDelay = false;

            if (!tab.expanded) {
                tab.setExpanded(true);
                addDelay = true;
            }

            inputStore.setHighlightedInputGuid(inputGuid, addDelay ? 300 : 0);
        },
        [inputStore]
    );

    const handleLayersTabExpand = React.useCallback(
        (tab: LayersTabModel, inputGuid: string) => {
            const layer = tab.getLayerByInputGuid(inputGuid);

            if (!layer) {
                return;
            }

            let addDelay = false;

            if (!layer.expanded) {
                layer.setExpanded(true);
                addDelay = true;
            }

            inputStore.setHighlightedInputGuid(inputGuid, addDelay ? 300 : 0);
        },
        [inputStore]
    );

    const handleLayersWithSectionsTabExpand = React.useCallback(
        (tab: LayersWithSectionsTabModel, inputGuid: string) => {
            const layer = tab.getLayerByInputGuid(inputGuid);

            if (!layer) {
                return;
            }

            let addDelay = false;

            if (!layer.expanded) {
                layer.setExpanded(true);
                addDelay = true;
            }

            const section = layer.getSectionByInputGuid(inputGuid);

            if (!section) {
                return;
            }

            if (!section.expanded) {
                section.setExpanded(true);
                addDelay = true;
            }

            inputStore.setHighlightedInputGuid(inputGuid, addDelay ? 300 : 0);
        },
        [inputStore]
    );

    const handleSectionsTabExpand = React.useCallback(
        (tab: SectionsTabModel, inputGuid: string) => {
            const section = tab.getSectionByInputGuid(inputGuid);

            if (!section) {
                return;
            }

            let addDelay = false;

            if (!section.expanded) {
                section.setExpanded(true);
                addDelay = true;
            }

            inputStore.setHighlightedInputGuid(inputGuid, addDelay ? 300 : 0);
        },
        [inputStore]
    );

    React.useEffect(() => {
        const subject = documentVisualStore.highlightedInputIdSubject.subscribe(inputGuid => {
            if (!inputGuid) {
                inputStore.setHighlightedInputGuid(null);
                return;
            }

            const tab = tabStore.getTabByInputGuid(inputGuid);

            if (!tab) {
                return;
            }

            tabStore.setActiveTabId(tab.id);

            if (InstanceUtils.isBasicTab(tab)) {
                handleBasicTabExpand(tab, inputGuid);
            } else if (InstanceUtils.isLayersTab(tab)) {
                handleLayersTabExpand(tab, inputGuid);
            } else if (InstanceUtils.isLayersWithSectionsTab(tab)) {
                handleLayersWithSectionsTabExpand(tab, inputGuid);
            } else if (InstanceUtils.isSectionsTab(tab)) {
                handleSectionsTabExpand(tab, inputGuid);
            }
        });

        return () => subject.unsubscribe();
    }, [
        tabStore,
        inputStore,
        documentVisualStore.highlightedInputIdSubject,
        handleBasicTabExpand,
        handleLayersTabExpand,
        handleLayersWithSectionsTabExpand,
        handleSectionsTabExpand
    ]);

    const renderTab = (tab: TabBaseModel) => {
        const TabRenderer = TabRendererRegistry[tab.id];

        if (!TabRenderer) {
            console.error(`Tab '${tab.id}' is not registered`);
            return null;
        }

        return <TabRenderer tab={tab} addFormRef={addFormRef} />;
    };

    return (
        <Tabs
            className="contract-ingestion-tabs-renderer"
            activeKey={tabStore.activeTabId}
            onChange={tabStore.setActiveTabId}
        >
            {tabStore.tabs.map(tab => (
                <Tabs.TabPane
                    className={`tab-pane ${tabStore.showLoader ? 'loading' : ''}`}
                    tab={<span className="tab-name-label">{tab.name}</span>}
                    key={tab.id}
                >
                    {renderTab(tab)}
                </Tabs.TabPane>
            ))}
        </Tabs>
    );
};

export default observer(TabsRenderer);

import {
    GeneralInformationTabRenderer,
    LimitsTabRenderer,
    PremiumsTabRenderer,
    DeductionsTabRenderer,
    ShareTabRenderer,
    ClaimsTabRenderer,
    PortfolioConditionsTabRenderer,
    AdminConditionsTabRenderer,
    OtherConditionsTabRenderer,
    InstalmentsTabRenderer,
    AdjustmentsTabRenderer,
    ReinstatementsTabRenderer
} from '../../tab_renderers';
import { TabProps } from '../../componentTypes';

type Registry = Record<string, React.FC<TabProps> | undefined>;

const TabRendererRegistry: Registry = {
    ['GeneralInformation']: GeneralInformationTabRenderer,
    ['Limits']: LimitsTabRenderer,
    ['Premiums']: PremiumsTabRenderer,
    ['Deductions']: DeductionsTabRenderer,
    ['Share']: ShareTabRenderer,
    ['Claims']: ClaimsTabRenderer,
    ['PortfolioConditions']: PortfolioConditionsTabRenderer,
    ['AdminConditions']: AdminConditionsTabRenderer,
    ['OtherConditions']: OtherConditionsTabRenderer,
    ['Instalments']: InstalmentsTabRenderer,
    ['Adjustments']: AdjustmentsTabRenderer,
    ['Reinstatements']: ReinstatementsTabRenderer
};

export default TabRendererRegistry;

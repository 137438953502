import { groupBy } from 'lodash';
import { QuestionPackageFieldDto } from '../types';
import { DocumentBlockProps } from '../../documents/stores/DocumentVisualStore';
import { FormInputFieldData } from '../../common/services/types';
import DocumentHelper from '../../custom_shared/misc/DocumentHelper';

export default class AskAlphaDocumentHelper {
    static getDocumentBlocks(
        packageFields: QuestionPackageFieldDto[],
        pageWidth?: number,
        packageId?: string
    ): DocumentBlockProps[] {
        if (!pageWidth || !packageId) {
            return [];
        }

        // To reuse existing document preview functionality package fields should be mapped to input fields
        const inputFields: Array<FormInputFieldData & { fieldId: string; area: number }> = packageFields.map(
            ({ field }) => ({
                fieldId: field.id,
                p: field.page,
                x: field.x,
                y: field.y,
                w: field.w,
                h: field.h,
                pw: field.pageWidth,
                ph: field.pageHeight,
                bt: field.blockType,
                pId: field.packageId,
                area: field.w * field.h
            })
        );

        const inputFieldsGroupedByPage = groupBy(inputFields, 'p');

        return Object.values(inputFieldsGroupedByPage).reduce<DocumentBlockProps[]>((acc, fields) => {
            // We need to make sure that small blocks are not overlapped by large blocks, so we sort them by area and subtract 1 digit after each block creation.
            let zIndex = 999;

            const documentBlocks = fields
                .sort((a, b) => a.area - b.area)
                .map(field => {
                    const documentBlock = DocumentHelper.createDocumentBlocks(
                        field.fieldId,
                        field,
                        pageWidth,
                        packageId,
                        { zIndex }
                    );

                    zIndex -= 1;

                    return documentBlock;
                });

            return [...acc, ...documentBlocks];
        }, []);
    }
}

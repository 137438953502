import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { FileOutlined } from '@ant-design/icons';
import { useAskAlphaStores } from '../../stores/context';
import DocumentPreviewSelector from '../../../documents/components/DocumentPreviewSelector';
import './DocumentPreview.less';

const DocumentPreview: React.FC = () => {
    const { askAlphaStore, askAlphaDocumentStore, askAlphaProjectsStore } = useAskAlphaStores();

    if (!askAlphaStore.selectedPackage) {
        return (
            <div className="ask-alpha-document-placeholder">
                <FileOutlined />
                <p>No Data</p>
            </div>
        );
    }

    return (
        <div className="ask-alpha-pdf-preview">
            <DocumentPreviewSelector
                packageName={askAlphaStore.selectedPackage.fileName}
                paginationPosition="80px"
                documentVisualStore={askAlphaDocumentStore}
                projectsStore={askAlphaProjectsStore}
                packageId={askAlphaStore.selectedPackage.id}
                sessionId={askAlphaStore.sessionId}
                hasLeftContainer
            />
        </div>
    );
};

export default observer(DocumentPreview);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { SendOutlined } from '@ant-design/icons';
import { AskAlphaSettingsValues } from '../../types';
import { useAskAlphaStores } from '../../stores/context';
import { useHomeStore } from '../../../analysis/stores/context';
import SessionProgressDialog from '../../../analysis/components/SessionProgressDialog';
import './ChatQuestionInput.less';

type Props = {
    settingsForm: FormInstance<AskAlphaSettingsValues>
};

const ChatQuestionInput: React.FC<Props> = ({ settingsForm }) => {
    const { homeVisualStore, previewsVisualStore, analyzeVisualStore } = useHomeStore();

    const { askAlphaStore, askAlphaApplicationDefinitionsStore } = useAskAlphaStores();

    const [message, setMessage] = React.useState('');

    const ref = React.useRef<HTMLTextAreaElement>(null);

    const isDisabled = React.useMemo(
        () => askAlphaStore.isBusy || !message.trim().length,
        [askAlphaStore.isBusy, message]
    );

    React.useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    const analyzePackage = React.useCallback(() => {
        const applicationDefinition = askAlphaApplicationDefinitionsStore.projectApplications.find(
            a => a.id === askAlphaStore.settings.values.applicationDefinitionId
        );

        if (!askAlphaStore.selectedPackage || !applicationDefinition) {
            return;
        }

        analyzeVisualStore.analyzePackage(
            askAlphaStore.selectedPackage.id,
            applicationDefinition.id,
            askAlphaStore.selectedPackage.fileName,
            applicationDefinition.extension,
            askAlphaStore.selectedPackage.type,
            applicationDefinition.name
        );
    }, [
        analyzeVisualStore,
        askAlphaApplicationDefinitionsStore.projectApplications,
        askAlphaStore.selectedPackage,
        askAlphaStore.settings.values.applicationDefinitionId
    ]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value);

    const askQuestion = () => {
        if (isDisabled) {
            return;
        }

        if (!askAlphaStore.settings.values.projectId || !askAlphaStore.settings.values.instructWorkflowId) {
            askAlphaStore.setSettingsVisible(true);
            setTimeout(() => settingsForm.validateFields(), 300);
            return;
        }

        const valueToLowerCase = message.toLowerCase();

        if (valueToLowerCase.includes('push') || valueToLowerCase.includes('sics')) {
            analyzePackage();
        } else {
            askAlphaStore.askQuestion(message.trim());
        }

        setMessage('');
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const enterPressed = event.key === 'Enter';
        const newLinePressed = event.metaKey || event.shiftKey;

        if (enterPressed && !newLinePressed) {
            event.preventDefault();
            return askQuestion();
        }

        if (event.key === 'ArrowUp' && askAlphaStore.lastSentMessage) {
            return setMessage(askAlphaStore.lastSentMessage);
        }
    };

    return (
        <div className="chat-input-container">
            <SessionProgressDialog
                homeStore={homeVisualStore}
                previewStore={previewsVisualStore}
                analyzeStore={analyzeVisualStore}
                visible={analyzeVisualStore.showProgressModal}
                setVisibility={analyzeVisualStore.setShowProgressModal}
            />

            <TextareaAutosize
                className="chat-input"
                placeholder="Ask a question..."
                ref={ref}
                minRows={1}
                maxRows={6}
                value={message}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                disabled={!askAlphaStore.selectedPackageId}
            />

            <Tooltip title="Send question">
                <Button type="primary" disabled={isDisabled} onClick={askQuestion}>
                    <SendOutlined />
                </Button>
            </Tooltip>
        </div>
    );
};

export default observer(ChatQuestionInput);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';
import { GenericDecimalInput } from '../../components';

const config = {
    label: 'Number of Reinstatements'
};

const CountOfReinstatementsInput: InputWithoutSource = ({ input }) => (
    <GenericDecimalInput label={config.label} input={input} precision={0} />
);

CountOfReinstatementsInput.meta = {
    inputType: InputModel.name
};

export default observer(CountOfReinstatementsInput);

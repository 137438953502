/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Empty, Form, Spin, Table, Tooltip } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import * as React from 'react';
import { Utils } from '../../../common/misc/Utils';
import { Filter } from '../../../common/services/SessionService';
import { DynamicControlProps } from './types';

export const DynamicResultTable: React.FC<DynamicControlProps> = ({inputParams, form, ui, onAction, parentForm}) => { 
    const [currentVal, setCurrentVal] = React.useState<object[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const buildColumns = () => {
        let columns: {
            title: string; 
            key: string;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render: (text: string, record: any, index: number) => any;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sorter?: (a: any, b: any) => -1 | 0 | 1;
            sortDirections?: SortOrder[];
            defaultSortOrder?: SortOrder;
            width?: number
        }[] = [];

        if (inputParams.behavior && inputParams.behavior.displayColumns) {
            inputParams.behavior.displayColumns.forEach(dc => {
                columns.push({
                    title: dc.header,
                    key: `${inputParams.id}-col-${dc.key}`,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    render: (text: string, record: any) => {
                        var val = getRecordValue(record, dc.key);
                        return (
                            <Tooltip overlayStyle={{maxWidth: 600}} placement="topLeft" title={val}>
                                <div style={{ maxWidth: 300, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {val}
                                </div>
                            </Tooltip>
                        );
                    }
                });
            });
            
            return columns;
        }

        return undefined;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getRecordValue = (record: any, key: string) => {
        let val = record[key] ? record[key] : '';
        const displayColumn = inputParams.behavior?.displayColumns ? inputParams.behavior.displayColumns.find(dc => dc.key === key) : undefined;
        if (displayColumn && displayColumn.dataType === 'date') {
            return Utils.safeFormatDateString(val, undefined, 'YYYY-MM-DD');
        }

        if (displayColumn && displayColumn.dataType === 'number') {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }

        return val;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleRowDoubleClick = (record: any) => {
        if (inputParams.behavior?.action?.settings) {
            const valueColumn = inputParams.behavior.action.settings.valueColumn;
            onAction(inputParams.id, 'setVal', record[valueColumn]);
        }
    };

    const renderTable = () => {
        if (!Array.isArray(currentVal)) {
            return null;
        }

        const hasActions = !!inputParams.behavior?.action?.settings;
        return(
            <Table 
                rowKey={(record: object) => {
                    return currentVal.indexOf(record); 
                }}
                className={`dynamic-visual-table result-table ${hasActions ? 'has-actions' : ''}`}
                dataSource={currentVal}
                columns={buildColumns()}
                loading={isLoading}
                onRow={(record) => {
                    return {
                        onDoubleClick: () => handleRowDoubleClick(record)
                    };
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                rowClassName={() => {
                    let className = '';
                    // TODO: Highlight on singular click
                    return className;
                }}
                pagination={{
                    hideOnSinglePage: true,
                    pageSizeOptions: ['10', '25', '50']
                }}
            />
        );
    };

    const renderEmpty = () => {
        if (isLoading) {
            const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
            return(
                <div style={{textAlign: 'center'}}>
                    <Spin indicator={loadingIcon} />
                </div>
            );
        }

        return (
            <Empty />
        );
    };

    const getFilterValues = (page: string = '0') => {
        if (inputParams.behavior?.action?.settings?.filterFields) {
            const filterKeys = inputParams.behavior?.action?.settings?.filterFields as string[];
            let filter: Filter = {};
            filterKeys.forEach(k => {
                let val = form.getFieldValue(k);

                if (!val && parentForm) {
                    val = parentForm.getFieldValue(k);
                }

                if (val) {
                    filter[k] = val;
                }
            });
            filter.page = page;
            filter.pageSize = '9999';
            return filter;
        }
        return undefined;
    };

    const handleAction = (page: string = '0') => {
        if (inputParams.behavior?.action?.settings) {
            setIsLoading(true);
            const filter = getFilterValues(page);
            const connectionId = inputParams.behavior.action.settings.connectionId;
            ui.getWidgetValues(connectionId, 0, 9999, filter, 9999).then(r => {
                var jsonStrings = r.data.map(d => d.value);
                let valData: object[] = [];
                jsonStrings.forEach(j => {
                    valData.push(JSON.parse(j));
                });
                setCurrentVal(valData);
                setIsLoading(false);
            });
        }
    };

    const renderActions = () => {
        if (inputParams.behavior && inputParams.behavior.action) {
            return (
                <Button type="primary" onClick={() => handleAction()}>
                    {inputParams.behavior.action.name}
                </Button>
            );
        }
        return null;
    };

    return (
        <Form.Item
            name={inputParams.id}
            initialValue={currentVal}
            className="wide result-table"
        >
            <>
                <div className="table-actions">
                    {renderActions()}
                </div>
                {currentVal ? renderTable() : renderEmpty()}
            </>
        </Form.Item>
    );
};
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { GenericSelectMultiple } from '../../components';
import { InputModelWithSource } from '../../models';
import { InputWithSource } from '../../componentTypes';

const config = {
    label: 'Reporting unit'
};

const ReportUnitsInput: InputWithSource = ({ input }) => <GenericSelectMultiple label={config.label} input={input} />;

ReportUnitsInput.meta = {
    inputType: InputModelWithSource.name
};

export default observer(ReportUnitsInput);

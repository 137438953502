import { action, computed, makeObservable, observable } from 'mobx';
import { TabBaseModel } from '..';
import { ContractsIngestionRuntimeInputDto, DataType } from '../../types';
import { TypeUtils } from '../../../custom_shared/misc';

export default abstract class InputBaseModel {
    readonly id: string;
    readonly guid: string;
    readonly parentInputId: string | null;
    readonly initialValue: unknown;
    readonly meta: Record<string, unknown>;
    readonly sourceId: string | null;
    readonly dataType: DataType;

    value: unknown;
    required: boolean;

    constructor(private readonly tab: TabBaseModel, data: ContractsIngestionRuntimeInputDto) {
        this.id = data.id;
        this.guid = data.guid;
        this.parentInputId = data.parentInputId;
        this.value = this.getValue(data.value);
        this.initialValue = this.value;
        this.meta = data.meta;
        this.sourceId = data.sourceId;
        this.dataType = data.dataType;
        this.required = data.required;

        makeObservable(this, {
            value: observable,
            required: observable,
            setValue: action.bound,
            setRequired: action.bound,
            parentInput: computed,
            childInput: computed,
            hasValue: computed
        });
    }

    setValue(value: unknown) {
        this.value = this.getValue(value);
        this.tab.inputValueChangeSubject.next(this);
    }

    setRequired(required: boolean) {
        this.required = required;
    }

    get hasValue() {
        if (TypeUtils.isString(this.value) || TypeUtils.isArray(this.value)) {
            return this.value.length > 0;
        }

        return this.value !== null;
    }

    get parentInput() {
        return this.tab.inputs.find(i => i.id === this.parentInputId);
    }

    get childInput() {
        return this.tab.inputs.find(i => i.parentInputId === this.id);
    }

    get dto(): ContractsIngestionRuntimeInputDto {
        return {
            id: this.id,
            guid: this.guid,
            parentInputId: this.parentInputId,
            value: this.value,
            meta: this.meta,
            sourceId: this.sourceId,
            dataType: this.dataType,
            required: this.required
        };
    }

    update(data: ContractsIngestionRuntimeInputDto) {
        this.setValue(data.value);
        this.setRequired(data.required);
    }

    private getValue(value: unknown) {
        return value === undefined ? null : value;
    }
}

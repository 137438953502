/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const ViewInDocumentIcon: React.FC<IconProps> = ({ width = 16, height = 17, color = '#000', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g opacity="0.8">
            <path
                d="M4.94271 14.13H7.79688C7.52604 13.8957 7.29167 13.6092 7.11979 13.2915H4.98958C4.45313 13.2915 4.16667 13.0155 4.16667 12.4946V4.60921C4.16667 4.0988 4.44792 3.80192 4.98958 3.80192H10.4323C10.9531 3.80192 11.25 4.09359 11.25 4.60921V9.15609C11.5677 9.32796 11.8542 9.55713 12.0885 9.83317V4.5988C12.0885 3.50505 11.5521 2.96338 10.474 2.96338H4.94271C3.86979 2.96338 3.32812 3.51025 3.32812 4.5988V12.4946C3.32812 13.5884 3.86979 14.13 4.94271 14.13ZM5.73958 5.92692H9.6875C9.86979 5.92692 10.0104 5.78109 10.0104 5.5988C10.0104 5.42171 9.86979 5.2863 9.6875 5.2863H5.73958C5.54688 5.2863 5.41146 5.42171 5.41146 5.5988C5.41146 5.78109 5.54688 5.92692 5.73958 5.92692ZM5.73958 7.73942H9.6875C9.86979 7.73942 10.0104 7.5988 10.0104 7.4165C10.0104 7.23942 9.86979 7.0988 9.6875 7.0988H5.73958C5.54688 7.0988 5.41146 7.23942 5.41146 7.4165C5.41146 7.5988 5.54688 7.73942 5.73958 7.73942ZM9.75 14.13C10.2188 14.13 10.6615 13.9894 11.026 13.7498L12.1979 14.9217C12.3229 15.0467 12.4375 15.0936 12.5781 15.0936C12.8177 15.0936 13 14.9061 13 14.6405C13 14.5259 12.9427 14.4113 12.8542 14.3228L11.6667 13.13C11.9323 12.7498 12.0885 12.2915 12.0885 11.7915C12.0885 10.4894 11.0417 9.44255 9.75 9.44255C8.45833 9.44255 7.40104 10.4998 7.40104 11.7915C7.40104 13.0832 8.45833 14.13 9.75 14.13ZM9.75 13.3853C8.86458 13.3853 8.14583 12.6665 8.14583 11.7915C8.14583 10.9165 8.86458 10.1925 9.75 10.1925C10.6198 10.1925 11.3385 10.9165 11.3385 11.7915C11.3385 12.6665 10.625 13.3853 9.75 13.3853Z"
                fill={color}
            />
            <path
                d="M4.94271 14.13H7.79688C7.52604 13.8957 7.29167 13.6092 7.11979 13.2915H4.98958C4.45313 13.2915 4.16667 13.0155 4.16667 12.4946V4.60921C4.16667 4.0988 4.44792 3.80192 4.98958 3.80192H10.4323C10.9531 3.80192 11.25 4.09359 11.25 4.60921V9.15609C11.5677 9.32796 11.8542 9.55713 12.0885 9.83317V4.5988C12.0885 3.50505 11.5521 2.96338 10.474 2.96338H4.94271C3.86979 2.96338 3.32812 3.51025 3.32812 4.5988V12.4946C3.32812 13.5884 3.86979 14.13 4.94271 14.13ZM5.73958 5.92692H9.6875C9.86979 5.92692 10.0104 5.78109 10.0104 5.5988C10.0104 5.42171 9.86979 5.2863 9.6875 5.2863H5.73958C5.54688 5.2863 5.41146 5.42171 5.41146 5.5988C5.41146 5.78109 5.54688 5.92692 5.73958 5.92692ZM5.73958 7.73942H9.6875C9.86979 7.73942 10.0104 7.5988 10.0104 7.4165C10.0104 7.23942 9.86979 7.0988 9.6875 7.0988H5.73958C5.54688 7.0988 5.41146 7.23942 5.41146 7.4165C5.41146 7.5988 5.54688 7.73942 5.73958 7.73942ZM9.75 14.13C10.2188 14.13 10.6615 13.9894 11.026 13.7498L12.1979 14.9217C12.3229 15.0467 12.4375 15.0936 12.5781 15.0936C12.8177 15.0936 13 14.9061 13 14.6405C13 14.5259 12.9427 14.4113 12.8542 14.3228L11.6667 13.13C11.9323 12.7498 12.0885 12.2915 12.0885 11.7915C12.0885 10.4894 11.0417 9.44255 9.75 9.44255C8.45833 9.44255 7.40104 10.4998 7.40104 11.7915C7.40104 13.0832 8.45833 14.13 9.75 14.13ZM9.75 13.3853C8.86458 13.3853 8.14583 12.6665 8.14583 11.7915C8.14583 10.9165 8.86458 10.1925 9.75 10.1925C10.6198 10.1925 11.3385 10.9165 11.3385 11.7915C11.3385 12.6665 10.625 13.3853 9.75 13.3853Z"
                fill={color}
                fillOpacity="0.2"
            />
        </g>
    </svg>
);

export default ViewInDocumentIcon;

import * as React from 'react';
import HomePage from '../screens/Analysis';
import { Route } from 'react-router';
import { context } from '../stores';
import { context as sessionContext } from '../../sessions/stores';
import { context as documentsContext } from '../../documents/stores';

const analysisRoute = () => {
    return (
        <>
            <Route
                path={'/analysis'}
                element={
                    <documentsContext.DocumentsStoreProvider>
                        <sessionContext.SessionsStoreProvider>
                            <context.AnalysisStoreProvider>
                                <HomePage />
                            </context.AnalysisStoreProvider>
                        </sessionContext.SessionsStoreProvider>
                    </documentsContext.DocumentsStoreProvider>
                }
            />
            <Route
                path={'/analysis/:sessionId'}
                element={
                    <documentsContext.DocumentsStoreProvider>
                        <sessionContext.SessionsStoreProvider>
                            <context.AnalysisStoreProvider>
                                <HomePage />
                            </context.AnalysisStoreProvider>
                        </sessionContext.SessionsStoreProvider>
                    </documentsContext.DocumentsStoreProvider>
                }
            />
        </>
    );
};

export default analysisRoute;
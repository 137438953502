import * as React from 'react';
import { AskAlphaScreen } from '../screens';
import { Route } from 'react-router';

const analysisRoute = () => {
    return (
        <>
            <Route path={'/ask-alpha'} element={<AskAlphaScreen />} />
        </>
    );
};

export default analysisRoute;

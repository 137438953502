import { makeObservable, observable, action, reaction, computed } from 'mobx';
import { Disposer } from '../../custom_shared/misc';
import { LayoutSettingsFactory } from '../misc';
import { LayoutSettingsBaseModel } from '../models';
import SessionStore from './SessionStore';

export default class SettingsStore extends Disposer {
    layoutSettings: LayoutSettingsBaseModel | null = null;

    layoutSettingsOptions: LayoutSettingsBaseModel[] = [];

    changedLayoutType: string | null = null;

    constructor(private readonly sessionStore: SessionStore) {
        super();

        this.reactions.push(
            reaction(
                () => this.sessionStore.response,
                response => {
                    if (!response) {
                        return;
                    }

                    this.setLayoutSettings(LayoutSettingsFactory.createSettings(response.layoutSettings));
                    this.setLayoutSettingsOptions(response.layoutSettingsOptions.map(LayoutSettingsFactory.createSettings));
                }
            )
        );

        makeObservable(this, {
            layoutSettings: observable,
            layoutSettingsOptions: observable,
            changedLayoutType: observable,
            setLayoutSettings: action,
            setLayoutSettingsOptions: action,
            setChangedLayoutType: action,
            selectedLayoutSettings: computed
        });
    }

    setLayoutSettings(layoutSettings: LayoutSettingsBaseModel) {
        this.layoutSettings = layoutSettings;
    }

    setLayoutSettingsOptions(layoutSettingsOptions: LayoutSettingsBaseModel[]) {
        this.layoutSettingsOptions = layoutSettingsOptions;
    }

    setChangedLayoutType(changedLayoutType: string | null) {
        this.changedLayoutType = changedLayoutType;
    }

    get selectedLayoutSettings() {
        if (this.changedLayoutType) {
            return this.layoutSettingsOptions.find(settings => settings.type === this.changedLayoutType) ?? null;
        }

        return this.layoutSettings;
    }

    dispose() {
        this.disposeReactions();
    }
}

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomRadioButtonWrapper, InputActions, CustomFormItem } from '../../components';
import { InputModelWithSource } from '../../models';
import { InputWithMeta } from '../../componentTypes';

// Please note that this is a generic component if behavior for your input is different - create a new independent component (like BusinessTypeInput.tsx etc.)

interface Props {
    label: string;
    input: InputModelWithSource
}

const GenericRadioButton: InputWithMeta<Props> = ({ label, input }) => {
    return (
        <CustomFormItem name={input.guid}>
            <CustomRadioButtonWrapper
                input={input}
                label={label}
                options={input.sourceProvider.options}
                topRightNode={<InputActions input={input} />}
            />
        </CustomFormItem>
    );
};

GenericRadioButton.meta = {
    inputType: InputModelWithSource.name
};

export default observer(GenericRadioButton);

import appClient from '../../common/services/AppClient';
import { AskAlphaSettingsValues, QuestionResponseDto, InstructWorkflowDto } from '../types';

export default class AskAlphaService {
    async askQuestion(
        message: string,
        packageId: string,
        nodeIds: string[],
        settings: Omit<AskAlphaSettingsValues, 'useContext'>
    ) {
        const url = `${process.env.REACT_APP_MANAGE_URL}chatbot/ask-question`;

        const data = {
            message,
            packageId,
            nodeIds,
            ...settings
        };

        return appClient.post<QuestionResponseDto>(url, data);
    }

    async getInstructWorkflows(projectId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/instruct-workflows`;

        const resp = await appClient.get<InstructWorkflowDto[]>(url);

        return resp.unwrapOr([]);
    }
}

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { debounce } from 'lodash';
import { AutoComplete } from 'antd';
import { useAskAlphaStores } from '../../stores/context';

const PackagesDropdown: React.FC = () => {
    const { askAlphaStore, askAlphaPackagesStore } = useAskAlphaStores();

    const debouncedSearch = React.useMemo(
        () =>
            debounce((updateAutocomplete: boolean = true) => {
                if (!updateAutocomplete) {
                    return;
                }

                askAlphaPackagesStore.searchAutocompletePackages();
            }, 500),
        [askAlphaPackagesStore]
    );

    const onSearch = React.useCallback(
        (packageSearchTerm?: string, updateAutocomplete: boolean = true) => {
            if (packageSearchTerm !== undefined) {
                if (!packageSearchTerm.length) {
                    askAlphaStore.setSelectedPackageId(undefined);
                    askAlphaStore.clearChat();
                }

                askAlphaPackagesStore.setPackageSearchTerm(packageSearchTerm);
            }

            debouncedSearch(updateAutocomplete);
        },
        [debouncedSearch, askAlphaPackagesStore, askAlphaStore]
    );

    return (
        <div className="ask-aplha-packages-dropdown">
            <AutoComplete
                style={{ width: '100%' }}
                placeholder="Select or upload a document"
                options={askAlphaPackagesStore.autocompletePackages.map(pkg => ({
                    label: pkg.fileName,
                    value: pkg.id,
                    key: pkg.id
                }))}
                value={askAlphaPackagesStore.packageSearchTerm}
                onSearch={onSearch}
                onSelect={(_, option) => {
                    onSearch(option.label as string, false);
                    askAlphaStore.changePackage(option.value);
                }}
                disabled={askAlphaStore.packagesImportInProcess}
                onClear={() => {
                    onSearch('');
                    askAlphaStore.changePackage(undefined);
                }}
                allowClear
            />
        </div>
    );
};

export default observer(PackagesDropdown);

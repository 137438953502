/* eslint-disable @typescript-eslint/member-ordering */
import { action, observable, computed, runInAction, makeObservable } from 'mobx';
import { ApplicationDefinitionsStore, ErrorStore } from '../../common/stores';
import ImageService from '../../common/services/ImageService';
import { ImagePreviewSize } from '../types/types';
import { DocumentService, types } from '../../common/services';
import { DynamicUiModel } from '../../common/stores/DynamicUiModel';
import { Utils } from '../../common/misc/Utils';
import { FormInputFieldData } from '../../common/services/types';
import { Subject } from 'rxjs';

type PagePreview = {
    page: number;
    url: string;
    orientation: 'Portrait' | 'Landscape';
    imgSize?: {
        width: number;
        height: number
    }
};

type DocumentField = {
    id: string;
    data: FormInputFieldData | null;
    combinedData: FormInputFieldData[] | null
};

export type HighlightBlockProps = {
    height: number;
    width: number;
    bottom: number;
    left: number;
    visibility: 'hidden' | 'visible';
    position: 'absolute';
    page: number;
    nodeHierarchy?: string;
    zIndex?: number
};

export type DocumentBlockProps = {
    id: string;
    blockProps: HighlightBlockProps;
    fieldData: FormInputFieldData
};

export default class DocumentVisualStore {

    currentPage: number = 0;

    pageWidth: number | undefined = undefined;

    isRendered: boolean = false;

    scale: number = 1;

    scrollPosition: number = 0;

    sizeCoefficient: number;

    pageCoordinates: number[] = [];    

    previews: PagePreview[] = [];

    highlightedBlockProps: HighlightBlockProps | undefined;

    highlightedField: types.FormInputFieldData | undefined;

    highlightedInputId: string | undefined;  

    largePreviews: PagePreview[] = [];

    documentBlocks: DocumentBlockProps[] = [];

    showBlocks: boolean = true;

    shouldScrollBlockIntoView: boolean = true;

    highlightedInputIdSubject = new Subject<string>();
    
    ui: DynamicUiModel;

    pagesFilter: number[] = [];

    get projectApplications() {
        return this.appStore.projectApplications;
    }

    get sizeRatio() {
        return this.ui && this.ui.layoutConfiguration && this.ui.layoutConfiguration.sizeRatio ? this.ui.layoutConfiguration.sizeRatio : undefined;
    }

    get totalPages() {
        return this.filteredLargePreviews.length;
    }

    get filteredPreviews() {
        if (!this.pagesFilter.length) {
            return this.previews;
        }

        return this.previews.filter(p => this.pagesFilter.includes(p.page));
    }

    get filteredLargePreviews() {
        if (!this.pagesFilter.length) {
            return this.largePreviews;
        }

        return this.largePreviews.filter(p => this.pagesFilter.includes(p.page));
    }

    constructor(private appStore: ApplicationDefinitionsStore, private imageService: ImageService, private errorStore: ErrorStore, private documentService: DocumentService) {
        makeObservable<DocumentVisualStore, 'addBlockFromFieldData'>(this, {
            currentPage: observable,
            pageWidth: observable,
            isRendered: observable,
            scale: observable,
            scrollPosition: observable,
            previews: observable,
            highlightedBlockProps: observable,
            highlightedInputId: observable,
            largePreviews: observable,
            documentBlocks: observable,
            showBlocks: observable,
            shouldScrollBlockIntoView: observable,
            pagesFilter: observable,
            projectApplications: computed,
            sizeRatio: computed,
            totalPages: computed,
            filteredPreviews: computed,
            filteredLargePreviews: computed,
            setDocumentBlocks: action.bound,
            setShowBlocks: action.bound,
            toggleShowBlocks: action.bound,
            setHighlightedBlockProps: action.bound,
            getPackageXmlContent: action.bound,
            setScale: action.bound,
            getPreviews: action.bound,
            prepareDocumentPages: action.bound,
            setScrollPosition: action,
            setCurrentPage: action,
            setPageWidth: action,
            setIsRendered: action,
            setPageCoodinates: action.bound,
            loadApplicationsForProject: action.bound,
            prepareAllBlocks: action.bound,
            highlightBlockByInputId: action.bound,
            highlightBlock: action.bound,
            calculateMinimapHeight: action.bound,
            getNextBlockToHighlight: action.bound,
            addBlockFromFieldData: action.bound,
            scrollAndHighlightBlock: action.bound,
            setPagesFilter: action.bound
        });
    }

    setShowBlocks(showBlocks: boolean) {
        this.showBlocks = showBlocks;
    }

    toggleShowBlocks() {
        this.showBlocks = !this.showBlocks;
    }

    setHighlightedBlockProps(props: HighlightBlockProps | undefined) {
        this.highlightedBlockProps = props;
    }

    setPagesFilter(pagesFilter: number[]) {
        this.pagesFilter = pagesFilter;
    }

    async getPackageXmlContent(packageId: string) {
        try {
            const resp = await this.documentService.getXmlDocumentContent(packageId);
            return resp.unwrapOr('');
        } catch (err) {
            console.error(err);
            return '';
        }
    }

    setScale(scale: number) {
        this.scale = scale;
        this.pageCoordinates = this.pageCoordinates.map((x, i) => 
            1444 * i * scale
        );
    }

    async getPreviews(
        packageId: string,
        imageSize: ImagePreviewSize,
        indexDate: string,
        fromPage?: number,
        toPage?: number
    ) {
        const resp = await this.imageService.getImagePathsWithSizes(packageId, imageSize, indexDate, fromPage, toPage);
        runInAction(() => { 
            this.previews = [];
        });

        resp.map(data => {
            const arr: PagePreview[] = data.map((r, i) => {
                const url = this.imageService.getImageUrlFromPath(r.path, imageSize, indexDate);
                return {page: i, url, orientation: 'Portrait', imgSize: r.size};
            });

            runInAction(() => this.previews = arr);
        }).mapErr(err => this.errorStore.addError(err.data));
    }

    async prepareDocumentPages(
        packageId: string,
        indexDate: string,
        imgSize: ImagePreviewSize = ImagePreviewSize.DocumentSize,
        defaultScale: number = 1
    ) {
        const resp = await this.imageService.getImagePathsWithSizes(packageId, imgSize, indexDate);
        resp.map(data => {
            this.pageCoordinates = [];
            runInAction(() => {
                this.largePreviews = [];
                this.scale = 0;
            });

            const arr: PagePreview[] = [];

            data.forEach((r, i) => {
                const url = this.imageService.getImageUrlFromPath(r.path, imgSize, indexDate);
                let orientation: 'Portrait' | 'Landscape' = 'Portrait';

                if (r.size.width > r.size.height) {
                    orientation = 'Landscape';
                }

                arr.push(({page: i, url, orientation: orientation, imgSize: r.size}));
            });

            runInAction(() => {
                this.largePreviews = arr;
                this.isRendered = true;
                this.scale = defaultScale;
            });
        }).mapErr(err => this.errorStore.addError(err.data));
    }

    setScrollPosition(scrollPosition: number) {
        this.scrollPosition = scrollPosition;
    }

    setCurrentPage(currentPage: number, useScroll: boolean = true) {
        this.currentPage = currentPage;
        if (useScroll && this.pageCoordinates) {
            this.scrollPosition = this.pageCoordinates[currentPage];
        }
    }

    setPageWidth(width: number | undefined) {
        if (width === this.pageWidth) {
            return;
        }

        this.pageWidth = width;
    }

    setIsRendered(isRendered: boolean) {
        this.isRendered = isRendered;
    }

    setPageCoodinates(pageCoords: number[]) {
        this.pageCoordinates = pageCoords;
    }

    async loadApplicationsForProject(projectId: string) {
        await this.appStore.loadApplicationsForProject(projectId);
    }

    setDocumentBlocks(documentBlocks: DocumentBlockProps[]) {
        this.documentBlocks = documentBlocks;
    }

    prepareAllBlocks(ui: DynamicUiModel, packageId: string | undefined = undefined) {
        this.ui = ui;
        this.documentBlocks = [];
        let fields: DocumentField[] = [];
        
        fields = ui.inputs.filter(i => i.controlType !== types.WidgetType.VisualTable).map(i => {
            return {id: i.id, data: Utils.extractFieldData(i), combinedData: Utils.extractFieldDataFromCombinedMeta(i)};
        });
        if (fields) {
            fields = fields.filter(f => (f.data && f.data.bt !== 'PAGE_BLOCK') || f.combinedData);
        }

        if (packageId) {
            fields = fields.filter(f => (f.data && f.data.pId === packageId) || f.combinedData);
        }

        if (this.pageWidth == null) {
            return;
        }

        for (var field of fields) {
            if (field.combinedData) {
                for(var data of field.combinedData) {
                    this.addBlockFromFieldData(data, field.id);
                }
            } else if (field.data) {
                this.addBlockFromFieldData(field.data, field.id);
            }
        }

        let visualTables = ui.inputs.filter(i => i.controlType === types.WidgetType.VisualTable)
            .map(i => {
                return {id: i.id, data: Utils.extractVisualTableRowData(i, ui)}; 
            });
 
        for (var table of visualTables) {
            if (table.data && table.data.length) {
                table.data.forEach((td) => {
                    const sizeCoefficient = this.pageWidth! / td.data.pw;
                    const bottomPos = ((td.data.y * sizeCoefficient) - (td.data.h * sizeCoefficient));
                    this.documentBlocks.push({
                        id: td.id,
                        blockProps: {
                            bottom: bottomPos,
                            height: td.data.h * sizeCoefficient,
                            left: td.data.x * sizeCoefficient,
                            page: td.data.p,
                            position: 'absolute',
                            visibility: 'visible',
                            width: td.data.w * sizeCoefficient
                        },
                        fieldData: td.data
                    });
                });
            }
        }

        const containerInputs = ui.containerInputs || [];
        for (let containerInput of containerInputs) {
            if (containerInput.children.length === 0) {
                continue;
            }

            containerInput.children.forEach((child) => {
                if (!child.input.meta || !child.input.meta.field) {
                    return;
                }

                let meta = child.input.meta.field as FormInputFieldData;
                meta.pId = child.input.meta.packageId;

                const sizeCoefficient = this.pageWidth! / meta.pw;
                const bottomPos = ((meta.y * sizeCoefficient) - (meta.h * sizeCoefficient));

                this.documentBlocks.push({
                    id: child.input.id,
                    blockProps: {
                        bottom: bottomPos,
                        height: meta.h * sizeCoefficient,
                        left: meta.x * sizeCoefficient,
                        page: meta.p,
                        position: 'absolute',
                        visibility: 'visible',
                        width: meta.w * sizeCoefficient
                    },
                    fieldData: meta
                });
            });
        }
    }

    highlightBlockByInputId(
        inputId: string | undefined,
        documentBlock: DocumentBlockProps | undefined = undefined,
        shouldScrollIntoView: boolean = false
    ) {
        if (!inputId || inputId === this.highlightedInputId) {
            this.highlightBlock(undefined, undefined);
            return;
        } 

        documentBlock = documentBlock ? documentBlock : this.documentBlocks.find(d => d.id === inputId);
        if (documentBlock) {
            this.highlightBlock(documentBlock.fieldData, inputId, shouldScrollIntoView);
        }  else {
            this.highlightedInputId = inputId;
            this.highlightedInputIdSubject.next(inputId);
        }
    }

    highlightBlock(
        field: types.FormInputFieldData | undefined,
        inputId: string | undefined,
        shouldScrollIntoView: boolean = true
    ) {
        if (inputId) {
            this.highlightedInputId = inputId;
            this.highlightedInputIdSubject.next(inputId);
        } else {
            this.highlightedField = undefined;
            this.highlightedInputId = undefined;
            this.setHighlightedBlockProps(undefined);
            this.highlightedInputIdSubject.next(undefined);
        }

        if (this.pageWidth == null) {
            return;
        }

        if (field && inputId) {
            this.shouldScrollBlockIntoView = shouldScrollIntoView;
            this.highlightedField = field;
            this.updateHighlightedBlockProps(field);
        } 
    }

    getHighlightedBlockProps(field: types.FormInputFieldData): HighlightBlockProps {
        const fieldRelativeHeight = (field.h * 100) / field.ph;
        const fieldRelativeWidth = (field.w * 100) / field.pw;
        const fieldRelativeLeft = (field.x * 100) / field.pw;
        const fieldRelativeBottom = ((field.y - field.h) * 100) / field.ph;

        return {
            bottom: fieldRelativeBottom,
            height: fieldRelativeHeight,
            left: fieldRelativeLeft,
            width: fieldRelativeWidth,
            page: field.p,
            position: 'absolute',
            visibility: 'visible',
            nodeHierarchy: field.nodeHierarchy
        };
    }

    updateHighlightedBlockProps(field: types.FormInputFieldData) {
        this.setHighlightedBlockProps(this.getHighlightedBlockProps(field));
    }

    scrollAndHighlightBlock(inputId: string) {
        const field = this.documentBlocks.find(d => d.id === inputId)?.fieldData;

        if (!field) {
            return;
        }

        this.shouldScrollBlockIntoView = true;

        this.highlightedInputId = inputId;
        this.highlightedInputIdSubject.next(inputId);
        this.highlightedField = field;

        this.updateHighlightedBlockProps(field);
    }

    calculateMinimapHeight() {
        let height = 0;
        const pageGap = 30;
        if (this.filteredLargePreviews && this.pageWidth) {
            this.filteredLargePreviews.forEach(lp => {
                if (lp.imgSize) {
                    height += this.calculatePreviewHeight(lp.imgSize.width, this.pageWidth!, lp.imgSize.height) + pageGap;
                } 
            });
        } 
        return height;
    }

    getNextBlockToHighlight(inputId: string) {
        const blocks = this.documentBlocks.filter(d => d.id === inputId)
            .sort((a, b) => ((a.blockProps.page * (1 / a.blockProps.bottom)) - ((b.blockProps.page * (1 / b.blockProps.bottom)))))
            .sort((a, b) => (a.fieldData.pId ?? '') > (b.fieldData.pId ?? '') ? -1 : (a.fieldData.pId ?? '') < (b.fieldData.pId ?? '') ? 1 : 0);

        if (blocks == null || blocks.length === 0) {
            return null;
        }

        if(blocks.length < 2) {
            return blocks[0].fieldData;
        }

        if (!this.highlightedBlockProps || this.highlightedInputId !== inputId) {
            return blocks[0].fieldData;
        }
        const highlightedBlock = blocks.map(b => b.blockProps).find(b => this.blocksAreEqual(this.highlightedBlockProps!, b));

        if (!highlightedBlock) {
            return blocks[0].fieldData;
        }

        const highlightedIndex = blocks.map(b => b.blockProps).indexOf(highlightedBlock);

        if (highlightedIndex === blocks.length - 1) {
            return blocks[0].fieldData;
        }

        return blocks[highlightedIndex + 1].fieldData;
    }

    private addBlockFromFieldData(data: FormInputFieldData, id: string) {
        if (!this.pageWidth) { 
            return; 
        }

        const sizeCoefficient = this.pageWidth / data.pw;
        const bottomPos = ((data.y * sizeCoefficient) - (data.h * sizeCoefficient));
        this.documentBlocks.push({
            id: id,
            blockProps: {
                bottom: bottomPos,
                height: data.h * sizeCoefficient,
                left: data.x * sizeCoefficient,
                page: data.p,
                position: 'absolute',
                visibility: 'visible',
                width: data.w * sizeCoefficient
            },
            fieldData: data
        });
    }

    private blocksAreEqual(b1: HighlightBlockProps, b2: HighlightBlockProps) {
        return b1.bottom === b2.bottom && b1.left === b2.left && b1.height === b2.height && b1.width === b2.width; 
    }

    calculatePreviewHeight(imgWidth: number, previewWidth: number, imgHeight: number) {
        return imgHeight * (previewWidth / imgWidth);
    }
    
    sortByPageNumber (prev1: PagePreview, prev2: PagePreview) {
        if (prev1.page > prev2.page) {
            return 1;            
        } else if (prev1.page < prev2.page) {
            return -1;
        } else {
            return 0;
        }
    }
}
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { AskAlphaQuestionModel } from '../../models';
import { useAskAlphaStores } from '../../stores/context';
import CopyMessageButton from './CopyMessageButton';
import './ChatDisplayItems.less';

type Props = {
    question: AskAlphaQuestionModel;
    addRef: (questionId: string, questionRef: React.RefObject<HTMLDivElement>) => void
};

const ChatbotQuestion: React.FC<Props> = ({ question, addRef }) => {
    const { askAlphaDocumentStore } = useAskAlphaStores();

    const [showCopyQuestionButton, setShowCopyQuestionButton] = React.useState(false);

    const [showCopyAnswerButton, setShowCopyAnswerButton] = React.useState(false);

    const ref = React.useRef<HTMLDivElement>(null);

    const onPackageFieldClick = (fieldId: string) =>
        askAlphaDocumentStore.highlightedInputId === fieldId
            ? askAlphaDocumentStore.highlightBlock(undefined, undefined)
            : askAlphaDocumentStore.scrollAndHighlightBlock(fieldId);

    React.useEffect(() => {
        addRef(question.id, ref);
    }, [question.id, addRef]);

    return (
        <div ref={ref} className="item-container">
            <div
                className="item message"
                onMouseEnter={() => setShowCopyQuestionButton(true)}
                onMouseLeave={() => setShowCopyQuestionButton(false)}
            >
                <CopyMessageButton message={question.message} isVisible={showCopyQuestionButton} />

                <span>{question.message}</span>
            </div>

            {question.processing && <div className="item-processing">...</div>}

            {question.response && (
                <div
                    className="item answer"
                    onMouseEnter={() => setShowCopyAnswerButton(true)}
                    onMouseLeave={() => setShowCopyAnswerButton(false)}
                >
                    <CopyMessageButton message={question.response.message} isVisible={showCopyAnswerButton} />

                    <span>{question.response.message}</span>

                    <ul className="package-field-list">
                        {question.response.packageFields.map(packageField => (
                            <li key={packageField.field.id}>
                                <span
                                    className="package-field"
                                    onClick={() => onPackageFieldClick(packageField.field.id)}
                                >
                                    {packageField.label}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default observer(ChatbotQuestion);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { AskAlphaErrorModel } from '../../models';
import CopyMessageButton from './CopyMessageButton';
import './ChatDisplayItems.less';

type Props = {
    error: AskAlphaErrorModel;
    addRef: (questionId: string, questionRef: React.RefObject<HTMLDivElement>) => void
};

const ChatErrorMessage: React.FC<Props> = ({ error, addRef }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const [showCopyErrorButton, setShowCopyErrorButton] = React.useState(false);

    React.useEffect(() => {
        addRef(error.id, ref);
    }, [error.id, addRef]);

    return (
        <div
            className="item-container"
            ref={ref}
            onMouseEnter={() => setShowCopyErrorButton(true)}
            onMouseLeave={() => setShowCopyErrorButton(false)}
        >
            <div className="item error">
                <CopyMessageButton message={error.message} isVisible={showCopyErrorButton} />
                <span>{error.message}</span>
            </div>
        </div>
    );
};

export default observer(ChatErrorMessage);

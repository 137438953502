import { HomeVisualStore, PreviewsVisualStore, AnalyzeVisualStore } from '../stores';
import { SessionsVisualStore } from '../../sessions/stores';
import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { Card, List, Tooltip, Spin, Skeleton, Button } from 'antd';
import { ApplicationSession } from '../../common/services/types';
import { TabsStore } from '../../common/stores';
import ImageWithFallback from '../../common/components/ImageWithFallback';
import errorImg from '../../common/resources/images/image-error.png';
import { Utils } from '../../common/misc/Utils';
import { hasObjectPermission, hasPermission, HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import authContext from '../../authorization/AuthContext';

type Props = {
    store: HomeVisualStore;
    tabsStore: TabsStore;
    previewsStore: PreviewsVisualStore;
    analyzeStore: AnalyzeVisualStore;
    sessionsVisualStore: SessionsVisualStore
};

export const OpenSessionsCard: React.FC<Props> = ({ store, tabsStore, previewsStore, analyzeStore, sessionsVisualStore }) => {
    const authCtx = React.useContext(authContext);

    const viewSession = async (item: ApplicationSession) => {
        await tabsStore.addTab({
            id: item.id,
            title: item.packageName,
            isSelected: true,
            type: item.isBulkSession ? 'multidocument' : 'document',
            metadata: {
                packageId: item.packageId,
                sessionId: item.id,
                packageName: item.packageName,
                dynamicUI: undefined,
                sessionError: item.error,
                sessionState: item.state,
                runtimeSessionId: item.runtimeSessionId,
                packageSetId: item.packageSetId,
                projectName: item.projectName,
                projectId: item.projectId,
                isReloadable: item.isReloadable,
                customUI: item.capabilities?.customUI,
                isBulk: item.isBulkSession,
                applicationData: {
                    appName: item.applicationName,
                    appId: item.applicationDefinitionId,
                    appExtension: item.applicationExtension,
                    appSettings: item.applicationSettings,
                    iotaApplication: item.iotaApplication
                }
            }
        });
    };

    const getArchiveButton = (id: string) => {
        return (
            <HasPermission permissionClaim={AppPermissions.CanMoveToArchive}>
                <Tooltip title="Move to archive">
                    <i className="alpha-icon sm archive" style={{position: 'absolute', bottom: 13, right: 11}} onClick={(e) => handleArchiveClick(e, id)} />
                </Tooltip>
            </HasPermission>);
    };

    const handleArchiveClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => {
        e.stopPropagation();
        analyzeStore.moveSessionToArchive(id);
    };

    const generateFooter = (item: ApplicationSession) => {
        const footerMsg = item.error && item.error.trim() !== '' ? 'Error. View details' : 'Complete the form';
        switch (item.state) {
        case 'NotStarted':
        case 'Pending':
            return (
                <div className="card-footer">
                    <div className="card-footer-progress">
                        <Spin className="session-loading-indicator" size="small"/>
                        <div className="faded-label active">Processing...</div>
                    </div>
                    {getArchiveButton(item.id)}
                </div>
            );
        case 'WaitingAction':
            return (
                <div className="card-footer">
                    <div className={`card-footer-link ${item.error && item.error.trim() !== '' ? 'danger' : ''}`}>
                        <i className="alpha-icon xs arrow-right-circle"/>
                        {footerMsg} &nbsp;
                    </div>
                    {getArchiveButton(item.id)}
                </div>
            );
        default:
            return;
        }
    };

    const handleCardClick = (item: ApplicationSession) => {
        if(!hasPermission(authCtx.permissions, [AppPermissions.CanOpenSessions, AppPermissions.CanManageSessionsAndDocuments])
        && !hasObjectPermission(authCtx.objectPermissions, item.projectId, [AppPermissions.CanOpenSessions, AppPermissions.CanManageSessionsAndDocuments])) {
            return;
        }

        switch (item.state) {
        case 'NotStarted':
        case 'Pending':
            if (item.error && item.error.trim() !== '') {
                viewSession(item);
            }
            analyzeStore.openProgressModalForSession(item);
            break;
        case 'WaitingAction':
            viewSession(item);
            break;
        default:
            return;
        }
    };

    const getDocPreviewCssClass = (item: ApplicationSession) => {
        let stateClass = previewsStore.smallPreviewUrls[item.packageId ?? item.packageSetId] ? item.state : 'NotStarted';
        const name = `document-preview-container ${stateClass}`;
        if (item.packageSetId) {
            return `${name} multiple`;
        }
        return name;
    };

    return (
        <Card className="content-card borderless">
            <div className="card-header">
                <span className="content-card-title">
                    Open sessions
                </span>

                <Observer>
                    {() => (
                        <List
                            className="alpha-list"
                            grid={{
                                gutter: 26,
                            }}
                            dataSource={store.openSessions}
                            renderItem={(item) => (
                                <List.Item key={`open-session-${item.id}`} className="alpha-list-item">
                                    <Card
                                        className={`content-card mini ${item.error && item.error.trim() !== '' ? 'danger' : ''}`}
                                        onClick={() => handleCardClick(item)}
                                        style={{cursor: 'pointer'}}
                                    >
                                        {item.packageSetId && previewsStore.smallPreviewUrls[item.packageId ?? item.packageSetId] && item.state === 'WaitingAction' 
                                            ? <div className="preview-second-doc"/> 
                                            : <></>}
                                        <div className={getDocPreviewCssClass(item)}>
                                            <div className="document-preview-content">
                                                <Observer>
                                                    {() => (
                                                        previewsStore.smallPreviewUrls[item.packageId ?? item.packageSetId] && !['Pending', 'NotStarted'].includes(item.state)
                                                            ? <ImageWithFallback 
                                                                src={previewsStore.smallPreviewUrls[item.packageId ?? item.packageSetId] || ''} 
                                                                className="document-preview"
                                                                fallbackSrc={errorImg}
                                                            /> :
                                                            <Skeleton className="alpha-block-skeleton" active paragraph={{rows: 3}}/>
                                                    )}
                                                </Observer>
                                            </div>
                                        </div>
                                        <div className="card-separator" />
                                        <div className={`document-info-container ${item.state}`}>
                                            <Tooltip title={item.packageName}>
                                                {item.packageSetId && !item.isBulkSession ? 
                                                    <div className="title multiple">{item.packageName.split(',').length} documents</div> :
                                                    <div className="title">{ item.packageName}</div>
                                                }
                                            </Tooltip>
                                            <div className="additional-info">
                                                <Observer>
                                                    {() => (
                                                        <>
                                                            {item.applicationName} 
                                                            &nbsp;({Utils.formatDateStringShort(item.updated, false, true)})
                                                            <div className="username-label">
                                                                {item.userId && store.allUsers[item.userId] ? `by ${store.allUsers[item.userId]} ` : ''}
                                                            </div>
                                                        </>
                                                    )}
                                                </Observer>
                                            </div>
                                        </div>
                                        <HasPermission permissionClaim={[AppPermissions.CanOpenSessions, AppPermissions.CanManageSessionsAndDocuments]}>        
                                            {generateFooter(item)}
                                        </HasPermission>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    )}
                </Observer>
                <Button 
                    className="load-more-button light"
                    onClick={() => sessionsVisualStore.loadMoreOpenSessions()}
                    disabled={sessionsVisualStore.openSessionsLoading}
                    style={{ display: sessionsVisualStore.openSessionsParams.total === store.openSessions.length ? 'none' : ''}}
                >
                    Load More
                </Button>
            </div>
        </Card>
    );
};

export default observer(OpenSessionsCard);
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomSelect, CustomSelectProps } from '../../../custom_shared/components';
import { WithInput } from '../../types';
import { useInputHighlighting, useGlobalInputState } from '../../hooks';

type Props = WithInput<CustomSelectProps>;

const CustomSelectWrapper: React.FC<Props> = (props: Props) => {
    const { input, ...rest } = props;

    const [containerRef, setContainerRef] = React.useState<React.RefObject<HTMLDivElement> | null>(null);

    const { containerStyle } = useInputHighlighting(input, containerRef);

    const globalInputState = useGlobalInputState(props);

    return (
        <CustomSelect
            containerStyle={containerStyle}
            filterOption={(text, option) => option?.title && option.title.toLowerCase().includes(text.toLowerCase())}
            getContainerRef={setContainerRef}
            showSearch
            allowClear
            {...rest}
            {...globalInputState}
        />
    );
};

export default observer(CustomSelectWrapper);

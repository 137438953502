import { makeObservable, computed, reaction, observable, runInAction } from 'mobx';
import { Disposer } from '../../custom_shared/misc';
import { InstanceUtils } from '../misc';
import TabStore from './TabStore';

export default class InputStore extends Disposer {
    highlightedInputGuid: string | null = null;

    highlightedInputTimeout: ReturnType<typeof setTimeout> | null = null;

    constructor(private readonly tabStore: TabStore) {
        super();

        makeObservable(this, {
            highlightedInputGuid: observable,
            allInputs: computed,
            allInputsWithSource: computed,
            inputsLoading: computed
        });

        this.reactions.push(
            reaction(
                () => this.allInputsWithSource,
                allInputsWithSource => {
                    allInputsWithSource.forEach(input => {
                        const fetchSources = input.parentInput
                            ? input.sourceProvider.getSourceOptionsByParent(input.parentInput.value)
                            : input.sourceProvider.getSourceOptions();

                        input.sourceProvider.setLoading(true);

                        fetchSources.finally(() => input.sourceProvider.setLoading(false));
                    });
                }
            )
        );
    }

    setHighlightedInputGuid(highlightedInputGuid: string | null, timeout: number = 0) {
        if (this.highlightedInputTimeout) {
            clearTimeout(this.highlightedInputTimeout);
        }

        this.highlightedInputTimeout = setTimeout(() => {
            runInAction(() => {
                this.highlightedInputGuid = highlightedInputGuid;
            });
        }, timeout);
    }

    get allInputs() {
        return this.tabStore.tabs.flatMap(tab => tab.inputs);
    }

    get allInputsWithSource() {
        return this.allInputs.filter(InstanceUtils.isInputWithSource);
    }

    get inputsLoading() {
        return this.allInputsWithSource.some(input => input.sourceProvider.loading);
    }

    dispose() {
        this.disposeReactions();
    }
}

import * as React from 'react';
import { useAppStore } from '../stores';

export default <T extends { disabled?: boolean }>(props: T) => {
    const { sessionStore } = useAppStore();

    const [disabled, setDisabled] = React.useState<boolean | undefined>();

    React.useEffect(() => {
        if (sessionStore.contractSent) {
            setDisabled(true);
            return;
        }

        setDisabled(props.disabled);
    }, [sessionStore.contractSent, props.disabled]);

    return {
        disabled
    };
};

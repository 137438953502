import * as React from 'react';
import DocumentHelper from '../misc/DocumentHelper';
import { CustomRuntimeInput } from '../types';
import { DocumentVisualStore } from '../../documents/stores';

interface Props {
    documentVisualStore: DocumentVisualStore;
    inputs: CustomRuntimeInput[];
    packageId: string
}

export default ({ documentVisualStore, inputs, packageId }: Props) => {
    React.useEffect(() => {
        if (!documentVisualStore.pageWidth) {
            return;
        }

        const documentBlocks = DocumentHelper.prepareDocumentBlocks(inputs, documentVisualStore.pageWidth, packageId);

        documentVisualStore.setDocumentBlocks(documentBlocks);
    }, [packageId, inputs, documentVisualStore, documentVisualStore.pageWidth]);
};

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomDatePickerWrapper, InputActions, CustomFormItem } from '../../components';
import { InputModel } from '../../models';
import { ValidationMessages } from '../../misc';
import { InputWithoutSource } from '../../componentTypes';

const config = {
    label: 'Period start'
};

const PeriodStartInput: InputWithoutSource = ({ input }) => {
    return (
        <CustomFormItem
            name={input.guid}
            required={input.required}
            message={ValidationMessages.requiredInput(config.label)}
        >
            <CustomDatePickerWrapper
                input={input}
                label={config.label}
                topRightNode={<InputActions input={input} />}
                required={input.required}
            />
        </CustomFormItem>
    );
};

PeriodStartInput.meta = {
    inputType: InputModel.name
};

export default observer(PeriodStartInput);

import * as React from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { CustomInputLabel } from '..';
import './CustomTextArea.less';
import '../../../custom_shared/styles/common.less';

export interface CustomTextAreaProps extends Omit<TextAreaProps, 'placeholder'> {
    label: string;
    required?: boolean;
    topRightNode?: React.ReactNode;
    containerStyle?: React.CSSProperties;
    hasError?: boolean;
    getContainerRef?: (containerRef: React.RefObject<HTMLDivElement>) => void
}

const CustomTextArea: React.FC<CustomTextAreaProps> = (props: CustomTextAreaProps) => {
    const { label, required, topRightNode, containerStyle, hasError, getContainerRef, ...textAreaProps } = props;

    const containerRef = React.useRef<HTMLDivElement>(null);

    const textAreaRef = React.useRef<TextAreaRef>(null);

    React.useEffect(() => {
        if (getContainerRef) {
            getContainerRef(containerRef);
        }
    }, [getContainerRef]);

    const getContainerClassName = () => {
        let className = 'custom-textarea-container';

        if (hasError) {
            className += ' has-error';
        }

        return className;
    };

    return (
        <div
            ref={containerRef}
            className={getContainerClassName()}
            onClick={() => textAreaRef.current?.focus()}
            style={containerStyle}
        >
            {!!topRightNode && <div className="top-right-node-container">{topRightNode}</div>}

            <Input.TextArea
                ref={textAreaRef}
                className="custom-textarea custom-scroll"
                placeholder={label}
                autoSize={{ minRows: 1, maxRows: 5 }}
                {...textAreaProps}
            />

            <CustomInputLabel label={label} required={required} />
        </div>
    );
};

export default CustomTextArea;

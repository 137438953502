import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse } from 'antd';
import { BasicTabModel } from '../../models';
import './TabCollapse.less';

interface Props {
    tab: BasicTabModel;
    children: React.ReactNode
}

const TabCollapse: React.FC<Props> = ({ tab, children }: Props) => {
    return (
        <Collapse
            className="tab-collapse"
            activeKey={tab.expanded ? [tab.id] : []}
            onChange={tab.toggleExpanded}
            bordered={false}
        >
            <Collapse.Panel
                className="tab-collapse-panel"
                header={<span className="header-title">{tab.name}</span>}
                key={tab.id}
            >
                {children}
            </Collapse.Panel>
        </Collapse>
    );
};

export default observer(TabCollapse);

/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const ReloadIcon: React.FC<IconProps> = ({ width = 24, height = 24, color = '#9BA0AA', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12.0156 10.8203C12.2109 10.8203 12.3594 10.7578 12.4688 10.6406L15.6875 7.40625C15.8281 7.27344 15.8828 7.11719 15.8828 6.9375C15.8828 6.76562 15.8125 6.59375 15.6875 6.46875L12.4688 3.20312C12.3594 3.07812 12.2031 3.01562 12.0156 3.01562C11.6641 3.01562 11.3984 3.29688 11.3984 3.65625C11.3984 3.82031 11.4609 3.97656 11.5703 4.10156L13.6562 6.14844C13.2109 6.07031 12.7578 6.02344 12.3047 6.02344C8.47656 6.02344 5.39844 9.09375 5.39844 12.9219C5.39844 16.7578 8.47656 19.8359 12.3047 19.8359C16.1328 19.8359 19.2031 16.7578 19.2031 12.9219C19.2031 12.5469 18.9375 12.2734 18.5547 12.2734C18.1875 12.2734 17.9453 12.5469 17.9453 12.9219C17.9453 16.0625 15.4375 18.5781 12.3047 18.5781C9.17188 18.5781 6.65625 16.0625 6.65625 12.9219C6.65625 9.78906 9.17188 7.28125 12.3047 7.28125C12.8984 7.28125 13.4453 7.32812 13.9141 7.42969L11.5781 9.75C11.4609 9.875 11.3984 10.0312 11.3984 10.1953C11.3984 10.5547 11.6641 10.8203 12.0156 10.8203Z"
            fill={color}
        />
    </svg>
);

export default ReloadIcon;

import { LayoutSettingsBaseModel } from '..';
import { SectionsLayoutSettingsDto } from '../../types';

export default class SectionsLayoutSettingsModel extends LayoutSettingsBaseModel {
    readonly numberOfSections: number;

    constructor(data: SectionsLayoutSettingsDto) {
        super(data);

        this.numberOfSections = data.numberOfSections;
    }

    get label() {
        return 'Sections Layout';
    }

    get dto(): SectionsLayoutSettingsDto {
        return {
            type: this.type,
            numberOfSections: this.numberOfSections
        };
    }
}

import appClient from '../../common/services/AppClient';
import { StartSessionResponse, SessionStates } from '../../common/services/types';
import { CustomSessionState, CustomSessionRuntimeData } from '../types';

export default class CustomSessionService {
    getSessionState<TResponse>(sessionExtension: string, sessionId: string) {
        const url = process.env.REACT_APP_IOTA_URL + `runtime/${sessionExtension}/${sessionId}`;
        return appClient.get<CustomSessionState<TResponse>>(url);
    }

    continueSession(sessionExtension: string, sessionId: string, action: string, inputs: CustomSessionRuntimeData) {
        const url = process.env.REACT_APP_IOTA_URL + `runtime/${sessionExtension}/${sessionId}`;
        return appClient.post(url, { inputs, action });
    }

    startSession(applicationId: string, packageId: string, parameters: Record<string, unknown> | null = null) {
        const url = process.env.REACT_APP_MANAGE_URL + `iotaruntime/applications/${applicationId}/sessions/${packageId}`;
        return appClient.post<StartSessionResponse>(url, { parameters });
    }

    updateSession(sessionId: string, state: SessionStates) {
        const request = {
            id: sessionId,
            state: state
        };

        const url = process.env.REACT_APP_MANAGE_URL + 'iotaruntime/applications/sessions';
        return appClient.update(url, request);
    }

    async getFileFromIotaTempStorage(filePath: string) {
        const url = `${process.env.REACT_APP_IOTA_URL}files/download`;
        const resp = await appClient.post(url, { filePath }, 'blob');

        resp.map(data => {
            const blobURL = URL.createObjectURL(data as Blob);
            const tempLink = document.createElement('a');

            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', filePath.replace(/^.*[\\/]/, ''));

            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }

            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);

            setTimeout(() => {
                URL.revokeObjectURL(blobURL);
            }, 100);
        });
    }
}

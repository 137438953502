import * as React from 'react';
import { debounce } from 'lodash';

export default (containerRef: React.RefObject<HTMLDivElement>, selector: string) => {
    const [hasSelectedElement, setHasSelectedElement] = React.useState(false);

    const updateStatus = React.useCallback(() => {
        if (containerRef.current) {
            setHasSelectedElement(!!containerRef.current.querySelector(selector));
        }
    }, [containerRef, selector]);

    const updateStatusDebounced = React.useMemo(
        () =>
            debounce(() => {
                updateStatus();
            }, 100),
        [updateStatus]
    );

    React.useEffect(() => {
        updateStatus();

        const observer = new MutationObserver(updateStatusDebounced);

        if (containerRef.current) {
            observer.observe(containerRef.current, {
                childList: true,
                subtree: true,
                attributes: true,
                characterData: true
            });
        }

        return () => {
            observer.disconnect();
            updateStatusDebounced.cancel();
        };
    }, [selector, containerRef, updateStatus, updateStatusDebounced]);

    return { hasSelectedElement };
};

import { action, observable, runInAction, makeObservable } from 'mobx';
import { WorkflowInstanceStore } from '../../common/stores';
import { DynamicUiModel } from '../../common/stores/DynamicUiModel';
import { DocumentVisualStore } from '../../documents/stores';
import { SessionService } from '../../common/services';
import { ApplicationSession, TabApplicationData } from '../../common/services/types';

export default class SessionPreviewStore {
    session: ApplicationSession | undefined = undefined;

    sessionIsLoading: boolean = false;

    docStore: DocumentVisualStore | undefined = undefined;

    ui: DynamicUiModel | undefined;

    constructor(private sessionService: SessionService, private workflowInstance: WorkflowInstanceStore) {
        makeObservable(this, {
            session: observable,
            sessionIsLoading: observable,
            docStore: observable,
            ui: observable,
            setUiModel: action.bound,
            setSessionIsLoading: action.bound,
            setStore: action,
            loadSession: action.bound
        });
    }

    setUiModel(ui: DynamicUiModel | undefined) {
        this.ui = ui;
    }

    setSessionIsLoading(isLoading: boolean) {
        this.sessionIsLoading = isLoading;
    }

    setStore(docStore: DocumentVisualStore | undefined) {
        this.docStore = docStore;
    }

    async loadSession(sessionId: string) {
        this.setSessionIsLoading(true);
        const session = await this.sessionService.getSessionById(sessionId);
        runInAction(() => {
            this.session = session ?? undefined;
        });

        if (session) {
            this.workflowInstance.sessionExtension[sessionId] = session?.applicationExtension ?? '';
            const appData: TabApplicationData = {
                appExtension: session.applicationExtension,
                appId: session.applicationDefinitionId,
                appName: session.applicationName,
                appSettings: session.applicationSettings,
                iotaApplication: session.iotaApplication
            };
            const ui = await this.workflowInstance.getSessionUiModel(sessionId, sessionId, appData);
            this.setUiModel(ui);
        }

        this.setSessionIsLoading(false);
    }
}
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomInputNumber, CustomInputNumberProps } from '../../../custom_shared/components';
import { WithInput } from '../../types';
import { useInputHighlighting, useGlobalInputState } from '../../hooks';

type Props = WithInput<CustomInputNumberProps>;

const CustomPercentageInputWrapper: React.FC<Props> = (props: Props) => {
    const { input, ...rest } = props;

    const [containerRef, setContainerRef] = React.useState<React.RefObject<HTMLDivElement> | null>(null);

    const { containerStyle } = useInputHighlighting(input, containerRef);

    const globalInputState = useGlobalInputState(props);

    return (
        <CustomInputNumber
            containerStyle={containerStyle}
            getContainerRef={setContainerRef}
            min={0}
            max={100}
            {...rest}
            {...globalInputState}
        />
    );
};

export default observer(CustomPercentageInputWrapper);

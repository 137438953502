/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const SearchIcon: React.FC<IconProps> = ({ width = 18, height = 18, color = '#9BA0AA', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8.10352 12.6152C9.08203 12.6152 9.98438 12.2988 10.7227 11.7715L13.5 14.5488C13.6289 14.6777 13.7988 14.7422 13.9805 14.7422C14.3613 14.7422 14.6309 14.4492 14.6309 14.0742C14.6309 13.8984 14.5723 13.7344 14.4434 13.6055L11.6836 10.8398C12.2637 10.0781 12.6094 9.13477 12.6094 8.10938C12.6094 5.63086 10.582 3.60352 8.10352 3.60352C5.63086 3.60352 3.59766 5.625 3.59766 8.10938C3.59766 10.5879 5.625 12.6152 8.10352 12.6152ZM8.10352 11.6426C6.16992 11.6426 4.57031 10.043 4.57031 8.10938C4.57031 6.17578 6.16992 4.57617 8.10352 4.57617C10.0371 4.57617 11.6367 6.17578 11.6367 8.10938C11.6367 10.043 10.0371 11.6426 8.10352 11.6426Z"
            fill={color}
        />
    </svg>
);

export default SearchIcon;

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomInputNumberWrapper, CustomFormItem } from '../../components';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';

const config = {
    label: 'Limits'
};

const LimitValueInput: InputWithoutSource = ({ input }) => {
    return (
        <CustomFormItem name={input.guid}>
            <CustomInputNumberWrapper input={input} label={config.label} />
        </CustomFormItem>
    );
};

LimitValueInput.meta = {
    inputType: InputModel.name
};

export default observer(LimitValueInput);

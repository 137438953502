import * as React from 'react';
import { ContainerInput, FormInputFieldData, FormInputParams, ContainerChildInput } from '../../common/services/types';
import { observer } from 'mobx-react-lite';
import { DynamicUiModel } from '../../common/stores/DynamicUiModel';
import Registry from './DynamicControlsRegistry';
import { Table, Row, Col } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { SimpleContainerChildInput } from './dynamiccontrols/SimpleContainerChildInput';
import { Subject } from 'rxjs';

type Props = {
    containerInput: ContainerInput;
    ui: DynamicUiModel;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleAction?: (id: string, action: string, value?: string) => void;
    form: FormInstance;
    onHighlightBlock: (field: FormInputFieldData, inputId: string) => void;
    getGearIcon: (inputParams: FormInputParams) => false | JSX.Element | undefined;
    highlightInputSubject?: Subject<string>
};

type Row = {
    row: number;
    inputs: ContainerChildInput[]
};

type Column = {
    title: string | undefined;
    key: string;
    render: (text: string, record: {
        row: number;
        inputs: ContainerChildInput[]
    },       index: number) => JSX.Element | null;
    width: string | number
};

const DynamicContainerInput: React.FC<Props> = ({containerInput, ui, form, handleAction, onHighlightBlock, highlightInputSubject, getGearIcon}) => {
    const getContainerChildInput = React.useCallback((input: ContainerChildInput | undefined) => {
        if (!input) {
            return null;
        }

        if (containerInput.behavior?.readonly) {
            if (input.input.behavior) {
                input.input.behavior.readonly = true;
            } else {
                input.input.behavior = {
                    readonly: true
                };
            }
        }

        if (containerInput.behavior && containerInput.behavior.useSimpleContainerControls === true) {

            return (
                <SimpleContainerChildInput
                    key={input.input.id}
                    inputParams={input.input}
                    form={form}
                    onHighlightBlock={onHighlightBlock}
                    highlightInputSubject={highlightInputSubject}
                />
            );
        } else {
            let WidgetComponent = Registry[input.input.controlType]!;
            return (
                <WidgetComponent
                    key={input.input.id}
                    inputParams={input.input}
                    onAction={handleAction}
                    ui={ui}
                    form={form}
                    onHighlightBlock={onHighlightBlock}
                    highlightInputSubject={highlightInputSubject}
                    getGearIcon={getGearIcon}
                    highlightOnFocus={false}
                />
            );
        }
    }, [containerInput.behavior, form, getGearIcon, handleAction, highlightInputSubject, onHighlightBlock, ui]);

    const init = React.useCallback(() => {
        let columns: Column[] = [];
        let rows: Row[] = [];

        if (!containerInput.rows || !containerInput.columns) {
            return;
        }

        for (let i = 0; i < containerInput.rows; i++) {
            rows.push({
                row: i,
                inputs: containerInput.children.filter(r => r.row === i)
            });
        }

        for (let i = 0; i < containerInput.columns; i++) {
            const behavior = rows[0].inputs.find(r => r.column === i)!.input.behavior;
            const columnWidth = behavior && behavior.width && 480 * behavior.width; 
            columns.push({
                title: containerInput.headers ? containerInput.headers[i] || '' : undefined,
                key: `${containerInput.id}-col-${i}`,
                render: (text: string, record: {row: number; inputs: ContainerChildInput[]}, index: number) => {
                    if (record.row === index) {
                        var input = record.inputs.find(r => r.column === i);
                        return getContainerChildInput(input);
                    }
                    return null;
                },
                width: columnWidth  || 'auto'
            });
        }

        setTableRows(rows);
        setTableColumns(columns);
    }, [containerInput.children, containerInput.columns, containerInput.headers, containerInput.id, containerInput.rows, getContainerChildInput]);

    React.useEffect(() => {
        let obj = {};
        containerInput.children.forEach(c => {
            // TODO: Revise this check and find a better way
            obj[c.input.id] = ['number', 'decimal'].includes(c.input.type) && typeof(c.input.value) === 'string' ?  Number(c.input.value.replace(/,/g, '')) : c.input.value;
        });
        form.setFieldsValue(obj);
        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },              [containerInput.behavior, containerInput.children]);
    
    // React.useEffect(() => {
    //     if (containerInput.behavior && containerInput.behavior.useSimpleContainerControls === true && highlightedInput) {
    //         form.scrollToField(highlightedInput);
    //     }
    // },              [highlightedInput]);

    const [tableRows, setTableRows] = React.useState<Row[]>([]);
    const [tableColumns, setTableColumns] = React.useState<Column[]>([]);

    const getFooter = () => {
        if (containerInput.footer) {
            return <Row style={{ justifyContent: containerInput.behavior?.horizontalAlignment === 'center' ? 'center' : undefined }}> {
                containerInput.footer.inputs.map((x, i) => {
                    if (containerInput.behavior?.readonly) {
                        if (x.behavior) {
                            x.behavior.readonly = true;
                        } else {
                            x.behavior = {
                                readonly: true
                            };
                        }
                    }

                    const WidgetComponent = Registry[x.controlType]!;
                    return (
                        <Col span={x.behavior && x.behavior.horizontalAlignment === 'center' ? undefined : 15} key={i}>
                            <WidgetComponent
                                key={x.id}
                                inputParams={x}
                                onAction={handleAction}
                                ui={ui}
                                form={form}
                                onHighlightBlock={onHighlightBlock}
                                highlightInputSubject={highlightInputSubject}
                                getGearIcon={getGearIcon}
                            />
                        </Col>);
                
                })}
            </Row>;
        } else {
            return <></>;
        }
    };

    if (!tableRows.length) {
        return null;
    }

    return (
        <Table 
            className="container-inputs-table"
            rowClassName="container-input-row"
            key={containerInput.id}
            columns={tableColumns}
            dataSource={tableRows}
            pagination={false}
            tableLayout={'auto'}
            rowKey={(record) => {
                return record.row; 
            }}
            footer={() => getFooter()}
        />
    );
};

export default observer(DynamicContainerInput);
import { action, observable, runInAction, computed, makeObservable } from 'mobx';
import { WorkflowInstanceStore, ProjectsStore, TabsStore, UserProfileStore } from '../../common/stores';
import { ApplicationSession, SessionFilter, SessionStates } from '../../common/services/types';
import { ApplicationSessionChanges } from '../types';
import { SessionService } from '../../common/services';
import PushClient from '../../common/services/ManagePushClient';

type SessionParams = {
    filter: SessionFilter;
    total: number
};

export default class SessionsVisualStore {
    static openSessionsStates: SessionStates[] = ['Pending', 'WaitingAction', 'NotStarted'];

    static archiveSessionsStates: SessionStates[] = ['Aborted', 'Finished', 'Archived'];

    static recentFinishedSessionsStates: SessionStates[] = ['Aborted', 'Finished'];

    openSessions: ApplicationSession[] = [];

    recentFinishedSessions: ApplicationSession[] = [];

    archiveSessions: ApplicationSession[] = [];

    openSessionsParams: SessionParams = {
        filter: {
            page: 0,
            pageSize: 24,
            sessionStates: SessionsVisualStore.openSessionsStates
        },
        total: 0
    };

    archiveSessionsParams: SessionParams = {
        filter: {
            page: 0,
            pageSize: 15,
            sessionStates: SessionsVisualStore.archiveSessionsStates
        },
        total: 0
    };

    recentFinishedSessionsParams: SessionParams = {
        filter: {
            page: 0,
            pageSize: 5,
            orderByField: 'updated',
            orderByDirection: 'DESC',
            sessionStates: SessionsVisualStore.recentFinishedSessionsStates
        },
        total: 0
    };

    openSessionsLoading: boolean = false;

    archiveSessionsLoading: boolean = false;

    loadForCurrentUserOnly: boolean = false;

    get allUsers() {
        return this.projectsStore.allUsers;
    }

    get allUsersList() {
        return Object.entries(this.allUsers).map(([id, name]) => ({ id, name }));
    }

    get projectIds() {
        return this.projectsStore.projects.map(p => p.id);
    }

    get userId() {
        return this.userProfileStore.currentUserProfileInfo.userId;
    }

    get hasAccessToAllEntities() {
        return this.userProfileStore.hasAccessToAllEntities;
    }

    constructor(
        private workflowInstanceStore: WorkflowInstanceStore, 
        private projectsStore: ProjectsStore, 
        private tabsStore: TabsStore, 
        private userProfileStore: UserProfileStore,
        private sessionService: SessionService
    ) {
        makeObservable(this, {
            loadForCurrentUserOnly: observable,
            openSessions: observable,
            recentFinishedSessions: observable,
            archiveSessions: observable,
            openSessionsParams: observable,
            archiveSessionsParams: observable,
            openSessionsLoading: observable,
            archiveSessionsLoading: observable,
            allUsers: computed,
            allUsersList: computed,
            projectIds: computed,
            userId: computed,
            loadApplicationSessions: action.bound,
            updateSessionState: action.bound,
            moveSessionToArchive: action,
            switchToSession: action,
            setOpenSessionsParams: action,
            setArchiveSessionsParams: action,
            setOpenSessionsLoading: action,
            setArchiveSessionsLoading: action,
            setLoadForCurrentUserOnly: action,
        });

        this.projectsStore.sessionChanges.subscribe((s) => {
            this.updateSessionState(s); 
        });
        PushClient.ConnectionStatusSubject.subscribe((connected) => {
            if (connected && PushClient.ReconnectAttemptCount > 0) {   
                const promises = [this.loadOpenSessions(), this.loadRecentFinishedSessions(), this.loadArchiveSessions()];
                Promise.all(promises);             
            }
        });
    }

    setOpenSessionsParams(openSessionsParams: SessionParams) {
        this.openSessionsParams = openSessionsParams;
    }

    setArchiveSessionsParams(archiveSessionsParams: SessionParams) {
        this.archiveSessionsParams = archiveSessionsParams;
    }

    setOpenSessionsLoading(openSessionsLoading: boolean) {
        this.openSessionsLoading = openSessionsLoading;
    }

    setArchiveSessionsLoading(archiveSessionsLoading: boolean) {
        this.archiveSessionsLoading = archiveSessionsLoading;
    }

    setLoadForCurrentUserOnly(loadForCurrentUserOnly: boolean) {
        this.loadForCurrentUserOnly = loadForCurrentUserOnly;
    }

    async loadMoreOpenSessions() {
        try {
            this.setOpenSessionsLoading(true);

            this.setOpenSessionsParams({
                ...this.openSessionsParams,
                filter: {
                    ...this.openSessionsParams.filter,
                    page: this.openSessionsParams.filter.page + 1
                }
            });

            const result = await this.loadApplicationSessions(this.openSessionsParams.filter);

            runInAction(() => {
                const filteredSessions = result.sessions.filter(s => !this.openSessions.some(os => os.id === s.id));
                this.openSessions = [...this.openSessions, ...filteredSessions];
            });

            this.setOpenSessionsParams({ ...this.openSessionsParams, total: result.total });
        } finally {
            this.setOpenSessionsLoading(false);
        }
    }

    async loadOpenSessions() {
        const result = await this.loadApplicationSessions(this.openSessionsParams.filter);

        runInAction(() => {
            this.openSessions = result.sessions;
        });

        this.setOpenSessionsParams({ ...this.openSessionsParams, total: result.total });
    }

    async loadRecentFinishedSessions() {
        const result = await this.loadApplicationSessions(this.recentFinishedSessionsParams.filter);

        runInAction(() => {
            this.recentFinishedSessions = result.sessions;
        });
    }

    async loadArchiveSessions() {
        try {
            this.setArchiveSessionsLoading(true);

            const result = await this.loadApplicationSessions(this.archiveSessionsParams.filter);

            runInAction(() => {
                this.archiveSessions = result.sessions;
            });

            this.setArchiveSessionsParams({ ...this.archiveSessionsParams, total: result.total });
        } finally {
            this.setArchiveSessionsLoading(false);
        }
    }

    async loadApplicationSessions(filter: SessionFilter) {
        await this.projectsStore.loadProjectsPromise;

        if (!this.projectIds) {
            return { sessions: [], total: 0 };
        }

        return await this.workflowInstanceStore.loadApplicationSessions({
            ...filter,
            projectIds: this.projectIds,
            userId: this.loadForCurrentUserOnly ? this.userId : filter.userId
        });
    }

    async updateSessionState(updateModel: ApplicationSessionChanges) {
        const sessionToUpdate = this.openSessions.find(a => a.id === updateModel.id);
        const isOpenSession = SessionsVisualStore.openSessionsStates.includes(updateModel.state);

        if (sessionToUpdate && !isOpenSession) {
            this.removeOpenSession(updateModel.id);
            return;
        }
    
        if (sessionToUpdate && isOpenSession) {
            this.updateOpenSession(sessionToUpdate, updateModel);
            return;
        }
    
        if (!sessionToUpdate && isOpenSession) {
            this.addOpenSession(updateModel.id);
            return;
        }
    }

    async addOpenSession(sessionId: string) {
        const session = await this.getSessionById(sessionId);

        if (!session || this.openSessions.some(s => s.id === session.id)) {
            return;
        }

        runInAction(() => {
            this.openSessions = [session, ...this.openSessions];
            this.setOpenSessionsParams({ ...this.openSessionsParams, total: this.openSessionsParams.total + 1 });
        });
    }

    updateOpenSession(sessionToUpdate: ApplicationSession, updateModel: ApplicationSessionChanges) {
        runInAction(() => {
            const index = this.openSessions.indexOf(sessionToUpdate);
            let newList = this.openSessions.slice();
            newList[index].state = updateModel.state;
            newList[index].error = updateModel.error || '';
            newList[index].runtimeSessionId = updateModel.runtimeSessionId;
            this.openSessions = newList;
        });
    }

    removeOpenSession(sessionId: string) {
        runInAction(() => {
            this.openSessions = this.openSessions.filter(s => s.id !== sessionId);
        });

        this.loadRecentFinishedSessions();
    }

    async getSessionById(sessionId: string) {
        return await this.sessionService.getSessionById(sessionId);
    }

    startSessionFromExisting(session: ApplicationSession) {
        if (session.packageId) {
            this.workflowInstanceStore.startSession(session.packageId, session.applicationDefinitionId, session.packageName, session.applicationExtension);
        } else if (session.packageSetId) {
            this.workflowInstanceStore.startSessionForPackageSet(
                session.packageSetId, 
                session.applicationDefinitionId, 
                session.applicationExtension, 
                session.packageName, 
                undefined, 
                session.isBulkSession
            );
        }
    }

    moveSessionToArchive(sessionId: string) {
        runInAction(() => {
            this.openSessions = this.openSessions.filter(s => s.id !== sessionId);
        });
    }

    async switchToSession(sessionObj: unknown) {
        const session = sessionObj as ApplicationSession;
        await this.tabsStore.addTab({
            id: session.id,
            title: session.packageName,
            isSelected: true,
            type: session.isBulkSession ? 'multidocument' : 'document',
            metadata: {
                packageId: session.packageId,
                sessionId: session.id,
                packageName: session.packageName,
                dynamicUI: undefined,
                sessionError: session.error,
                sessionState: session.state,
                runtimeSessionId: session.runtimeSessionId,
                packageSetId: session.packageSetId,
                projectName: session.projectName,
                projectId: session.projectId,
                isReloadable: session.isReloadable,
                customUI: session.capabilities?.customUI,
                isBulk: session.isBulkSession,
                applicationData: {
                    appName: session.applicationName,
                    appId: session.applicationDefinitionId,
                    appExtension: session.applicationExtension,
                    appSettings: session.applicationSettings,
                    iotaApplication: session.iotaApplication
                }
            }
        });
    }
}
import * as React from 'react';
import { useHomeStore } from '../stores/context';
import { observer } from 'mobx-react-lite';
import { Button, Card } from 'antd';
import teachAlphaimage from '../../common/resources/images/alpha-dashboard-education.jpeg';
import analyseImage from '../../common/resources/images/alpha-dashboard-doc-analysis.jpeg';
import settingsImage from '../../common/resources/images/alpha-dashboard-settings.jpeg';
import authContext from '../../authorization/AuthContext';
import { hasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { useNavigate } from 'react-router';

type CardContentProps = {
    title: string;
    content: string;
    handleClick?: () => void
};

const HomeCardContent: React.FC<CardContentProps> = ({title, content, handleClick}) => {
    return (
        <div className="home-card-content">
            <h3 className="card-content-title">{title}</h3>
            <div className="card-content-body">{content}</div>
            <div>
                <Button type="primary" disabled={handleClick == null} onClick={handleClick}>Get started</Button>
            </div>
        </div>
    );
};

const HomePage: React.FC = () => {
    const { homeVisualStore } = useHomeStore();
    const authCtx = React.useContext(authContext);
    const navigate = useNavigate();
    const [canAccessAnalysis, setCanAccessAnalysis] = React.useState(true);
    const [canAccessPortal, setCanAccessPortal] = React.useState(true);

    React.useEffect(() => {
        setCanAccessAnalysis(hasPermission(authCtx.permissions, [AppPermissions.CanSeeAnalysisPageAll, AppPermissions.CanSeeAnalysisPagePersonal]));
        setCanAccessPortal(hasPermission(authCtx.permissions, AppPermissions.CanAccessPortal));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getHandler = (hasAccess: boolean, handler: () => void) => {
        if (!hasAccess) {
            return undefined;
        }

        return handler;
    };

    const navigateToPath = (path: string) => {
        navigate(path);
        homeVisualStore.resetTabSelection();
    };

    // eslint-disable-next-line max-len
    const analysisContent = 'Let Alpha read your documents and help you book them into your line of business systems.\n You can analyse existing documents or upload new ones here...';
    const educationContent = 'Help teach Alpha to better understand your documents by tagging and labelling your sample documents...';
    const settingsContent = 'Visit the Alpha Portal to configure options for things such as User management, Task statuses and workflow settings...';

    return (
        <div className="home-buttons-container">
            <h1 className="welcome-text">{homeVisualStore.welcomeMsg}</h1>
            <div className="home-navigation-buttons">   
                <Card bordered={false} hoverable cover={<img src={analyseImage} />}>
                    <HomeCardContent title="Document analysis" content={analysisContent} handleClick={getHandler(canAccessAnalysis, () => navigateToPath('/analysis'))} />
                </Card>
                <Card bordered={false} hoverable cover={<img src={teachAlphaimage}/>}>
                    <HomeCardContent title="Teach Alpha" content={educationContent} handleClick={getHandler(canAccessPortal, homeVisualStore.handleTeachAlphaClick)} />
                </Card>
                <Card hoverable bordered={false} cover={<img src={settingsImage} />}>
                    <HomeCardContent title="Settings" content={settingsContent} />
                </Card>
            </div>
        </div>

    );
};

export default observer(HomePage);
import * as React from 'react';
import { AzureAD } from 'react-aad-msal';
import SearchPage from '../screens/Search';
import { Route } from 'react-router';
import { context } from '../stores';
import { context as sessionContext } from '../../sessions/stores';
import { context as documentsContext } from '../../documents/stores';
import authProvider from '../services/authProvider';

const searchRoute = () => {
    return (
        <Route
            path={'/search'}
            element={
                <AzureAD provider={authProvider} forceLogin>
                    <documentsContext.DocumentsStoreProvider>
                        <sessionContext.SessionsStoreProvider>
                            <context.SearchStoreProvider>
                                <SearchPage />
                            </context.SearchStoreProvider>
                        </sessionContext.SessionsStoreProvider>
                    </documentsContext.DocumentsStoreProvider>
                </AzureAD>
            }
        />
    );
};

export default searchRoute;
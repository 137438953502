import { WarningOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { DynamicUiModel } from '../../common/stores/DynamicUiModel';

type Props = {
    appName: string;
    packageName: string;
    ui: DynamicUiModel;
    onReload?: () => void;
    setSettingsDialogVisible: (value: boolean) => void;
    handleClick: (id: string) => void;
    dataId?: string;
    sessionState?: string
};

const DocumentHeaderRenderer: React.FC<Props> = ({
    appName,
    packageName,
    ui,
    onReload,
    setSettingsDialogVisible,
    sessionState,
    handleClick,
    dataId
}) => {
    return (
        <div className="alpha-form-title-container">
            <div className="app-form-info">
                <span className="app-name">{appName}</span>
                <h3>{packageName}</h3>
                {ui.errors && ui.errors.length ? (
                    <h3 style={{ marginTop: 10, color: 'orange' }}>
                        <WarningOutlined /> Please correct your details.
                    </h3>
                ) : (
                    <h3 style={{ marginTop: 10 }}>Please review the form and fill the empty fields.</h3>
                )}
            </div>

            {ui.actions && ui.actions.length ? (
                <div className="app-form-controls">
                    {onReload ? (
                        <HasPermission permissionClaim={AppPermissions.CanManageSessionsAndDocuments}>
                            <Tooltip title="Reload">
                                <Button
                                    type="link"
                                    className="reload-button"
                                    onClick={onReload}
                                    data-id={`alpha-application-form-reload-left-${dataId ? dataId : 'undefined'}`}
                                >
                                    <i className="alpha-icon reload xs" />
                                </Button>
                            </Tooltip>
                        </HasPermission>
                    ) : null}

                    <Tooltip title="Settings">
                        <Button
                            type="link"
                            className="reload-button"
                            onClick={() => setSettingsDialogVisible(true)}
                            data-id={`alpha-application-form-blocks-left-${dataId ? dataId : 'undefined'}`}
                            style={{ marginTop: -8 }}
                        >
                            <i className="alpha-icon gear-gray" style={{ width: 24, height: 24 }} />
                        </Button>
                    </Tooltip>

                    {sessionState !== 'Archived' && (
                        <HasPermission permissionClaim={AppPermissions.CanManageSessionsAndDocuments}>
                            <Button
                                key={0}
                                className="submit-button"
                                name={'submitButton'}
                                htmlType="submit"
                                type="primary"
                                data-id={`alpha-application-form-submit-left-${dataId ? dataId : 'undefined'}`}
                                onClick={() => handleClick(ui.actions[0].id)}
                                disabled={ui.actions[0].isDisabled}
                            >
                                {ui.actions[0].name}
                            </Button>
                        </HasPermission>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default observer(DocumentHeaderRenderer);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { GenericRadioButton } from '../../components';
import { InputModelWithSource } from '../../models';
import { InputWithSource } from '../../componentTypes';

const config = {
    label: 'Instalments Type'
};

const InstalmentTypeInput: InputWithSource = ({ input }) => <GenericRadioButton label={config.label} input={input} />;

InstalmentTypeInput.meta = {
    inputType: InputModelWithSource.name
};

export default observer(InstalmentTypeInput);

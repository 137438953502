import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';
import { GenericPercentageInput } from '../../components';

const config = {
    label: 'Comission'
};

const CommissionInput: InputWithoutSource = ({ input }) => (
    <GenericPercentageInput label={config.label} input={input} />
);

CommissionInput.meta = {
    inputType: InputModel.name
};

export default observer(CommissionInput);

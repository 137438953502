import * as React from 'react';
import { InputBaseModel } from '../models';
import { useAppStore } from '../stores';

export default (input: InputBaseModel, containerRef: React.RefObject<HTMLDivElement> | null) => {
    const { inputStore } = useAppStore();

    const [isHighlighted, setIsHighlighted] = React.useState(false);

    React.useEffect(() => {
        setIsHighlighted(input.guid === inputStore.highlightedInputGuid);
    }, [input.guid, inputStore.highlightedInputGuid]);

    React.useEffect(() => {
        if (!isHighlighted || !containerRef || !containerRef.current) {
            return;
        }

        containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [isHighlighted, containerRef]);

    return {
        containerStyle: isHighlighted ? { boxShadow: '0px 0px 1px 2px #83acde' } : {}
    };
};

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import { useAskAlphaStores } from '../../stores/context';
import { AskAlphaSettingsValues } from '../../types';
import {
    DocumentPreview,
    FileUploadButton,
    FileUploadOverlay,
    PackagesDropdown,
    AskAlphaHeader,
    ChatRenderer
} from '../../components';
import './AskAlphaScreen.less';

const AskAlphaScreen: React.FC = () => {
    const { askAlphaStore, askAlphaApplicationDefinitionsStore } = useAskAlphaStores();

    const [settingsForm] = Form.useForm<AskAlphaSettingsValues>();

    const [fileUploadOverlayVisible, setFileUploadOverlayVisible] = React.useState(false);

    const handleDragOverCapture = () => {
        setFileUploadOverlayVisible(true);
    };

    const handleUploadLeave = (e: React.DragEvent<HTMLDivElement>) => {
        if (!e.relatedTarget) {
            setFileUploadOverlayVisible(false);
        }
    };

    const handleDropCapture = () => {
        setFileUploadOverlayVisible(false);
    };

    React.useEffect(() => {
        askAlphaStore.changeUiBehaviour({ scrollIntoViewImmediately: true });

        askAlphaStore.getInstructWorkflows();

        if (askAlphaStore.settings.values.projectId) {
            askAlphaApplicationDefinitionsStore.loadApplicationsForProject(askAlphaStore.settings.values.projectId);
        }
    }, [askAlphaStore, askAlphaApplicationDefinitionsStore]);

    return (
        <div
            id={`tab-content-${askAlphaStore.sessionId}`}
            className="ask-alpha-screen"
            onDragOverCapture={handleDragOverCapture}
            onDropCapture={handleDropCapture}
            onDragLeave={handleUploadLeave}
        >
            <div className="ask-alpha-left-column">
                <AskAlphaHeader settingsForm={settingsForm} />

                <div className="side-content-wrapper">
                    <FileUploadOverlay isVisible={fileUploadOverlayVisible} />

                    <div className="package-controls">
                        <PackagesDropdown />
                        <FileUploadButton />
                    </div>

                    <ChatRenderer settingsForm={settingsForm} />
                </div>
            </div>

            <div className="ask-alpha-right-column">
                <DocumentPreview />
            </div>
        </div>
    );
};

export default observer(AskAlphaScreen);

import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PackageSetItemModel } from '../services/types';
import { ProjectsStore, TabsStore, ApplicationDefinitionsStore, DocumentTabStore, ErrorStore } from '../stores';
import { DynamicUiModel } from '../stores/DynamicUiModel';
import { TabModel } from '../types/TabModel';
import PackageSetItemRenderer from './PackageSetItemRenderer';

const { TabPane } = Tabs;

type PackageSetPreviewProps = {
    tab: TabModel;
    projectsStore: ProjectsStore;
    hasLeftContainer: boolean;
    collapsed: boolean;
    paginationPosition: string;
    tabsStore: TabsStore;
    applicationDefinitionsStore: ApplicationDefinitionsStore;
    documentTabStore: DocumentTabStore;
    errorStore: ErrorStore
};


const PackageSetPreviewRenderer: React.FC<PackageSetPreviewProps> = ({
    projectsStore, 
    tab, 
    hasLeftContainer, 
    collapsed,
    paginationPosition, 
    tabsStore,
    applicationDefinitionsStore,
    documentTabStore,
    errorStore}) => {


    const [packages, setPackages] = React.useState<PackageSetItemModel[]>([]);

    React.useEffect(() => {
        async function init() {
            let packagesInSet = await projectsStore.getPackagesFromSet(tab.metadata.packageSetId);
            if (packagesInSet) {
                packagesInSet = packagesInSet.sort((p1, p2) => p1.fileName > p2.fileName ? -1 : 1);
                setPackages(packagesInSet);
                documentTabStore.setSelectedPackageId(packagesInSet[0].id);
            }
        }

        init();
    }, [documentTabStore, projectsStore, tab.metadata.packageSetId]);

    if (!tab.metadata || !tab.metadata.packageSetId) {
        return null;
    }

    const height = hasLeftContainer ? 'calc(100vh - 180px)' : 'calc(50vh - 120px)';
    const ui = tab.metadata.dynamicUI as DynamicUiModel;
    const getSizeRatio = () => {
        return ui && ui.layoutConfiguration && ui.layoutConfiguration.sizeRatio != null ? ui.layoutConfiguration.sizeRatio : 1;
    };

    const handleTabSwitch = (pkgId: string) => {
        documentTabStore.setSelectedPackageId(pkgId);
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        },         0);
    };

    
    const hasBottomContainer = () => {
        return tab.metadata && tab.metadata.dynamicUI?.uiModel?.sections?.filter((s: { location: string }) => s.location === 'Bottom').length > 0;
    };

    const width = hasLeftContainer ? `calc(100% - ${520 * getSizeRatio() + 12}px)` : 'auto';
    return (packages && packages.length 
        ? <Tabs 
            style={{height}}
            activeKey={documentTabStore.selectedPackageId} 
            className={hasBottomContainer() ? 'iota-tab-with-bottom-container' : ''}
            tabBarStyle={{width}}
            onChange={handleTabSwitch}>
            {packages.map(p => (
                <TabPane key={p.id} tab={p.fileName} forceRender>
                    <PackageSetItemRenderer 
                        hasLeftContainer={hasLeftContainer}
                        packageId={p.id}
                        collapsed={collapsed}
                        paginationPosition={paginationPosition}
                        projectsStore={projectsStore}
                        tab={tab}
                        tabsStore={tabsStore}
                        applicationDefinitionsStore={applicationDefinitionsStore}
                        errorStore={errorStore}
                        packageName={p.fileName}
                    />
                </TabPane>
            ))}
        </Tabs>
        : <div>No packages in set</div>);
};

export default observer(PackageSetPreviewRenderer);
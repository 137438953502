import * as React from 'react';
import { useState, useEffect } from 'react';
import { DynamicControlProps } from './types';
import { WidgetDataItem } from '../../../common/services/types';
import { Tooltip, Form } from 'antd';
import { reaction } from 'mobx';
import { getConfLevelColor, ConfidenceLevel } from './ConfidenceLevel';
import { Utils } from '../../../common/misc/Utils';
import { CommentOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Subscription } from 'rxjs';
import { SelectWithTextSelection } from '../../../common/components/SelectWithTextSelection';

export const DynamicMultiSelect: React.FC<DynamicControlProps> = ({ inputParams, ui, form, onHighlightBlock, highlightInputSubject, getGearIcon, handleCommentFieldClick }) => {
    const [allItems, setAllItems] = useState<WidgetDataItem[]>([]);
    const [currentValue, setCurrentValue] = useState<{ value: string; text: string }[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
  
    let sub: React.MutableRefObject<Subscription | undefined> = React.useRef();
    
    React.useEffect(() => {
        if (highlightInputSubject) {
            sub.current = highlightInputSubject.subscribe(id => {
                if (id === inputParams.id) {
                    setIsHighlighted(true);
                } else {
                    setIsHighlighted(false);
                }
            });
        }
  
        return (() => {
            if (sub.current) {
                sub.current.unsubscribe();
            }
        });
    },              [highlightInputSubject, inputParams.id]);

    useEffect(() => {
        setLoading(true);
        ui.getAllWidgetValues(inputParams.source.id).then(r => {      
            setAllItems(r.data);
            setLoading(false);
        });
    
        ui.getCurrentWidgetValue(inputParams.id, inputParams.source.id).then(r => {     
            const obj = {};
            obj[inputParams.id] = r === undefined ? r : ((r instanceof Array) ? (r.length ? r.map(v => v && v.value) : undefined) : [r].map(v => v && v.value));
            form.setFieldsValue(obj); 
            setCurrentValue(() => {
                const val = r === undefined ? r : ((r instanceof Array) ? (r.length ? r : undefined) : [r]);
                return val;
            });
        });

        const disposer = inputParams.parentInputId && reaction(() => ui.parentBasedInputValues[inputParams.parentInputId!], (items: WidgetDataItem[]) => {
            setAllItems(items);
            const obj = {};
            obj[inputParams.id] = undefined;
            form.setFieldsValue(obj);
        }) || null;
        return () => {
            if (disposer) {
                disposer();
            }
        };
    },        [form, inputParams.id, inputParams.parentInputId, inputParams.source.id, ui]);

    const highlightField = () => {
        const fieldData = Utils.extractFieldData(inputParams);
        if (fieldData) {
            onHighlightBlock(fieldData, inputParams.id);
        }
    };

    const getTooltip = () => {
        if (!inputParams.behavior || !inputParams.behavior.inputTooltip) {
            return null;
        }
        return(
            <Tooltip overlayClassName="alpha-input-tooltip" title={inputParams.behavior.inputTooltip} placement="top">
                <QuestionCircleOutlined style={{marginLeft: 6, verticalAlign: 'middle'}}/>
            </Tooltip>
        );
    };

    const label = (
        <>
            <span className="alpha-doc-control-label with-controls">
                <Tooltip title={inputParams.name}>
                    <span className="alpha-doc-control-name">
                        {inputParams.name}
                    </span>
                </Tooltip>
                <span className="controls-container">
                    {inputParams.behavior && inputParams.behavior.readonly 
                        ? <></> 
                        : <ConfidenceLevel input={inputParams} /> } 
    
                    { inputParams.behavior?.comments && (
                        <CommentOutlined className='comments-icon' onClick={() => handleCommentFieldClick!(inputParams.id)}/>
                    )}                
                    {inputParams.meta && inputParams.meta.field && inputParams.value != null && (
                        <Tooltip title="View in document">
                            <i className="alpha-icon xs form-anchor" onClick={highlightField}/>
                        </Tooltip>
                    )  || getGearIcon(inputParams)}
                    {getTooltip()}
                </span>
            </span>
        </>
    );

    const getInputCssClass = () => {
        let styleClass: string = '';
        if (currentValue) {
            styleClass += getConfLevelColor(inputParams);
        } 

        if (inputParams.behavior && inputParams.behavior.style) {
            styleClass += ' colored-select';
        }

        styleClass += isHighlighted ? ' iota-contract-shadow' : '';
        return styleClass;
    };

    return (
        <Form.Item
            data-id={inputParams.id}
            data-type={inputParams.controlType}
            label={label}
            colon={false}
            labelCol={{span: 24}}
            name={inputParams.id}
            rules={[{
                required: inputParams.validation && inputParams.validation.required  && inputParams.validation.required.value,
                message: inputParams.validation && inputParams.validation.required && inputParams.validation.required.message || `${inputParams.name} is required`
            }]}
            className={inputParams.behavior && inputParams.behavior.width === 1 ? 'wide' : ''}
            initialValue={currentValue ? currentValue.map(v => v && v.value) : undefined}
        >
            <SelectWithTextSelection
                className={getInputCssClass()}
                allowClear={inputParams.validation?.required?.value === true ? false : true}
                disabled={(inputParams.behavior && inputParams.behavior.readonly) || loading}
                loading={loading}
                showSearch   
                filterOption
                optionFilterProp="label"
                style={Utils.buildDynamicInputStyles(inputParams)}
                mode={inputParams.controlType.toString() === 'multipleTagsSelect' ? 'tags' : 'multiple'}
                placeholder={''}
                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                options={allItems.map(i => {
                    return {label: i.text, value: i.value}; 
                })}
                suffixIcon={<i className="alpha-icon xxs arrow-down-icon" style={{margin: 0, width: 8}}/>}
            />
        </Form.Item>
    );
};

export default DynamicMultiSelect;
import { ErrorStore } from '../../common/stores';
import { observable, action, runInAction, computed, makeObservable } from 'mobx';
import { SearchService } from '../services';
import { SearchResults, SearchResultSourceTag, SearchResultBasicModelData } from '../types';
import * as _ from 'lodash';
import { ResultApi } from '../../common/services/AppClient';

export default class SearchVisualStore {
    searchKey: string = '';

    searchResults: SearchResults;

    selectedTags: SearchResultSourceTag[] = [];

    deepSearchTag: SearchResultSourceTag | undefined;

    highlightPreview: string | undefined = undefined;

    highlightedBlockId: string | undefined = undefined;

    isLoading: boolean = false;

    get resultsCount() {
        return this.searchResults && this.searchResults.results ? this.searchResults.results.length : 0;
    }

    constructor(private searchService: SearchService, private errorStore: ErrorStore) {
        makeObservable(this, {
            searchKey: observable,
            searchResults: observable,
            selectedTags: observable,
            deepSearchTag: observable,
            highlightPreview: observable,
            highlightedBlockId: observable,
            isLoading: observable,
            resultsCount: computed,
            setSearchKey: action.bound,
            setHighlightPreview: action.bound,
            selectTag: action.bound,
            search: action.bound,
            deepSearchByTagValue: action,
            downloadPackage: action,
            splitTags: action,
            checkIfTagsAreEqual: action
        });
    }

    setSearchKey(searchKey: string) {
        this.searchKey = searchKey;
    }

    setHighlightPreview(highlightText: string | undefined, blockId: string | undefined) {
        this.highlightPreview = highlightText;
        this.highlightedBlockId = blockId;
    }

    selectTag(tag: SearchResultSourceTag) {
        const foundTag = this.selectedTags.find(t => this.checkIfTagsAreEqual(t, tag));
        if (foundTag) {
            const index = this.selectedTags.indexOf(foundTag);
            this.selectedTags.splice(index, 1);
        } else {
            this.selectedTags.push(tag);
        }

        if (this.searchKey && this.searchKey.trim() !== '') {
            this.search();
        }
    }

    async search() {
        if (!this.searchKey || this.searchKey.trim() === '') {
            return;
        }

        runInAction(() => { 
            this.isLoading = true;
            this.deepSearchTag = undefined;
        });
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let resp: ResultApi<any>;
            if (!this.selectedTags || !this.selectedTags.length) {
                resp = await this.searchService.searchDocumentsByQuery(this.searchKey);
            } else {
                resp = await this.searchService.queryDocumentsByTags(this.searchKey, this.selectedTags);
            }

            resp.mapErr(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            }).map(data => {
                runInAction(() => {             
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    let mappedData: any[] = [];
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    data.results.forEach((r: any) => {
                        if (r.entries && r.entries.length) {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            r.entries.forEach((e: any) => {
                                var model: SearchResultBasicModelData = {
                                    blockType: e.blockType,
                                    contentType: r.contentType,
                                    fileName: r.fileName,
                                    highlights: e.highlights,
                                    id: e.id,
                                    packageId: r.packageId,
                                    tags: e.tags,
                                    text: e.text
                                };
                                mappedData.push({
                                    data: model,
                                    type: 'BASIC',
                                    url: undefined,
                                });
                            });
                        }
                    });
    
                    this.searchResults = { results: mappedData };
                });  
            });            
        } catch (err) {
            this.errorStore.addBasicError(err);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
            this.setHighlightPreview(undefined, undefined);
        }
    }

    async deepSearchByTagValue(tag: SearchResultSourceTag | undefined) {
        if (!tag || !tag.values || !tag.values.length) {
            return;
        }

        try {
            runInAction(() => {
                this.isLoading = true;
                this.deepSearchTag = tag;
            });
            const value = tag.values.join(', ');
            let resp = await this.searchService.deepSearch(value);
            resp.map(data => {
                runInAction(() => {
                    this.searchResults = { results: data};
                });
            }).mapErr(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });            
        } catch (err) {
            runInAction(() => {
                this.deepSearchTag = undefined;
            });
            this.errorStore.addBasicError(err);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
            this.setHighlightPreview(undefined, undefined);
        }
    }

    async downloadPackage(packageId: string) {
        this.searchService.handleDownload(packageId);
    }

    splitTags(tags: SearchResultSourceTag[]) {
        let splittedTags: SearchResultSourceTag[] = [];
        tags.forEach(tag => {
            splittedTags = splittedTags.concat(tag.values.map((v) => {
                return {id: tag.id, values: [v]}; 
            }));
        });
        return splittedTags.filter(this.onlyUniqueTags);
    }

    checkIfTagsAreEqual(tag1: SearchResultSourceTag, tag2: SearchResultSourceTag) {
        return tag1.id === tag2.id && _.isEqual(tag1.values, tag2.values);
    }

    private onlyUniqueTags(value: SearchResultSourceTag, index: number, self: SearchResultSourceTag[]) {
        const similarElements = self.filter(t => t.id === value.id && _.isEqual(t.values, value.values));
        const foundIndex = self.indexOf(similarElements[0]);
        return foundIndex === index;
    }
}
import { LayoutSettingsBaseModel } from '..';
import { BasicLayoutSettingsDto } from '../../types';

export default class BasicLayoutSettingsModel extends LayoutSettingsBaseModel {
    constructor(data: BasicLayoutSettingsDto) {
        super(data);
    }

    get label() {
        return 'Basic';
    }

    get dto(): BasicLayoutSettingsDto {
        return {
            type: this.type
        };
    }
}

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputModelWithSource } from '../../models';
import { InputWithSource } from '../../componentTypes';
import { GenericSelect } from '../../components';

const config = {
    label: 'Premium Entry Percent Of'
};

const PremiumEntryPercentOfInput: InputWithSource = ({ input }) => <GenericSelect label={config.label} input={input} />;

PremiumEntryPercentOfInput.meta = {
    inputType: InputModelWithSource.name
};

export default observer(PremiumEntryPercentOfInput);

import { makeObservable, observable, action, computed } from 'mobx';
import { SectionsTabDto } from '../../types';
import { TabBaseModel, SectionModel, SourceModelBase } from '..';

export default class SectionsTabModel extends TabBaseModel {
    sections: SectionModel[] = [];

    constructor(data: SectionsTabDto, sources: SourceModelBase[]) {
        super(data);

        this.sections = data.sections.map(section => new SectionModel(this, section, sources));

        makeObservable(this, {
            sections: observable,
            inputs: computed,
            update: action
        });
    }

    getSectionByInputGuid(guid: string) {
        return this.sections.find(section => section.inputs.some(input => input.guid === guid));
    }

    update(data: SectionsTabDto, sources: SourceModelBase[]) {
        data.sections.forEach(section => {
            const existingSection = this.sections.find(s => s.id === section.id);

            if (!existingSection) {
                this.sections.push(new SectionModel(this, section, sources));
            } else {
                existingSection.update(section, sources);
            }
        });

        this.sections = this.sections.filter(section => data.sections.some(s => s.id === section.id));
    }

    get inputs() {
        return this.sections.flatMap(section => section.inputs);
    }

    get dto(): SectionsTabDto {
        return {
            id: this.id,
            type: this.type,
            name: this.name,
            sections: this.sections.map(section => section.dto)
        };
    }
}

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import type { InputNumberProps } from 'antd';
import { InputNumber } from 'antd';
import { DocumentVisualStore } from '../../stores';
import { TypeUtils } from '../../../custom_shared/misc';
import './PageNumberInput.less';

interface Props {
    documentVisualStore: DocumentVisualStore;
    packageId?: string;
    sessionId?: string;
    targetWindow: Window
}

const PageNumberInput: React.FC<Props> = ({ documentVisualStore, packageId, sessionId, targetWindow }: Props) => {
    const [pageNumber, setPageNumber] = React.useState<number>(documentVisualStore.currentPage + 1);

    const ref = React.useRef<HTMLInputElement>(null);

    const onChange: InputNumberProps['onChange'] = value => {
        if (TypeUtils.isNumber(value)) {
            setPageNumber(value);
        }
    };

    const onPressEnter = () => {
        ref.current?.blur();
    };

    const onBlur = () => {
        const page = targetWindow.document.getElementById(`${sessionId}-${packageId}-${pageNumber - 1}`);

        if (page) {
            page.scrollIntoView({ block: 'start' });
        }
    };

    React.useEffect(() => {
        setPageNumber(documentVisualStore.currentPage + 1);
    }, [documentVisualStore.currentPage]);

    return (
        <div className="page-number-input">
            <span>Page</span>
            <InputNumber
                ref={ref}
                min={1}
                max={documentVisualStore.totalPages}
                value={pageNumber}
                onChange={onChange}
                onPressEnter={onPressEnter}
                onBlur={onBlur}
            />
            <span>/</span>
            <span>{documentVisualStore.totalPages}</span>
        </div>
    );
};

export default observer(PageNumberInput);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore, WorkflowInstanceStore, TabsStore, DocumentTabStore, ApplicationDefinitionsStore, ErrorStore, 
    ProjectsStore, IotaSessionsStore, UserProfileStore } from '../stores';
import { DocumentVisualStore } from '../../documents/stores';
import ImageService from '../services/ImageService';
import { Form, Progress, Spin, Tooltip } from 'antd'; // , Divider
import { SectionLocation } from '../services/types';
import { TabModel } from '../types/TabModel';
import { DynamicUiModel } from '../stores/DynamicUiModel';
import { DocumentService, SessionService } from '../services';
import { DownOutlined, SelectOutlined } from '@ant-design/icons';
import DocumentPreviewSelector from '../../documents/components/DocumentPreviewSelector';
import PackageSetPreviewRenderer from './PackageSetPreviewRenderer';
import ScanResultsRenderer from './ScanResultsRenderer';
import CustomAppRenderer from '../../custom_shared/CustomAppRenderer';
import DocumentSettingsDialog from '../../documents/components/DocumentSettingsDialog';
import { getGearIcon } from '../../documents/components/DynamicControlsContainer';
import { WidgetValues } from '../services/WidgetValuesProvider';
import { Utils } from '../../common/misc/Utils';

type DocumentPageProps = {
    tab: TabModel;
    tabsStore: TabsStore;
    applicationDefinitionsStore: ApplicationDefinitionsStore;
    errorStore: ErrorStore;
    projectsStore: ProjectsStore;
    iotaSessionsStore: IotaSessionsStore;
    userProfileStore: UserProfileStore
};

const DocumentPageRenderer: React.FC<DocumentPageProps> = observer(({
    tab, 
    applicationDefinitionsStore, 
    errorStore, 
    tabsStore, 
    projectsStore,
    iotaSessionsStore,
    userProfileStore
}) => {
    
    const [collapsed, setCollapsed] = React.useState(false);
    const [detachedWindow, setDetachedWindow] = React.useState<boolean>(false);
    const [settingsDialogVisible, setSettingsDialogVisible] = React.useState(false);
    const [preSubmitDialogVisible, setPreSubmitDialogVisible] = React.useState(false);
    const [currentWidgetValues, setCurrentWidgetValues] = React.useState<WidgetValues | undefined>(undefined);
    const [currentAction, setCurrentAction] = React.useState<string | undefined>(undefined);
    const [action, setAction] = React.useState('');
    const [form] = Form.useForm();
    const parentStorageKey = `parent-${tab.id}`;
    const childStorageKey = `child-${tab.id}`;

    
    const handleIdChangeInLocalStorage = React.useCallback(() => {
        const inputId = localStorage.getItem(childStorageKey);
        const docStore = tabsStore.documentStores[tab.id];
        if (inputId && docStore) {
            docStore.highlightBlockByInputId(inputId);
        }
    }, [childStorageKey, tab.id, tabsStore.documentStores]);


    React.useEffect(() => {
        window.addEventListener('storage', handleIdChangeInLocalStorage);
        const highlightSub = tabsStore.documentStores[tab.id].highlightedInputIdSubject.subscribe(id => {
            localStorage.setItem(parentStorageKey, id);
        });
        return (() => {
            window.removeEventListener('storage', handleIdChangeInLocalStorage);
            highlightSub.unsubscribe();
        });
    }, [handleIdChangeInLocalStorage, parentStorageKey, tab.id, tabsStore.documentStores]);

    if (!tabsStore.documentStores[tab.id]) {
        const imageService = new ImageService();
        const documentService = new DocumentService();
        tabsStore.documentStores[tab.id] = new DocumentVisualStore(applicationDefinitionsStore, imageService, errorStore, documentService);
    }

    if (!tabsStore.workflowInstances[tab.id]) {
        const sessionService = new SessionService();
        tabsStore.workflowInstances[tab.id] =  new WorkflowInstanceStore(sessionService, iotaSessionsStore, userProfileStore);
    }

    if (!tabsStore.documentTabStores[tab.id]) {
        tabsStore.documentTabStores[tab.id] =  new DocumentTabStore(tab, tabsStore.workflowInstances[tab.id]);
    }

    const getSizeRatio = () => {
        return ui && ui.layoutConfiguration && ui.layoutConfiguration.sizeRatio != null ? ui.layoutConfiguration.sizeRatio : 1;
    };

    const renderDetach = () => {
        if (!ui || !ui.layoutConfiguration || hasLeftContainer) {
            return null;
        }

        return (
            <Tooltip title="Pop out preview">
                <SelectOutlined style={{marginLeft: 12}} onClick={detachWindow} />
            </Tooltip>
        );
    };

    const detachWindow = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        if (tabStore.tab.id) {
            setDetachedWindow(true);
            setCollapsed(true);
            const newWindow = window.open(`/preview/${tabStore.tab.id}`, '_blank', 'location=yes,height=700,width=1100,scrollbars=yes,status=yes');
            if (newWindow) {
                // Interval is needed due to redirections happening in a new window and onbeforeunload is triggered immediately.
                const timer = setInterval(function() {
                    if(newWindow.closed) {
                        setDetachedWindow(false);
                        setCollapsed(false);
                        clearInterval(timer);
                    }
                }, 500);
            }
        }
    };

    let height = '100%';
    let paginationPosition = tab.metadata.packageId ? '74px' : '124px';
    let hasLeftContainer = true;
    let sizeRatio = 1;
    const tabStore = tabsStore.documentTabStores[tab.id];
    const workflowInstance = tabsStore.workflowInstances[tab.id];
    const ui = tabStore.tab.metadata.dynamicUI as DynamicUiModel;
    if (!tab.metadata.uiIsLoading) {
        if (ui) {
            sizeRatio = getSizeRatio();
            const section = ui.sections.find(x => x.location === SectionLocation.Bottom);
            height = section ? '50%' : '100%';
            hasLeftContainer = !!sizeRatio && sizeRatio > 0;
            paginationPosition = !section ? paginationPosition : collapsed || !hasLeftContainer ? '124px' : 'calc(50% - 28px)';
            paginationPosition = !!section && !collapsed && !hasLeftContainer ? '74px' : paginationPosition;
            tabsStore.documentStores[tab.id].prepareAllBlocks(ui, tab.metadata.packageId);
        }
    }

    const getProgressPercent = (dynamicUi: DynamicUiModel) => {
        return +(dynamicUi.progress * 100).toFixed(1);
    };

    const getPackageIdFromMeta = () => {
        let packageId = null;
        if (ui) {
            ui.inputs.forEach(i => { 
                if (i.meta && i.meta.packageId) {
                    packageId = i.meta.packageId;
                }
            });
        }

        return packageId;
    };

    const onSubmit = async (values: WidgetValues, submitAction: string) => {
        tabStore.handleTabUiUpdate(undefined);
        await workflowInstance.handleFormSubmit(values, submitAction, tab.metadata);
    };

    const handleSubmitClick = async (values: WidgetValues, submitAction: string) => {
        const dynamicUI = tab.metadata.dynamicUI! as DynamicUiModel;
        const hasPreSubmitActions = dynamicUI?.preSubmitActions?.length;
        const uiAction = dynamicUI.actions[0].id;
        
        if (!hasPreSubmitActions || uiAction != submitAction) {
            await onSubmit(values, submitAction);
        } else if (hasPreSubmitActions) {
            setCurrentAction(submitAction);
            setCurrentWidgetValues(values);
            // setPreSubmitDialogVisible(true);
            handlePreSubmitAction();
        }
    };

    const handlePreSubmitAction = async () => {
        const dynamicUI = tab.metadata.dynamicUI! as DynamicUiModel;
        const preSubmitAction =  dynamicUI?.preSubmitActions?.[0];

        if (!preSubmitAction) { 
            return;
        }

        switch(preSubmitAction.type) {
        case 'Authorize':
            setPreSubmitDialogVisible(true);
            break;
        case 'OidcImplicitFlowAuthorize':
        case 'OidcCodeFlowAuthorize':
            document.getElementById(`${tab.id}-oauth2-login-button`)?.click();
            break;
        default:
            break;
        }
    };

    const handleOidcFlowSuccess = async (value: string) => {
        const oauthPreSubmitAction = ui.preSubmitActions?.find(a => ['OidcImplicitFlowAuthorize', 'OidcCodeFlowAuthorize'].includes(a.type));
        if (!oauthPreSubmitAction) {
            return;
        }

        if (oauthPreSubmitAction.type === 'OidcImplicitFlowAuthorize') {
            await handleOidcImplicitFlowSuccess(value);
        } else if (oauthPreSubmitAction.type === 'OidcCodeFlowAuthorize') {
            await handleOidcCodeFlowSuccess(value);
        }
    };

    const handleOidcImplicitFlowSuccess = async (token: string) => {
        const oauthPreSubmitAction = ui.preSubmitActions?.find(a => a.type === 'OidcImplicitFlowAuthorize');
        if (!oauthPreSubmitAction) {
            return;
        }

        const values = currentWidgetValues;

        if (values) {
            const tokenTypeKey = 'tokenType';
            const tokenKey = 'accessToken';
            const accessToken = token.split(' ')[1];
            const tokenType = token.split(' ')[0];
            
            // @ts-ignore
            values[tokenKey] = {value: accessToken, text: 'Access token'};
            // @ts-ignore
            values[tokenTypeKey] = {value: tokenType, text: 'Token type'};
        }

        setCurrentWidgetValues(values);
        onSubmit(values!, currentAction!);
    };

    const handleOidcCodeFlowSuccess = async (code: string) => {
        const oauthPreSubmitAction = ui.preSubmitActions?.find(a => a.type === 'OidcCodeFlowAuthorize');
        if (!oauthPreSubmitAction) {
            return;
        }

        const redirectUrl = window.location.protocol + '//' + window.location.host + '/openid-success';

        let body = `grant_type=authorization_code&code=${code}`;
        body += `&client_id=${oauthPreSubmitAction.metadata.ClientId}`;
        body += `&redirect_uri=${encodeURIComponent(redirectUrl)}`;
        if (oauthPreSubmitAction.metadata.ClientSecret) {
            body += `&client_secret=${oauthPreSubmitAction.metadata.ClientSecret}`;
        }

        if (oauthPreSubmitAction.metadata.ExtraParams) {
            for(let key in oauthPreSubmitAction.metadata.ExtraParams) {
                if (oauthPreSubmitAction.metadata.ExtraParams[key] != null) {
                    body += `&${key}=${oauthPreSubmitAction.metadata.ExtraParams[key]}`;
                }
            }

        }

        const response = await fetch(oauthPreSubmitAction.metadata.TokenEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body
        });

        const json = await response.json();
        const values = currentWidgetValues;

        if (values) {
            const tokenKey = 'accessToken';
            const tokenTypeKey = 'tokenType';
            // @ts-ignore
            values[tokenKey] = {value: json.access_token, text: 'Access token'};
            // @ts-ignore
            values[tokenTypeKey] = {value: json.token_type, text: 'Token type'};
        }

        setCurrentWidgetValues(values);
        onSubmit(values!, currentAction!);
    };

    const handleAction = async (control: string, actionId: string) => {
        setAction(actionId);
        var obj = Utils.prepareRuntimeDataObjectFromForm(form, getPackageIdFromMeta());
        await handleSubmitClick(obj, actionId);
    };

    const documentStore = tabsStore.documentStores[tab.id];
    const packageName= tab.metadata.packageName as string;

    return (
        <div className={`alpha-document-container ${hasLeftContainer ? '' : 'no-left'}`} style={{ height: 'calc(100vh - 85px)' }}>
            {ui && (
                <DocumentSettingsDialog
                    ui={ui}
                    tab={tab}
                    form={form}
                    packageName={packageName}
                    showingBlocks={documentStore.showBlocks}
                    isVisible={settingsDialogVisible}
                    setIsVisible={setSettingsDialogVisible}
                    setShowingBlocks={documentStore.setShowBlocks}
                    handleAction={handleAction}
                    getGearIcon={getGearIcon}
                    isLoading={tab.metadata.uiIsLoading && !tab.metadata.freezeUi}
                />
            )}

            {(tab.metadata.uiIsLoading || ui?.validateOnLoad) && !tab.metadata.freezeUi
                ? <div className={'loading-ui'} style={ui?.validateOnLoad ? { backgroundColor: '#f0f1f3' } : undefined}>
                    {!ui || ui.progress == null ? (
                        <Spin size="large" />
                    ) : (
                        <Progress type="circle" percent={getProgressPercent(ui)} />
                    )}
                </div>
                // eslint-disable-next-line max-len
                : (
                    <div className="leftColumnContainer" style={{width: 520 * getSizeRatio(), flex: `0 0 ${getSizeRatio() * 520}px`, overflowX: 'hidden', overflowY: 'hidden'}}>
                        <ScanResultsRenderer 
                            form={form}
                            documentTabStore={tabsStore.documentTabStores[tab.id]} 
                            tabsStore={tabsStore} 
                            workflowInstance={tabsStore.workflowInstances[tab.id]}
                            preSubmitDialogVisible={preSubmitDialogVisible}
                            currentWidgetValues={currentWidgetValues}
                            currentAction={currentAction}
                            setPreSubmitDialogVisible={setPreSubmitDialogVisible}
                            setSettingsDialogVisible={setSettingsDialogVisible}
                            handleSubmitClick={handleSubmitClick}
                            setCurrentWidgetValues={setCurrentWidgetValues}
                            setCurrentAction={setCurrentAction}
                            action={action}
                            setAction={setAction}
                            handleAction={handleAction}
                            handleOidcFlowSuccess={handleOidcFlowSuccess}
                        />
                    </div>
                )}
           
            <div className="rightColumnContainer">
                {tab.metadata.packageId
                    ? 
                    <div className={`document-wrapper ${collapsed ? 'bottom-collapsed' : ''}`} style={{height: '100%'}}>
                        <DocumentPreviewSelector
                            packageName={tab.metadata.packageName}
                            paginationPosition={paginationPosition}
                            documentVisualStore={tabsStore.documentStores[tab.id]!}
                            projectsStore={projectsStore}
                            packageId={tab.metadata.packageId as string}
                            sessionId={tab.metadata.sessionId as string}
                            modelIsLoading={tab.metadata.uiIsLoading as boolean}
                            hasLeftContainer={hasLeftContainer}
                        />
                    </div>
                    : 
                    <PackageSetPreviewRenderer 
                        tab={tab} 
                        projectsStore={projectsStore} 
                        hasLeftContainer={hasLeftContainer} 
                        collapsed={collapsed}
                        paginationPosition={paginationPosition}
                        applicationDefinitionsStore={applicationDefinitionsStore}
                        errorStore={errorStore}
                        tabsStore={tabsStore}
                        documentTabStore={tabStore}
                    />
                }
                {height === '100%' ? <></>  :
                    <>
                        {/* <Divider/> */}
                        <div 
                            className={`bottomContainer ${collapsed ? 'collapsed' : ''} ${detachedWindow ? 'detached' : ''}`}
                        >
                            <div 
                                data-id={`collapser-${tab.id}`}
                                className={'bottom-container-collapser'}
                                onClick={() => setCollapsed(!collapsed)}
                            >
                                <DownOutlined className={`collapse-icon ${collapsed ? 'collapsed' : ''}`} />
                                <span className="collapser-title">{hasLeftContainer ? 'Parsed tables' : 'Original Document'}</span>
                                {renderDetach()}
                            </div>
                            <ScanResultsRenderer 
                                form={form}
                                documentTabStore={tabsStore.documentTabStores[tab.id]} 
                                tabsStore={tabsStore} 
                                workflowInstance={tabsStore.workflowInstances[tab.id]} 
                                bottomContainer
                                preSubmitDialogVisible={preSubmitDialogVisible}
                                currentWidgetValues={currentWidgetValues}
                                currentAction={currentAction}
                                setPreSubmitDialogVisible={setPreSubmitDialogVisible}
                                setSettingsDialogVisible={setSettingsDialogVisible}
                                handleSubmitClick={handleSubmitClick}
                                setCurrentWidgetValues={setCurrentWidgetValues}
                                setCurrentAction={setCurrentAction}
                                action={action}
                                setAction={setAction}
                                handleAction={handleAction}
                                handleOidcFlowSuccess={handleOidcCodeFlowSuccess}
                            />
                        </div>
                    </>}
            </div>
        </div>
    );
});

const getTabStyle = (isSelected: boolean): React.CSSProperties => {
    if (isSelected) {
        return {
            display: 'block'
        };
    } else {
        return {
            display: 'none'
        };
    }
};

type DocumentTabsContentProps = {
    tabsStore?: TabsStore
};

export const DocumentTabsContent: React.FC<DocumentTabsContentProps> = ({tabsStore}) => {
    const { projectsStore, applicationDefinitionsStore, errorStore, iotaSessionStore, userProfileStore } = useStore();

    if (!tabsStore) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        tabsStore = useStore().tabsStore;
    }

    return (
        <div>
            {tabsStore.documentTabs && tabsStore.documentTabs.map(t => {
                const id = `tab-content-${t.id}`;
                return (
                    <div
                        id={id}
                        key={id}
                        style={getTabStyle(!!tabsStore!.selectedTab && tabsStore!.selectedTab.id === t.id)}
                    >
                        {t.metadata.customUI ? (
                            <CustomAppRenderer tab={t} />
                        ) : (
                            <DocumentPageRenderer
                                tab={t}
                                projectsStore={projectsStore}
                                applicationDefinitionsStore={applicationDefinitionsStore}
                                errorStore={errorStore}
                                tabsStore={tabsStore!}
                                iotaSessionsStore={iotaSessionStore}
                                userProfileStore={userProfileStore}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default observer(DocumentTabsContent);
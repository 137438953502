import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu, Dropdown, Button, Select, Upload, message as antMessage } from 'antd';
import { ProjectsStore } from '../../common/stores';
import Modal from 'antd/lib/modal/Modal';
import TextArea from 'antd/lib/input/TextArea';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { HomeVisualStore } from '../../analysis/stores';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';

type Props = {
    projectsStore: ProjectsStore;
    homeStore: HomeVisualStore
};

export const ActionsMenu: React.FC<Props> = ({projectsStore, homeStore}) => {
    const [emailDialogVisible, setEmailDialogVisible] = React.useState<boolean>(false);
    const [emails, setEmails] = React.useState<string[]>([]);
    const [message, setMessage] = React.useState<string>('');

    React.useEffect(() => {
        if (!emailDialogVisible) {
            setEmails([]);
            setMessage('');
        }
    },              [emailDialogVisible]);

    const handleDownload = () => {
        projectsStore.handleDownload('pdf');
    };

    // const handleSendByEmail = () => {
    //     setEmailDialogVisible(true);
    // };

    const sendPackage = async () => {
        if (projectsStore.selectedPackageId) {
            var status = await projectsStore.handleSendViaEmail(projectsStore.selectedPackageId, emails, message);
            if (status === 200) {
                setEmailDialogVisible(false);
            }
        }
    };

    const beforeUpload = (file: RcFile, fileList: RcFile[]) => {
        if (fileList.indexOf(file) !== 0) {
            return false;
        }

        homeStore.setFilesToUpload(fileList);
        if (projectsStore.projects && projectsStore.projects.length > 1) {            
            homeStore.setShowProjectSelectionModal(true);
            return false;
        } else {
            homeStore.setSelectedProjectToUpload(projectsStore.currentProjectId);
            homeStore.confirmProjectSelection();
            return false;
        }
    };

    const onChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }

        if (status === 'done') {
        
            antMessage.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            antMessage.error(`${info.file.name} file upload failed.`);
        }
    };

    const actionsMenu = (
        <Menu data-id="new-contract-actions">
            <Menu.Item 
                data-id="new-contract-actions-download" 
                key="0" 
                className="label-with-icon" 
                onClick={handleDownload}
                disabled={!projectsStore.selectedPackageId}
            >
                <i className="alpha-icon xs download" />
                <span>Download document</span>
            </Menu.Item>
            {
                // Commented out in case we will decide that we need it back
            }
            {/* <Menu.Item 
                data-id="new-contract-actions-send" 
                key="1" 
                className="label-with-icon" 
                onClick={handleSendByEmail}
                disabled={!projectsStore.selectedPackageId}
            >
                <i className="alpha-icon xs mail" />
                <span>Send by email</span>
            </Menu.Item> */}
            <HasPermission permissionClaim={AppPermissions.CanManageSessionsAndDocuments} entityId={projectsStore.selectedPackageProject}>
                <Menu.Item 
                    data-id="new-contract-actions-bulk-upload" 
                    key="2" 
                    className="label-with-icon"
                >
                    <div className="menu-upload-wrapper">
                        <Upload 
                            multiple 
                            directory 
                            showUploadList={false} 
                            action={projectsStore.folderUploadAction}
                            beforeUpload={beforeUpload}
                            onChange={onChange}
                        >
                            <i className="alpha-icon xs plus" />
                            <span>Bulk upload</span>
                        </Upload>
                    </div>
                </Menu.Item>
            </HasPermission>
        </Menu>
    );
    // TODO: Make menu upload wrapper 100% wide

    return (
        <>
            <Modal
                title="Send document via email"
                visible={emailDialogVisible}
                onCancel={() => setEmailDialogVisible(false)}
                footer={[
                    <Button 
                        key="package-send-by-email" 
                        type="primary" 
                        onClick={sendPackage} 
                        disabled={!emails || !emails.length} 
                        loading={projectsStore.sendingPackage}
                    >
                        Send
                    </Button>
                ]}
            >
                <div style={{margin: '20px 0'}}>
                    <Select 
                        placeholder="To"
                        mode="tags" 
                        value={emails} 
                        onChange={(val: unknown ) => {
                            setEmails(val as string[]); 
                        }} 
                        style={{width: '100%'}} 
                        tokenSeparators={[';']} 
                    />
                </div>
                <p>
                    <TextArea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Message"
                        autoSize
                        style={{minHeight: 150}}
                    />
                </p>
            </Modal>
            <Dropdown 
                data-id="new-contract-actions-dropdown" 
                overlay={actionsMenu} 
                placement="topLeft" 
                trigger={['click']}>
                <Button data-id="new-contract-actions-dropdown-button" className="light">
                    More actions
                </Button>
            </Dropdown>
        </>
    );
};

export default observer(ActionsMenu);

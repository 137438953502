/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const WarningIcon: React.FC<IconProps> = ({ width = 24, height = 24, color = '#F5222D', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12.3672 20.3281C16.7266 20.3281 20.3359 16.7109 20.3359 12.3594C20.3359 8 16.7188 4.39062 12.3594 4.39062C8.00781 4.39062 4.39844 8 4.39844 12.3594C4.39844 16.7109 8.01562 20.3281 12.3672 20.3281ZM12.3672 19C8.67969 19 5.73438 16.0469 5.73438 12.3594C5.73438 8.67188 8.67188 5.71875 12.3594 5.71875C16.0469 5.71875 19 8.67188 19.0078 12.3594C19.0156 16.0469 16.0547 19 12.3672 19ZM12.3594 13.7734C12.7266 13.7734 12.9453 13.5625 12.9531 13.1562L13.0703 9.03125C13.0781 8.63281 12.7656 8.33594 12.3516 8.33594C11.9297 8.33594 11.6328 8.625 11.6406 9.02344L11.7422 13.1562C11.75 13.5547 11.9688 13.7734 12.3594 13.7734ZM12.3594 16.3125C12.8047 16.3125 13.1953 15.9531 13.1953 15.5C13.1953 15.0391 12.8125 14.6875 12.3594 14.6875C11.8984 14.6875 11.5156 15.0469 11.5156 15.5C11.5156 15.9453 11.9062 16.3125 12.3594 16.3125Z"
            fill={color}
        />
    </svg>
);

export default WarningIcon;

import { observable, action, makeObservable } from 'mobx';
import { CustomSessionRuntimeData } from '../types';

export default class RuntimeDataStore {
    values: Map<string, unknown> = new Map();

    constructor() {
        makeObservable(this, {
            values: observable,
            add: action,
            delete: action
        });
    }

    add(key: string, value: unknown) {
        this.values.set(key, value);
    }

    delete(key: string) {
        this.values.delete(key);
    }

    getValues() {
        const values: CustomSessionRuntimeData = {};

        for (const [key, value] of this.values) {
            values[key] = { value };
        }

        return values;
    }
}

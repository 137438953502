import * as React from 'react';
import { RequiredFieldIcon } from '../../icons';

interface CustomInputLabelProps {
    label: string;
    required?: boolean;
    onClick?: () => void
}

const CustomInputLabel: React.FC<CustomInputLabelProps> = ({ label, required, onClick }) => {
    // Prevent label from animating when the component is first rendered
    const [transitionEnabled, setTransitionEnabled] = React.useState(false);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setTransitionEnabled(true);
        }, 100);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <label
            className="custom-input-label"
            onClick={onClick}
            style={{
                transition: transitionEnabled ? '0.1s linear' : 'none'
            }}
        >
            {label} {!!required && <RequiredFieldIcon />}
        </label>
    );
};

export default CustomInputLabel;

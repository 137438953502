import { observer } from 'mobx-react';
import * as React from 'react';
import '../../../styles/common.less';

const OpenIdSuccessPage = () => {
    React.useEffect(() => {
        document.body.className = 'no-menu';
        window.addEventListener(
            'message',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (event: MessageEvent<any>) => {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const code = urlParams.get('code');

                const hashString = window.location.hash;
                const hashParams = new URLSearchParams(decodeURIComponent(hashString).replace('#', '?'));
                const accessToken = hashParams.get('access_token');
                const tokenType = hashParams.get('token_type');

                if (event.data.message === 'requestResult' && code != null) {
                    event.source?.postMessage({ message: 'deliverResult', result: code });
                } else if (event.data.message === 'requestResult' && accessToken != null && tokenType != null) {
                    event.source?.postMessage({ message: 'deliverResult', result: `${tokenType} ${accessToken}` });
                }
            },
            false,
        );
    }, []);

    return (
        <div style={{textAlign: 'center', fontSize: 32}}>
            Authorization successful
        </div>
    );
};

export default observer(OpenIdSuccessPage);
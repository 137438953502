import { action, makeObservable, observable } from 'mobx';
import { LocalStorageService } from '../../common/services';
import { AskAlphaSettingsValues } from '../types';

const localStorageKey = 'ask-aplha-settings';

const defaultSettings: AskAlphaSettingsValues = {
    projectId: null,
    applicationDefinitionId: null,
    instructWorkflowId: null,
    instructWorkflowOutputSchemeName: null,
    instructWorkflowCustomOutputSchemeType: null,
    instructWorkflowCustomOutputScheme: null,
    useContext: false
};

export default class AskAlphaSettingsModel {
    values: AskAlphaSettingsValues = { ...defaultSettings };

    private readonly localStorageService = new LocalStorageService();

    constructor() {
        makeObservable(this, {
            values: observable,
            setValues: action.bound,
            resetInstructWorkflow: action.bound
        });

        this.getSettingsFromLocalStorage();
    }

    setValues(values: Partial<AskAlphaSettingsValues>) {
        this.values = Object.entries(values).reduce(
            (acc, [key, value]) => {
                acc[key] = value;
                return acc;
            },
            {
                ...defaultSettings
            }
        );

        this.updateLocalStorageSettings();
    }

    updateProjectId(projectId: string | null) {
        this.setValues({ ...this.values, projectId });
    }

    resetApplicationDefinition() {
        this.setValues({ ...this.values, applicationDefinitionId: null });
    }

    resetInstructWorkflow() {
        this.setValues({ ...this.values, instructWorkflowId: null, instructWorkflowOutputSchemeName: null });
    }

    private getSettingsFromLocalStorage() {
        this.setValues(this.localStorageService.getItem<Partial<AskAlphaSettingsValues>>(localStorageKey, {}));
    }

    private updateLocalStorageSettings() {
        this.localStorageService.setItem(localStorageKey, this.values);
    }
}

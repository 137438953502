import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Tooltip, Upload, message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { useAskAlphaStores } from '../../stores/context';
import './FileUploadButton.less';

const FileUploadButton: React.FC = () => {
    const { askAlphaStore } = useAskAlphaStores();

    const onChange = (info: UploadChangeParam) => {
        const status = info.file.status;

        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }

        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    return (
        <Tooltip title="Upload a file">
            <Upload
                onChange={onChange}
                showUploadList={false}
                multiple={false}
                action={askAlphaStore.uploadAction}
                name="file"
                disabled={askAlphaStore.packagesImportInProcess}
                beforeUpload={() => {
                    askAlphaStore.clearChat();
                    askAlphaStore.setSelectedPackageId(undefined);
                    askAlphaStore.updateSearch('');
                    return true;
                }}
            >
                <Button
                    className="file-upload-button"
                    icon={<i className="alpha-icon xs plus" />}
                    disabled={askAlphaStore.packagesImportInProcess}
                />
            </Upload>
        </Tooltip>
    );
};

export default observer(FileUploadButton);

import SessionService, { Filter } from './SessionService';
import { WidgetDataItem, WidgetData, TabApplicationData } from './types';

type CacheItem = {
    [key: string]: Promise<WidgetData> | undefined
};

type DataCacheItem = {
    [key: string]: Promise<{ data: WidgetDataItem[]; total: number; lowData: WidgetData }>
};

export const PageSize = 500;

type CancelablePromise<T> = Promise<T> & { cancel: () => void };

const dataCache: DataCacheItem = {};
const cache: CacheItem = {};

export function makeCancelable<T>(promise: Promise<T>): CancelablePromise<T> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let rejectFn: (reasong?: any) => void;

    const wrappedPromise = new Promise<T>((resolve, reject) => {
        rejectFn = reject;

        Promise.resolve(promise)
            .then(resolve)
            .catch(reject);
    }) as CancelablePromise<T>;

    wrappedPromise.cancel = () => {
        rejectFn({ canceled: true });
    };

    return wrappedPromise;
}

export class WidgetValuesProvider {
    constructor(
        private service: SessionService, 
        private link: string, 
        private session: string, 
        private applicationData: TabApplicationData) {

    }

    async fetch(skip: number, take: number, filter: (Filter | undefined) = undefined, pageSize: number = PageSize): Promise<WidgetData> {
        const lowPage = Math.floor(skip / pageSize);
        const hightPage = Math.floor((skip + take) / pageSize);
        const that = this;

        async function loadItems() {
            const lowData = await that.getWindow(lowPage, PageSize, filter);
            let data: WidgetDataItem[] = [];

            if (take !== pageSize && skip === 0) {
                const hightData = await that.getWindow(hightPage, PageSize, filter);
                data = lowData.data.concat(hightData.data);
            } else {
                data = lowData.data;
            }

            return {
                data: data,
                total: lowData.total,
                lowData
            };
        }

        const key = `${this.session}-${this.link}-${lowPage}-${hightPage}-${JSON.stringify(filter || {})}`;
        if (!dataCache[key]) {
            dataCache[key] = loadItems();
        }        

        return dataCache[key].then(d => {
            const localSkip = skip - lowPage * PageSize;
            return {
                data: d.data.slice(localSkip, localSkip + take),
                total: d.lowData.total,
                skip: skip
            };
        });
    }

    async fetOne(value: string): Promise<WidgetDataItem | undefined> {
        const res = await this.fetchAll();
        return res.data.find(x => x.value === value);
    }

    async fetchAll(): Promise<WidgetData> {
        const data = await this.getWindow(0, 99999);
        return data;
    }

    async fetchAllByParent(parentValues: string[], ignoreCache: boolean = true): Promise<WidgetData> {
        const data = await this.getWindow(0, 99999, undefined, parentValues, ignoreCache);
        return data;
    }

    private async getWindow(page: number, pageSize: number, filter: (Filter | undefined) = undefined, parentValues?: string[], ignoreCache?: boolean): Promise<WidgetData> {
        const key = `${this.session}-${this.link}-${page}-${pageSize}-${JSON.stringify(filter || {})}`;
        
        if (!ignoreCache && cache[key])  {
            return cache[key]!;
        }      

        var that = this;
        async function loadItems() {
            var {iotaApplication, appExtension, appSettings} = that.applicationData;
            const res = await that.service.loadValuesForWidgets(that.session, that.link, iotaApplication, appExtension, appSettings, page, pageSize, filter, parentValues);
            return res.map(data => {
                const items = data.rows.map(r => ({ text: r.items[0].value as string, value: r.items[1].value as string }));

                return {
                    data: items,
                    total: data.totalCount,
                    skip: data.page * data.pageSize
                };
            }).unwrapOr({ data: [], total: 0, skip: 0 });            
        }

        const widgetData = loadItems();

        if (ignoreCache) {
            return widgetData;
        }

        cache[key] = widgetData;
        return cache[key]!;
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isStaticValues(val: WidgetDataItem[] | WidgetValuesProvider): val is WidgetDataItem[]  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (val as any).fetch === undefined;
}

export type WidgetValues = {
    [key: string]: WidgetDataItem[] | WidgetValuesProvider
};

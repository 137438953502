import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useParams } from 'react-router';
import { DocumentVisualStore } from '../../documents/stores';
import ImageService from '../../common/services/ImageService';
import { useStore } from '../../common/stores';
import { useHomeStore } from '../../analysis/stores/context';
import DocumentExcel from '../../documents/components/DocumentExcel';
import { DocumentService } from '../../common/services';

const PreviewPage: React.FC = () => {
    // @ts-ignore
    const { sessionId } = useParams();
    const { workflowInstance, errorStore, applicationDefinitionsStore } = useStore();
    const { sessionPreviewStore } = useHomeStore();
    const parentStorageKey = `parent-${sessionId}`;
    const childStorageKey = `child-${sessionId}`;

    
    const highlightInputFromStorage = React.useCallback(() => {
        const inputId = localStorage.getItem(parentStorageKey);

        if (inputId) {
            sessionPreviewStore.docStore?.highlightBlockByInputId(inputId);
        }
    }, [parentStorageKey, sessionPreviewStore.docStore]);
    
    React.useEffect(() => {
        if (sessionId) {
            sessionPreviewStore.loadSession(sessionId);
        }
        
        highlightInputFromStorage();
        window.addEventListener('storage', highlightInputFromStorage);
        return (() => {
            window.removeEventListener('storage', highlightInputFromStorage);
            localStorage.removeItem(parentStorageKey);
            localStorage.removeItem(childStorageKey);
        });
    }, [childStorageKey, highlightInputFromStorage, parentStorageKey, sessionId, sessionPreviewStore]);

    React.useEffect(() => {
        if (sessionPreviewStore.ui && !sessionPreviewStore.docStore) {
            const imageService = new ImageService();
            const documentService = new DocumentService();
            const docStore =  new DocumentVisualStore(applicationDefinitionsStore, imageService, errorStore, documentService);
            docStore.setPageWidth(100);
            docStore.prepareAllBlocks(sessionPreviewStore.ui, sessionPreviewStore.session?.packageId);
            sessionPreviewStore.setStore(docStore);
        }
    }, [applicationDefinitionsStore, errorStore, sessionPreviewStore, sessionPreviewStore.ui, workflowInstance]);

    const selectCallback = (inputId: string) => {
        localStorage.setItem(childStorageKey, inputId);
    };

    if (!sessionPreviewStore.docStore || !sessionPreviewStore.session || !sessionPreviewStore.docStore.ui) {
        return null;
    }

    // Temporarily is for Excel docs only. Can be extended if will be necessary.
    return (
        <div className="preview-doc-wrapper">
            <DocumentExcel 
                documentVisualStore={sessionPreviewStore.docStore} 
                packageId={sessionPreviewStore.session.packageId}
                packageName=""
                highlightedInputId={sessionPreviewStore.docStore.highlightedInputId}
                height="calc(100vh - 120px)"
                selectCallback={selectCallback}
            />
        </div>
    );
};

export default observer(PreviewPage);
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import './PackageNameLabel.less';

const PackageNameLabel: React.FC = () => {
    const { tab } = useAppStore();

    return (
        <span className="contract-ingestion-document-preview-package-name">{tab.metadata.packageName as string}</span>
    );
};

export default observer(PackageNameLabel);

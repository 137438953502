import * as React from 'react';
import './styles/common.less';
import './styles/analyzed-doc.less';
import './styles/sessions.less';
import 'react-quill/dist/quill.snow.css';
import './styles/new-contract-analysis.less';
import { configure } from 'mobx';
import { bootstrap } from './modules/common';
import { AskAlphaStoresConditionalProvider } from './modules/ask_alpha/stores/context';
import { BrowserRouter } from 'react-router-dom';
import MainLayout from './modules/UI/components/MainLayout';
import 'ant-design-draggable-modal/dist/index.css';
import { HotKeys } from 'react-hotkeys';
import { DraggableModalProvider } from 'ant-design-draggable-modal';
const ipcRenderer = window.require && window.require('electron').ipcRenderer;

configure({ enforceActions: 'observed' });

// function init() {
  
//   // TODO: find a better way to to keep store's state when doing hot reload
//   if (process.env.NODE_ENV === 'development') {
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     // (window as any).stores = (window as any).stores || rootStores;
//   }
// }

class App extends React.Component {
    handlers = {enableDevTools: this.toggleDevTools.bind(this)};

    componentDidMount() {
        if (process.env.REACT_APP_DISABLE_ANIMATIONS === 'true') {
            document.body.className = 'no-anims';
        }
    }

    toggleDevTools() {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('electron/') > -1) {
            ipcRenderer.send('ALPHA:DEV_TOOLS');
        }

    }
    render() {
        console.log(`Version: ${process.env.REACT_APP_VERSION}`);
        const map = {enableDevTools: 'f12'};

        return (
            <DraggableModalProvider>
                <bootstrap.StoreProvider>
                    {/* Ask alpha state should not be reset when navigating between pages, that's why we provide stores on top level */}
                    <AskAlphaStoresConditionalProvider>
                        <BrowserRouter>
                            <HotKeys keyMap={map} handlers={this.handlers}>
                                <MainLayout />
                            </HotKeys>
                        </BrowserRouter>
                    </AskAlphaStoresConditionalProvider>
                </bootstrap.StoreProvider>
            </DraggableModalProvider>
        );
    }
}

export default App;

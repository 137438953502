import * as React from 'react';
import { FormInputParams } from '../../../common/services/types';

type Props = {
    input: FormInputParams
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getConfidenceLevel(input: FormInputParams): number | undefined {
    if (input.unmodifiedValue == null || !input.meta) {
        return undefined;
    }

    const confidenceLevel = input.meta!.confidenceLevel && input.meta!.confidenceLevel 
                            && input.tokenId && input.meta!.confidenceLevel[input.tokenId];
    return confidenceLevel;
}

export const getFakeConfidenceLevel = () => {
    return Math.floor(Math.random() * (20) + 80);
};

export const getConfLevelColor = (inputParams: FormInputParams) => {
    let styleClass = '';
    if (!inputParams.meta || inputParams.meta.type !== 'tag' ) {
        return '';
    }

    const confidenceLevel = getConfidenceLevel(inputParams);
    styleClass = confidenceLevel! >= 0.9 ? 'confidence-level-high' : confidenceLevel ? 'confidence-level-average' : '';

    return styleClass;
};

export const ConfidenceLevel: React.FC<Props> = ({input}) => {
    if (input.unmodifiedValue == null || !input.meta || input.meta.type !== 'tag') {
        return <></>;
    }

    const confidenceLevel = getConfidenceLevel(input);
    if (!confidenceLevel) {
        return <></>;
    }

    return  <span style={input.meta ? {float: 'right', marginRight: 20} : {float: 'right'}} className="confidenece-level">{Math.round(confidenceLevel * 100)}%</span>;
};
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputModelWithSource } from '../../models';
import { InputWithSource } from '../../componentTypes';
import { GenericSelect } from '../../components';

const config = {
    label: 'Period'
};

const PaymentWithinPeriodInput: InputWithSource = ({ input }) => (
    <GenericSelect label={config.label} input={input} />
);

PaymentWithinPeriodInput.meta = {
    inputType: InputModelWithSource.name
};

export default observer(PaymentWithinPeriodInput);

import { Utils } from '../../../common/misc/Utils';
import * as React from 'react';
import { FormInputFieldData, FormInputParams, WidgetData, WidgetDataItem } from '../../../common/services/types';
import { Col, DatePicker, FormInstance, Input, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { DynamicUiModel } from '../../../common/stores/DynamicUiModel';
import { DynamicModalForm } from './DynamicModalForm';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { useEffect } from 'react';
import { reaction } from 'mobx';
import { Moment } from 'moment-timezone';

type RenderEditProps = {
    id: string;
    selectedRecord: object | undefined;
    inputParams: FormInputParams;
    recordEditForm: FormInstance;
    sources: {[key: string]: WidgetData};
    ui: DynamicUiModel;
    onHighlightBlock: (field: FormInputFieldData, inputId: string) => void;
    getGearIcon: (inputParams: FormInputParams) => React.ReactElement;
    highlightInputSubject?: Subject<string>;
    // tslint:disable-next-line:no-any
    onAction: (id: string, action: string, value?: unknown) => void
};

// eslint-disable-next-line max-len
export const DynamicVisualTableModal: React.FC<RenderEditProps> = ({ui, id, selectedRecord, inputParams, recordEditForm, sources,  onHighlightBlock, highlightInputSubject, getGearIcon, onAction }) => {
    const [source, setSource] = React.useState<{[key: string]: WidgetData}>(sources);
    const formattedKey = Utils.insertWhitespaceBeforeCapital(id).replace(' ', '');
    const override = inputParams.behavior && inputParams.behavior.inputOverride && inputParams.behavior.inputOverride.find(i => i.key === id || i.key === formattedKey);

    useEffect(() => {
        const inputOverride = inputParams.behavior?.inputOverride;
      
        const parentInputId = inputOverride ? inputOverride.find(x => x.key === id)?.parentInputId : null;
        
        const disposer = parentInputId && reaction(() => ui.parentBasedInputValues[parentInputId!], (items: WidgetDataItem[]) => {
            if (override) {
                const val = override.source;
                const sourceObj = {};                
                Object.assign(sourceObj, source );
                sourceObj[val] = {total: items.length, skip: 0, data: items};
                setSource(sourceObj);
            }
            const obj = {};
            obj[id] = undefined;
            recordEditForm.setFieldsValue(obj);
        
        }) || null;
        return () => {
            if (disposer) {
                disposer();
            }
        };

    },        [id, inputParams.behavior?.inputOverride, override, recordEditForm, source, ui.parentBasedInputValues]);
    
    const getFieldName = (key: string) => {
        const displayColumn = inputParams.behavior && inputParams.behavior.displayColumns && inputParams.behavior.displayColumns.find(dc => dc.key === key) 
            ? inputParams.behavior.displayColumns.find(dc => dc.key === key) 
            : undefined;

        if (displayColumn) {
            return displayColumn.header;
        } else {
            const inputs = inputParams?.behavior?.inputOverride;
            const name = inputs && inputs.find(x=> x.key === key)?.name;
            return name ? name : Utils.insertWhitespaceBeforeCapital(key);
        }
    };

    const getModalFormControl = (params: FormInputParams, key: string) => {
        if (!params.modalForm || !ui.actions || !ui.actions.length) {
            return null;
        }

        const newInputParams = {
            ...params,
            id: key
        };

        return (
            <DynamicModalForm 
                inputParams={newInputParams}
                form={recordEditForm}
                getGearIcon={getGearIcon}
                onAction={onAction}
                onHighlightBlock={onHighlightBlock}
                ui={ui}
                highlightInputSubject={highlightInputSubject}
            />
        );
    };

    const getDateMomentFromString = (dateString: string) => {
        moment.tz.setDefault('UTC');
        let newMoment: moment.Moment;
        const defaultFormat = 'DD/MM/YYYY';
        const defaultFormatWithTime = 'DD/MM/YYYY hh:mm:ss A';
        if (moment(dateString, defaultFormat).format(defaultFormat) === dateString) {
            newMoment = moment(dateString, defaultFormat);
        } else if (moment(dateString, defaultFormatWithTime).format(defaultFormatWithTime) === dateString) {
            newMoment = moment(dateString, defaultFormatWithTime);
        } else {
            newMoment = moment(dateString, 'YYYY-MM-DD');
        }
        return newMoment.isValid() ? newMoment : undefined;
    };

    const getInputControls = (key: string) => {
        if (inputParams.behavior && inputParams.behavior.inputOverride) {
            const formattedId = Utils.insertWhitespaceBeforeCapital(id).replace(' ', '');
            const overrideInput = inputParams.behavior.inputOverride.find(i => i.key === key || i.key === formattedId);

            if (overrideInput && overrideInput.hasModalForm === true) {
                return getModalFormControl(inputParams, key);
            }
        }
        return null;
    };

    if (!selectedRecord) { 
        return null;
    }

    const getCurrentWidgetValue = () => {
        if (override?.type == 'dropdown' && override.source && source[override.source]) {
            return source[override.source].data.find(x=> x.text === selectedRecord[id].value)?.value || selectedRecord[id].value;
            
        } else {
            return selectedRecord[id].value;
        }
    };
    
    let formObj = {};
    
    const getSystemInputValue = () => {
        const inputs = inputParams?.behavior?.systemInputs;
        const sysInput =  inputs && inputs.find(x => x.key === id);
        return sysInput && sysInput.value;
    };
    
    if (override && override.type === 'datepicker') {
        formObj[id] = selectedRecord[id] ? getDateMomentFromString(selectedRecord[id].value) : undefined;
    } else  {
        formObj[id] = selectedRecord[id] ? getCurrentWidgetValue() : getSystemInputValue();
    }

    recordEditForm.setFieldsValue(formObj);

    let input = (
        <Input 
            className="dynamic-input" 
            value={selectedRecord[id] ? selectedRecord[id].value : ''} 
            maxLength={override?.maxLength ?? undefined}
            // onChange={(e) => updateFieldInSelectedRecord(key, e.target.value) }
        />
    );
    let span = 10;
    let offset = 1;

    if (override) {

        if (override.hasRowSpecificSource && inputParams.behavior?.displayColumns) {
            const overrideKeyColumn = override.rowSpecificSourceKey ?? inputParams.behavior.displayColumns.find(dc => dc.key == override.key)?.rowSpecificSourceKey ?? '';
            const sourceId = selectedRecord[overrideKeyColumn].value;
            const inputSource = ui.uiModel.sources.find(s => s.id === `${override.key}-${sourceId}`);

  
            input = (
                <Select 
                    allowClear
                    className="dynamic-input"
                    showSearch
                    options={!inputSource ? [] :inputSource.data.map(i => {
                        return {label: i.text, value: i.value}; 
                    })}
                    mode={override.multiSelect === true ? 'tags' : undefined}
                />
            );

            if (!inputSource) {
                const recordFormObj = {};
                recordFormObj[id] = undefined;
                recordEditForm.setFieldsValue(recordFormObj);
            }
        }

        switch (override.type) {
        case 'datepicker':
            input = (
                <DatePicker 
                    className="dynamic-input"
                    // onChange={(val) => updateDateInSelectedRecord(key, val)}
                />
            );
            break;
        case 'dropdown': 
            if (override.source && source[override.source]) {
                const style: React.CSSProperties | undefined = override.singleInputPerRow 
                    ? {width: 'calc(50% - 14px)'} 
                    : undefined;

                input = (
                    <Select 
                        className="dynamic-input"
                        showSearch
                        optionFilterProp='label'
                        options={source[override.source].data.map(i => {
                            return {label: i.text, value: i.value}; 
                        })}
                        mode={override.multiSelect === true ? 'tags' : undefined}
                        style={style}
                        // onSelect={(val) => updateFieldInSelectedRecord(key, val as string)}
                    />
                );
                    
            }
            break;
        case 'text':
            input = (
                <Input.TextArea 
                    className="dynamic-input"
                    rows={7}
                    autoSize={{maxRows: 7}}
                    maxLength={override?.maxLength ?? undefined}
                />
            );
            break;
        default: 
            break;
        }

        if (override.width === 'wide' || override.singleInputPerRow === true) {
            span = 21;
            offset = 1;
        }
    } 

    const label = (
        <div className="input-label">
            <span className="label-title">{getFieldName(id)}</span>
            {getInputControls(id)}
        </div>);
        
    const handleDateChange = (momentObj: Moment, dateString: string) => {
        if (dateString && dateString.length) { 
            const date = new Date(dateString);
            return moment(date);
        }
        return undefined;
    };

    return (
        <Col key={id} span={span} offset={offset}>
            <div className="input-wrapper">
                <FormItem
                    colon={false}
                    label={label}
                    name={id}
                    getValueFromEvent={override && override.type == 'datepicker' ? handleDateChange : undefined}
                >
                    {input}
                </FormItem>
            </div>
        </Col>
    );
};

/* eslint-disable no-undef */
import $ from 'jquery';
import '@progress/kendo-ui/js/kendo.spreadsheet';
import '@progress/kendo-ui/css/web/kendo.common.min.css';
import '@progress/kendo-ui/css/web/kendo.default-v2.min.css';

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import appClient from '../../../common/services/AppClient';

type Props = {
    packageId: string;
    packageName: string;
    highlightedRow?: number;
    higlightRowCallback?: (higlightRow: number) => void;
    tableOffset?: number;
    style?: React.CSSProperties
};

const DocumentExcel: React.FC<Props> = ({
    packageId,
    packageName,
    highlightedRow,
    higlightRowCallback,
    tableOffset = 0,
    style = { width: '100%', height: 'calc(50vh - 80px)' }
}) => {
    const [spreadsheet, setSpreadsheet] = React.useState<kendo.ui.Spreadsheet | null>(null);

    const [blob, setBlob] = React.useState<Blob | null>(null);

    const ref = React.useRef<HTMLDivElement>(null);

    const onSelect = React.useCallback(
        (event: kendo.ui.SpreadsheetSelectEvent) => {
            if (!event.range) {
                return;
            }

            const range = event.range as kendo.spreadsheet.Range & {
                topLeft: () => { col: number; rel: number; row: number }
            };

            const cellRef = range.topLeft();
            const higlightRow = cellRef.row - tableOffset;

            if (higlightRowCallback) {
                higlightRowCallback(higlightRow);
            }
        },
        [tableOffset, higlightRowCallback]
    );

    React.useEffect(() => {
        const url = process.env.REACT_APP_MANAGE_URL + `document/${packageId}`;

        appClient.get<Blob>(url, 'blob').then(resp => {
            if (resp.isOk()) {
                resp.map(setBlob);
            } else {
                console.error(`Can't load file ${packageId}:${packageName} for preview`);
            }
        });
    }, [packageId, packageName]);

    React.useEffect(() => {
        if (!ref.current) {
            return;
        }

        const kendoSpreadsheet = $(ref.current)
            .kendoSpreadsheet({
                sheetsbar: false,
                toolbar: false,
                select: onSelect
            })
            .data('kendoSpreadsheet');

        setSpreadsheet(kendoSpreadsheet);
    }, [onSelect]);

    React.useEffect(() => {
        if (!spreadsheet || !blob) {
            return;
        }

        spreadsheet.fromFile(blob);
    }, [spreadsheet, blob]);

    React.useEffect(() => {
        if (!spreadsheet || typeof highlightedRow !== 'number') {
            return;
        }

        const sheet = spreadsheet.activeSheet();

        sheet.range(`A${highlightedRow + 1}:AA${highlightedRow + 1}`).select();
    }, [spreadsheet, highlightedRow]);

    return <div ref={ref} style={style} />;
};

export default observer(DocumentExcel);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputWithoutSource } from '../../componentTypes';
import { InputModel } from '../../models';
import { GenericPercentageInput } from '../../components';

const config = {
    label: 'Brokerage'
};

const BrokerageInput: InputWithoutSource = ({ input }) => <GenericPercentageInput label={config.label} input={input} />;

BrokerageInput.meta = {
    inputType: InputModel.name
};

export default observer(BrokerageInput);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Spin } from 'antd';
import { useAppStore } from '../../stores';
import { DocumentPreview, DummyForm } from '..';
import './AppContent.less';

const AppContent: React.FC = () => {
    const { tab, sessionStore } = useAppStore();

    React.useEffect(() => {
        sessionStore.getSession();
    }, [sessionStore]);

    if (sessionStore.isLoading || sessionStore.inProgress) {
        return (
            <div className="loading-ui">
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div id={tab.metadata.customUI} className="demo-app-content">
            <div className="left-column">
                <DummyForm />
            </div>

            <div className="right-column">
                <DocumentPreview />
            </div>
        </div>
    );
};

export default observer(AppContent);

import { uniqBy } from 'lodash';
import { PackageFieldResult } from '../../common/services/types';

export default class DocumentFieldFinder {
    public static searchChildFields(searchTerm: string, fieldSearchResults: PackageFieldResult[]) {
        const fieldMatches = fieldSearchResults.reduce<PackageFieldResult[]>(
            (acc, field) => acc.concat(DocumentFieldFinder.findMatches(searchTerm, field.childFields)),
            []
        );

        return uniqBy(fieldMatches, 'fieldId');
    }

    private static findMatches(searchTerm: string, childFields: PackageFieldResult[] | null) {
        if (!childFields || !childFields.length) {
            return [];
        }

        // Split the search term into characters
        const searchTermCharacters = searchTerm.toLowerCase().trim().split('');

        const characterMap = new Map<number, string>();
        const allCharacters: string[] = [];

        let characterIndex = 0;

        // Iterate through child field and map each character index to child field id
        childFields.forEach(childField => {
            const childFieldTextCharacters = (childField.text.toLowerCase().trim() + ' ').split('');

            childFieldTextCharacters.forEach(char => {
                characterMap.set(characterIndex, childField.fieldId);
                allCharacters.push(char);
                characterIndex++;
            });
        });

        // Find the index ranges of the search term characters in the all characters array
        const indexRanges = DocumentFieldFinder.findSubarrayIndexes(allCharacters, searchTermCharacters);
        const childFieldIdsSet = new Set<string>();

        // Iterate through the index ranges and get child field ids
        indexRanges.forEach(range => {
            for (let i = range.start; i <= range.end; i++) {
                const childFieldId = characterMap.get(i);

                if (childFieldId) {
                    childFieldIdsSet.add(childFieldId);
                }
            }
        });

        return childFields.filter(childField => childFieldIdsSet.has(childField.fieldId));
    }

    private static findSubarrayIndexes(mainArray: string[], subArray: string[]) {
        const result: { start: number; end: number }[] = [];
        const subArrayLength = subArray.length;
        const mainArrayLength = mainArray.length;

        for (let i = 0; i <= mainArrayLength - subArrayLength; i++) {
            let j = 0;

            while (j < subArrayLength && mainArray[i + j] === subArray[j]) {
                j++;
            }

            if (j === subArrayLength) {
                result.push({ start: i, end: i + subArrayLength - 1 });
            }
        }

        return result;
    }
}

import { LayoutSettingsBaseModel } from '..';
import { LayersLayoutSettingsDto } from '../../types';

export default class LayersLayoutSettingsModel extends LayoutSettingsBaseModel {
    readonly numberOfLayers: number;

    constructor(data: LayersLayoutSettingsDto) {
        super(data);

        this.numberOfLayers = data.numberOfLayers;
    }

    get label() {
        return 'Layers Layout';
    }

    get dto(): LayersLayoutSettingsDto {
        return {
            type: this.type,
            numberOfLayers: this.numberOfLayers
        };
    }
}

import * as React from 'react';
import security from '../../common/services/SecurityService';

export default class NoRolesError extends Error {
    errorScreen: React.ReactElement;
    
    constructor() {
        super();
        this.errorScreen = (
            <div className="no-access-to-portal">No roles assigned to current user. Please report to Administrator... 
                <span onClick={this.logout}>&nbsp;Log out</span>
            </div>);
    }

    logout() {
        security.inst.logout();
    }
}
import * as React from 'react';
import { SearchVisualStore } from '../../stores';
import { SearchResultModel, SearchResultMessageModelData } from '../../types';
import { observer } from 'mobx-react-lite';
import { MailOutlined } from '@ant-design/icons';

type Props = {
    store: SearchVisualStore;
    result: SearchResultModel;
    style?: React.CSSProperties
};

const MessageSearchResult: React.FC<Props> = ({ store, result, style }) => {
    if (result.type !== 'MESSAGES') {
        return null;
    }

    const item = result.data as SearchResultMessageModelData;

    const handleLinkClick = () => {
        if (result.url || item.url) {
            window.open(result.url || item.url);
        }
    };

    return (
        <div
            style={{ cursor: 'default', ...style }}
            key={item.id}
            className={'ant-list-item project-list-item'}
        >
            <div>
                <span className="cognitive-search-thumbnail" style={{background: 'transparent', textAlign: 'center'}}>
                    <MailOutlined style={{color: '#273c75', fontSize: 22}} />
                </span>
            </div>
            <div>
                <div>
                    <span
                        style={{
                            fontSize: 13,
                            cursor: 'pointer',
                            color: '#2750b7',
                            textDecoration: 'underline'
                        }}
                        onClick={handleLinkClick}
                    >
                        {item.subject}
                    </span>
                </div>
                <div className="additional-info">
                    <div style={{ margin: '3px 0' }}>
                        <span
                            style={{ borderRadius: 4 }}
                            className={'project-tag selectable'}
                        >
                            From: {item.from}
                        </span>
                        <span
                            style={{ borderRadius: 4 }}
                            className={'project-tag selectable'}
                        >
                            To: {item.toRecipients.join(', ')}
                        </span>
                    </div>
                    <div 
                        className="search-blocks-preview" 
                        onClick={() => store.setHighlightPreview(item.body, item.id)}
                        style={{
                            background: store.highlightedBlockId && store.highlightedBlockId === item.id ? 'aliceblue' : 'transparent'
                        }}
                    >
                        {item.body}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(MessageSearchResult);
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { FormInstance } from 'antd';
import { useAppStore } from '../../stores';
import {
    AppHeader,
    TabsRenderer,
    DocumentPreview,
    ContractStatusModal,
    FlowResponseRenderer,
    ErrorsRenderer
} from '../../components';
import './ContractIngestionAppScreen.less';
import '../../../custom_shared/styles/common.less';

const ContractIngestionAppScreen: React.FC = () => {
    const appStore = useAppStore();

    const formRefs = React.useRef<Map<string, FormInstance>>(new Map());

    const addFormRef = React.useCallback((id: string, form: FormInstance) => {
        formRefs.current.set(id, form);
    }, []);

    React.useEffect(() => {
        appStore.sessionStore.initialize();
    }, [appStore.sessionStore]);

    React.useEffect(() => {
        return () => appStore.dispose();
    }, [appStore]);

    return (
        <div className="contract-ingestion-app-screen">
            <AppHeader formRefs={formRefs} />
            <ContractStatusModal />
            <FlowResponseRenderer />
            <ErrorsRenderer />
            <TabsRenderer addFormRef={addFormRef} />
            <DocumentPreview />
        </div>
    );
};

export default observer(ContractIngestionAppScreen);

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import '@progress/kendo-ui/js/kendo.spreadsheet';
import * as $ from 'jquery';
import '@progress/kendo-ui/css/web/kendo.common.min.css';
import '@progress/kendo-ui/css/web/kendo.default-v2.min.css';
import { DocumentVisualStore } from '../stores';
import { WidgetType } from '../../common/services/types';
import appClient from '../../common/services/AppClient';

export type Props = {    
    packageId: string;
    packageName: string;
    highlightedInputId: string | undefined;
    documentVisualStore: DocumentVisualStore;
    height?: string;
    selectCallback?: (inputId: string) => void
};

export default class DocumentExcel extends React.Component<Props> {
    spreadsheet?: kendo.ui.Spreadsheet;
    initialSelectionIgnored = false;
    disableSelectionTrigger = false;
    visualTableOffset: number = 0;

    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        const url = process.env.REACT_APP_MANAGE_URL + `document/${this.props.packageId}`;
        const resp = await appClient.get<Blob>(url, 'blob');

        this.props.documentVisualStore.setPageWidth(100);

        resp.mapErr(() => {
            console.error(`Can't load file ${this.props.packageId}:${this.props.packageName} for preivew`);
        }).map(blob => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const onSelect = (arg: any) => {
                if (this.disableSelectionTrigger) {
                    return;
                }
    
                if (!this.initialSelectionIgnored) {
                    this.initialSelectionIgnored = true;
                    return;
                }
    
                const cellRef = arg.range.topLeft();
                if (!cellRef?.row) {
                    return;
                }
    
                const visualTableId = this.props.documentVisualStore.ui?.inputs?.find(i => i.controlType === WidgetType.VisualTable)?.id;
                const visualTableSource = this.props.documentVisualStore.ui?.inputs?.find(i => i.controlType === WidgetType.VisualTable)?.source?.id;
                if (visualTableId && visualTableSource) {
                    const higlightRow = cellRef.row - this.visualTableOffset;
                    const highlightId = `${visualTableId}-${higlightRow}`;
                    this.props.documentVisualStore.highlightBlockByInputId(highlightId);
    
                    if (this.props.selectCallback) {
                        this.props.selectCallback(highlightId);
                    }
                }
            };
    
            const calculateRowOffset = () => {
                const { ui } = this.props.documentVisualStore;
                if (!ui) {
                    return;
                }
                const visualTableSource = ui.inputs?.find(i => i.controlType === WidgetType.VisualTable)?.source?.id;
    
                if (!visualTableSource) {
                    return;
                }
    
                ui.getWidgetArrayValueFromSource(visualTableSource).then(r => {
                    if (r instanceof Array && r.length) {
                        const val = r[0].value;
    
                        if (val instanceof Array && val.length) {
                            const rowVal = val[0];
                            const rowIndexKey = 'ALPHA:RowIndex';
    
                            if (!rowVal) {
                                return;
                            }
    
                            const rowIndex = rowVal[rowIndexKey];
                            const keys = Object.getOwnPropertyNames(rowVal);
                            const rowFieldName = keys.find(k => k !== rowIndexKey);
    
                            if (!rowFieldName) {
                                return;
                            }
    
                            const y = rowVal[rowFieldName].meta?.field?.y;
    
                            if ([y, rowIndex].some(n => n === null || n === undefined)) {
                                return;
                            }
    
                            this.visualTableOffset = y - rowIndex;
                        }
                    }
                });
            };
            
            calculateRowOffset();
    
            const that = this;
            let node;

            try {
                node = ReactDOM.findDOMNode(this);
            } catch {
                return;
            }

            if (!node) {
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.spreadsheet = $(node).kendoSpreadsheet({
                sheetsbar: true,
                toolbar: false,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                excelImport: function(e: any) {
                    e.promise
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        .progress(function(ev: any) {
                            /* The result can be observed in the DevTools(F12) console of the browser. */
                            console.log(kendo.format('{0:P} complete', ev.progress));
                        })
                        .done(function() {
                            console.log('Export completed!');
                            const spreadSheet = e.sender ;
                            const activeSheet = spreadSheet?.activeSheet();
                            if (activeSheet) {
                                const rowsCount = activeSheet._rows._count;
                                const columns = activeSheet._columns._count;
                                const rowOffset = that.visualTableOffset > 0 ? that.visualTableOffset - 1 : 1;
                                activeSheet.range(rowOffset, 0 , rowsCount - rowOffset, columns).filter(true);
                            }
                        });
                },
                select: onSelect
            }).data('kendoSpreadsheet');
    
            this.spreadsheet.fromFile(blob);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.spreadsheet.one('render', function (e: any) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                e.sender.sheets().forEach(function (sheet: any) {
                    sheet.range(0, 0, sheet._rows._count, sheet._columns._count).enable(false);
                });
            });
        });
    }

    componentDidUpdate(props: Props) {
        if (!this.spreadsheet || !props.documentVisualStore.highlightedField) {
            return;
        }

        const field = props.documentVisualStore.highlightedField;

        const sheet = this.spreadsheet.activeSheet();
        this.disableSelectionTrigger = true;
        sheet.range(`A${field.y + 1}:AA${field.y + 1}`).select();
        this.disableSelectionTrigger = false;
    }


    render = () => (<div id={'spreadsheet'} style={ { width: '100%', height: this.props.height ?? 'calc(50vh - 80px)' } }/>);
}
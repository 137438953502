import { makeObservable, observable, runInAction } from 'mobx';
import { ContractsIngestionRuntimeInputDto } from '../../types';
import { InputBaseModel, SourceModelBase, StaticSourceModel, TabBaseModel } from '..';
import { SourceProvider } from '../../misc';

export default class InputModelWithSource extends InputBaseModel {
    sourceProvider: SourceProvider;

    constructor(tab: TabBaseModel, data: ContractsIngestionRuntimeInputDto, source?: SourceModelBase) {
        super(tab, data);

        this.createSourceProvider(source);

        makeObservable(this, {
            sourceProvider: observable
        });
    }

    createSourceProvider(source?: SourceModelBase) {
        runInAction(() => {
            if (source) {
                this.sourceProvider = new SourceProvider(source);
                return;
            }

            console.warn(`Source not found for input '${this.id} ${this.guid}'`);
            this.sourceProvider = new SourceProvider(new StaticSourceModel({ id: '', type: '', link: null, data: [] }));
        });
    }
}

import React from 'react';
import { Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { debounce } from 'lodash';
import { SearchIcon, CrossIcon } from '../../../custom_shared/icons';
import { useAppStore } from '../../stores';
import './TextSearch.less';

const searchLabel = 'Search';

const TextSearch: React.FC = () => {
    const { documentPreviewStore } = useAppStore();

    const inputRef = React.useRef<Input>(null);

    const debouncedSearch = React.useMemo(
        () =>
            debounce(() => {
                documentPreviewStore.searchPackageFields();
            }, 500),
        [documentPreviewStore]
    );

    React.useEffect(() => {
        debouncedSearch();
    }, [documentPreviewStore.searchTerm, debouncedSearch]);

    React.useEffect(() => {
        if (documentPreviewStore.searchInputVisible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [documentPreviewStore.searchInputVisible]);

    if (!documentPreviewStore.searchInputVisible) {
        return (
            <div
                className="contract-ingestion-text-search-container"
                onClick={e => {
                    e.stopPropagation();
                    documentPreviewStore.setSearchInputVisible(true);
                }}
            >
                <div className="input-activator">
                    <SearchIcon />
                    <span>{searchLabel}</span>
                </div>
            </div>
        );
    }

    return (
        <div className="contract-ingestion-text-search-container" onClick={e => e.stopPropagation()}>
            <div className="text-search-input">
                <SearchIcon />
                <Input
                    ref={inputRef}
                    placeholder={searchLabel}
                    value={documentPreviewStore.searchTerm}
                    onChange={e => documentPreviewStore.setSearchTerm(e.target.value)}
                />
                <CrossIcon onClick={() => documentPreviewStore.setSearchInputVisible(false)} />
            </div>
        </div>
    );
};

export default observer(TextSearch);

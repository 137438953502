import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import { WindowPortal } from '..';
import FullScreenDocumentPreview from './FullScreenDocumentPreview';
import BottomDocumentPreview from './BottomDocumentPreview';

const DocumentPreview: React.FC = () => {
    const [externalWindow, setExternalWindow] = React.useState<Window | undefined>(undefined);

    const { documentPreviewStore } = useAppStore();

    React.useEffect(() => {
        return () => {
            if (externalWindow) {
                externalWindow.close();
            }
        };
    }, [externalWindow]);

    if (documentPreviewStore.previewPosition === 'fullScreen') {
        return (
            <WindowPortal
                title="Document Preview"
                externalWindow={externalWindow}
                setExternalWindow={setExternalWindow}
                onWindowClose={() => {
                    documentPreviewStore.setPreviewPosition('bottom');
                    setExternalWindow(undefined);
                }}
            >
                <FullScreenDocumentPreview externalWindow={externalWindow} />
            </WindowPortal>
        );
    }

    return <BottomDocumentPreview />;
};

export default observer(DocumentPreview);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { GenericDecimalInput } from '../../components';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';

const config = {
    label: 'Estimated Premium Income'
};

const EstimatedPremiumIncomeInput: InputWithoutSource = ({ input }) => (
    <GenericDecimalInput label={config.label} input={input} />
);

EstimatedPremiumIncomeInput.meta = {
    inputType: InputModel.name
};

export default observer(EstimatedPremiumIncomeInput);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { TabModel } from '../common/types/TabModel';
import CustomAppRegistry from './CustomAppRegistry';

export interface CustomAppProps {
    tab: TabModel
}

const CustomAppRenderer: React.FC<CustomAppProps> = ({ tab }) => {
    const CustomApp = CustomAppRegistry[tab.metadata.customUI];

    if (!CustomApp) {
        console.error(`Custom app '${tab.metadata.customUI}' is not registered`);
        return null;
    }

    return <CustomApp tab={tab} />;
};

export default observer(CustomAppRenderer);

import { makeObservable, observable, action, computed } from 'mobx';
import { LayersWithSectionsTabDto } from '../../types';
import { TabBaseModel, SourceModelBase, LayerWithSectionsModel } from '..';

export default class LayersWithSectionsTabModel extends TabBaseModel {
    layers: LayerWithSectionsModel[] = [];

    constructor(data: LayersWithSectionsTabDto, sources: SourceModelBase[]) {
        super(data);

        this.layers = data.layers.map(layer => new LayerWithSectionsModel(this, layer, sources));

        makeObservable(this, {
            layers: observable,
            inputs: computed,
            update: action
        });
    }

    getLayerByInputGuid(guid: string) {
        for (const layer of this.layers) {
            if (layer.sections.some(s => s.inputs.some(i => i.guid === guid))) {
                return layer;
            }
        }

        return null;
    }

    update(data: LayersWithSectionsTabDto, sources: SourceModelBase[]) {
        data.layers.forEach(layer => {
            const existingLayer = this.layers.find(l => l.id === layer.id);

            if (!existingLayer) {
                this.layers.push(new LayerWithSectionsModel(this, layer, sources));
            } else {
                existingLayer.update(layer, sources);
            }
        });

        this.layers = this.layers.filter(layer => data.layers.some(l => l.id === layer.id));
    }

    get inputs() {
        return this.layers.flatMap(layer => layer.sections.flatMap(section => section.inputs));
    }

    get dto(): LayersWithSectionsTabDto {
        return {
            id: this.id,
            type: this.type,
            name: this.name,
            layers: this.layers.map(layer => layer.dto)
        };
    }
}

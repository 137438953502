import { makeObservable, observable, action } from 'mobx';
import { PackageListItemModel, PackageStateResult } from '../../common/services/types';
import { ProjectsService } from '../../common/services';
import { ProjectsStore } from '../../common/stores';
import { PackageChanges } from '../../common/services/types';
import { types } from '../../common/services';

export default class AskAlphaPackagesStore {
    autocompletePackages: PackageListItemModel[] = [];

    packageSearchTerm: string = '';

    projectIds: string[] = [];

    constructor(private readonly projectStore: ProjectsStore, private readonly service: ProjectsService) {
        makeObservable<AskAlphaPackagesStore, 'updatePackageState'>(this, {
            autocompletePackages: observable,
            packageSearchTerm: observable,
            projectIds: observable,
            setAutocompletePackages: action,
            setPackageSearchTerm: action,
            setProjectIds: action,
            updatePackageState: action.bound
        });

        this.projectStore.packageChanges.subscribe(this.updatePackageState);
    }

    setAutocompletePackages(autocompletePackages: PackageListItemModel[]) {
        this.autocompletePackages = autocompletePackages;
    }

    setPackageSearchTerm(packageSearchTerm: string) {
        this.packageSearchTerm = packageSearchTerm;
    }

    setProjectIds(projectIds: string[]) {
        this.projectIds = projectIds;
    }

    async searchAutocompletePackages() {
        try {
            const searchTerm = this.packageSearchTerm.trim();

            const request = {
                searchTerm,
                page: 0,
                pageSize: 15,
                projectIds: this.projectIds,
                orderBy: null,
                distinctBy: null,
                fieldsSearch: {
                    packageMatch: {
                        state: { $in: [PackageStateResult.Ready] }
                    },
                    additionalSearchTerm: searchTerm.length
                        ? {
                            key: 'userTags',
                            value: searchTerm
                        }
                        : {}
                },
                uploadedBy: this.projectStore.hasAccessToAllEntities ? null : this.projectStore.currentUserId
            };

            const response = await this.service.searchPackages(request);

            this.setAutocompletePackages(response.packages);
        } catch (err) {
            console.error(err);
        }
    }

    private updatePackageState(data: unknown) {
        const packageChange = data as PackageChanges;

        if (!this.projectIds.includes(packageChange.projectId)) {
            return;
        }

        const packageListItem: PackageListItemModel = { ...packageChange, type: types.PackageListItemType.Package };
        const packageIndex = this.autocompletePackages.findIndex(i => i.id === packageListItem.id);

        if (packageIndex === -1) {
            this.setAutocompletePackages([packageListItem, ...this.autocompletePackages]);
            return;
        }

        const newPackageListItems = [...this.autocompletePackages];

        newPackageListItems[packageIndex] = packageListItem;

        this.setAutocompletePackages(newPackageListItems);
    }
}

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomSelectWrapper, InputActions, CustomFormItem } from '../../components';
import { InputModelWithSource } from '../../models';
import { ValidationMessages } from '../../misc';
import { InputWithSource } from '../../componentTypes';

const config = {
    label: 'Currency'
};

const CurrencyInput: InputWithSource = ({ input }) => {
    return (
        <CustomFormItem
            name={input.guid}
            required={input.required}
            message={ValidationMessages.requiredInput(config.label)}
        >
            <CustomSelectWrapper
                input={input}
                label={config.label}
                options={input.sourceProvider.options}
                topRightNode={<InputActions input={input} />}
                required={input.required}
            />
        </CustomFormItem>
    );
};

CurrencyInput.meta = {
    inputType: InputModelWithSource.name
};

export default observer(CurrencyInput);

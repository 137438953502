import { makeObservable, observable, action } from 'mobx';
import { SourceModelBase } from '../models';
import { CustomSourceOption } from '../../custom_shared/types';
import { InstanceUtils } from '.';

export default class SourceProvider {
    loading: boolean = false;

    options: CustomSourceOption[] = [];

    constructor(private readonly source: SourceModelBase) {
        makeObservable(this, {
            loading: observable,
            options: observable,
            setLoading: action,
            setOptions: action
        });
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    setOptions(options: CustomSourceOption[]) {
        this.options = options;
    }

    async getSourceOptions() {
        this.setOptions(await this.source.getSourceOptions());
    }

    async getSourceOptionsByParent(parentValues: unknown) {
        if (!InstanceUtils.isLinkedSource(this.source)) {
            return;
        }

        this.setOptions(await this.source.getSourceOptionsByParent(parentValues));
    }
}


import { Observer, Observable } from 'rxjs';
import PushClient from './PushClient';
import { ApplicationSessionResult, PackageResult, PackageSetResult, IotaSessionResult } from './types';
import { CustomSessionChangeResult } from '../../custom_shared/types';

export default class ManagePushClient {
    public static Client = new PushClient(process.env.REACT_APP_PUSH_URL!);
    private static IsTestEnv = process.env.NODE_ENV === 'test';

    static get ConnectionStatusSubject() {
        return ManagePushClient.Client.ConnectionStatusSubject;
    }    

    static get ReconnectAttemptCount() {
        return ManagePushClient.Client.ReconnectAttemptCount;
    }

    createPackageListener(): Observable<PackageResult> {
        return Observable.create((obs: Observer<PackageResult>) => {
            if (ManagePushClient.IsTestEnv) {
                return;
            }

            ManagePushClient.Client.Connection.on('PackageMessage', (data: string) => {
                console.info('Received data from push service on packages...');
                obs.next(JSON.parse(data));
            });
        });
    }

    createPackageSetListener(): Observable<PackageSetResult> {
        return Observable.create((obs: Observer<PackageSetResult>) => {
            if (ManagePushClient.IsTestEnv) {
                return;
            }

            ManagePushClient.Client.Connection.on('PackageSetMessage', (data: string) => {
                console.info('Received data from push service on package sets...');
                obs.next(JSON.parse(data));
            });
        });
    }

    createSessionStateListener(): Observable<ApplicationSessionResult> {
        return Observable.create((obs: Observer<ApplicationSessionResult>) => {
            if (ManagePushClient.IsTestEnv) {
                return;
            }
            
            ManagePushClient.Client.Connection.on('SessionStatusMessage', (data: string) => {
                console.info('Received data from push service...');
                obs.next(JSON.parse(data));
            });
        });
    }

    createIotaSessionListener(): Observable<IotaSessionResult | CustomSessionChangeResult<unknown>> {
        return Observable.create((obs: Observer<IotaSessionResult | CustomSessionChangeResult<unknown>>) => {
            if (ManagePushClient.IsTestEnv) {
                return;
            }

            ManagePushClient.Client.Connection.on('IotaSessionMessage', (data: string) => {
                console.info('Received data from iota push service on iota sessions...');
                obs.next(JSON.parse(data));
            });
        });
    }    
}
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Upload, message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { useAskAlphaStores } from '../../stores/context';
import './FileUploadOverlay.less';

interface Props {
    isVisible: boolean
}

const FileUploadOverlay: React.FC<Props> = ({ isVisible }) => {
    const { askAlphaStore } = useAskAlphaStores();

    const onChange = (info: UploadChangeParam) => {
        const status = info.file.status;

        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }

        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    return (
        <div className="package-upload-overlay" style={{ display: !isVisible ? 'none' : undefined }}>
            <Upload.Dragger
                className="document-uploader"
                showUploadList={false}
                action={askAlphaStore.uploadAction}
                multiple={false}
                name="file"
                onChange={onChange}
                onDrop={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                beforeUpload={() => {
                    askAlphaStore.clearChat();
                    askAlphaStore.setSelectedPackageId(undefined);
                    askAlphaStore.updateSearch('');
                    return true;
                }}
            >
                Drop file here
            </Upload.Dragger>
        </div>
    );
};

export default observer(FileUploadOverlay);

import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { DocumentVisualStore } from '../../documents/stores';
import { DocumentService } from '../services';
import ImageService from '../services/ImageService';
import { ProjectsStore, TabsStore, ApplicationDefinitionsStore, ErrorStore } from '../stores';
import { DynamicUiModel } from '../stores/DynamicUiModel';
import { TabModel } from '../types/TabModel';
import DocumentPreviewSelector from '../../documents/components/DocumentPreviewSelector';

type PackageSetItemProps = {
    tab: TabModel;
    packageId: string;
    projectsStore: ProjectsStore;
    hasLeftContainer: boolean;
    collapsed: boolean;
    paginationPosition: string;
    tabsStore: TabsStore;
    applicationDefinitionsStore: ApplicationDefinitionsStore;
    errorStore: ErrorStore;
    packageName: string
};

const PackageSetItemRenderer: React.FC<PackageSetItemProps> = ({
    tab, 
    packageId, 
    projectsStore, 
    hasLeftContainer, 
    collapsed,
    paginationPosition, 
    tabsStore, 
    applicationDefinitionsStore,
    errorStore,
    packageName}) => {
    const storeId = `${tab.id}-${packageId}`;

    const handleResize = React.useCallback(() => {
        const docStore = tabsStore.documentStores[storeId];
        if (docStore && docStore.highlightedField && docStore.highlightedInputId) {
            docStore.highlightBlock(docStore.highlightedField, docStore.highlightedInputId);
        }
    }, [storeId, tabsStore.documentStores]);

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);

        return (() => {
            window.removeEventListener('resize', handleResize);
        });
    },              [handleResize]);
    
    if (!tabsStore.documentStores[storeId]) {
        const imageService = new ImageService();
        const documentService = new DocumentService();
        tabsStore.documentStores[storeId] = new DocumentVisualStore(applicationDefinitionsStore, imageService, errorStore, documentService);
    }

    if (!tab.metadata.uiIsLoading) {
        const ui = tab.metadata.dynamicUI as DynamicUiModel;
        if (ui && packageId) {
            tabsStore.documentStores[storeId].prepareAllBlocks(ui, packageId);
        }
    }

    const blockClickCallback = (inputId: string | undefined) => {
        const mainStore = tabsStore.documentStores[tab.id];
        const docBlock = tabsStore.documentStores[storeId].documentBlocks.find(d => d.id === inputId);
        if (mainStore) {
            mainStore.highlightBlockByInputId(inputId, docBlock);
        }
    };

    return (
        <div className={`document-wrapper ${collapsed ? 'bottom-collapsed' : ''}`} style={{height: 'calc(100vh - 180px)'}}>
            <DocumentPreviewSelector 
                paginationPosition={paginationPosition}
                documentVisualStore={tabsStore.documentStores[storeId]!}
                projectsStore={projectsStore}
                packageId={packageId as string}
                sessionId={tab.metadata.sessionId as string}
                modelIsLoading={tab.metadata.uiIsLoading as boolean}
                hasLeftContainer={hasLeftContainer}
                highlightBlockCallback={blockClickCallback}
                packageName={packageName}
            />
        </div>
    );
};

export default observer(PackageSetItemRenderer);
import * as React from 'react';
import { Route } from 'react-router';
import OpenIdSuccessPage from '../screens/OpenIdSuccessPage';

const authorizationRoute = () => {
    return (
        <Route 
            path="/openid-success" 
            element={
                <OpenIdSuccessPage />
            } 
        />
    );
};
 

export default authorizationRoute;
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useSearchStore } from '../stores/context';
import CognitiveSearch from '../components/CognitiveSearch';

const SearchPage: React.FC = () => {
    const searchStore = useSearchStore();

    return (
        <CognitiveSearch store={searchStore.searchVisualStore} />
    );
};

export default observer(SearchPage);
/* eslint-disable @typescript-eslint/member-ordering */
import { action, runInAction, observable, toJS, makeObservable } from 'mobx';
import { SessionService } from '../services';
import {
    FormInputModels,
    IotaAppComment,
    IotaSessionResult,
    SessionState,
    SessionStates,
    TabApplicationData,
    SessionFilter
} from '../services/types';
import { types } from '../services';
import { DynamicUiModel } from './DynamicUiModel';
import { IotaSessionsStore, UserProfileStore } from '.';
import { Subject } from 'rxjs';

const GET_SESSION_STATE_RETRY_COUNT = 1000;
const GET_SESSION_SATE_TIMEOUT = 500;

export default class WorkflowInstanceStore {
    isPdfHandleInProgress: boolean = false;

    sessionId: string;

    dynamicUi: DynamicUiModel;

    applicationName: string = '';

    // TODO: Make dedicated store and view for scaned results
    renderScanResults: boolean = false;

    sessionGetRetryCount: number = 0;

    reloadInProgress: boolean = false;

    sessionToComplete: string;

    sessionExtension: {[sessionId: string]: string} = {};

    uiUpdateSubject = new Subject<DynamicUiModel | undefined>();

    preservedAppData: TabApplicationData;

    constructor(
        public sessionService: SessionService, 
        private iotaSessionsStore: IotaSessionsStore, 
        private userProfileStore: UserProfileStore
    ) {
        makeObservable(this, {
            isPdfHandleInProgress: observable,
            sessionId: observable,
            dynamicUi: observable,
            applicationName: observable,
            reloadInProgress: observable,
            setApplicationName: action.bound,
            setReloadInProgress: action,
            startSession: action.bound,
            startSessionForPackageSet: action.bound,
            startSessionForMultiplePackages: action.bound,
            reload: action,
            reloadPackageSession: action.bound,
            getSessionState: action.bound,
            continueSession: action,
            loadSessionUiModel: action.bound,
            createUiModel: action,
            getUiModel: action,
            handleFormSubmit: action,
            loadApplicationSessions: action,
            switchToSession: action,
            getFileFromIotaTempStorage: action.bound,
            getSessionUiModel: action
        });

        this.iotaSessionsStore.iotaSessionChanges.subscribe((s: IotaSessionResult) => {
            if (this.sessionExtension[s.sessionId]) {
                this.loadSessionUiModel(s.sessionId, s.response, s.inProgress);
            }
        });
    }

    setApplicationName(name: string) {
        this.applicationName = name;
    }

    setReloadInProgress(val: boolean) {
        this.reloadInProgress = val;
    }

    async startSession(
        packageId: string,
        appId: string,
        packageName: string,
        extension: string,
        callback?: (sessionId: string, packageId: string, packageName: string) => void
    ) {
        this.isPdfHandleInProgress = true;
        this.renderScanResults = true;

        const resp = await this.sessionService.startSession(appId, packageId);
        resp.map(sess => {
            runInAction(() => {
                this.sessionId = sess.sessionId;
            });
    
            if (callback) {
                callback(sess.sessionId, packageId, packageName);
            }
    
            if (extension) {
                this.sessionExtension[this.sessionId] = extension;
            } 
        });        
    }

    async startSessionForPackageSet(
        packageSetId: string,
        appId: string,
        extension: string | undefined,
        setName: string,
        callback?: (sessionId: string, setId: string, setName: string) => void,
        isBulk: boolean = true
    ) {
        this.isPdfHandleInProgress = true;
        this.renderScanResults = true;

        const resp = await this.sessionService.startSessionForPackageSet(appId, packageSetId, isBulk);
        resp.map(data => {
            const sessionId = data && data.length ? data[0] : '';

            runInAction(() => {
                this.sessionId = sessionId;
            });
            
            if (callback) {
                callback(sessionId, setName, packageSetId);
            }
    
            if (extension) {
                this.sessionExtension[this.sessionId] = extension;
            } 
        });        
    }

    async startSessionForMultiplePackages(
        packageIds: string[],
        appId: string,
        extension: string,
        callback?: (sessionId: string) => void
    ) {
        this.isPdfHandleInProgress = true;
        this.renderScanResults = true;

        const resp = await this.sessionService.startSessionForMultiplePackages(appId, packageIds);
        resp.map(data => {
            runInAction(() => {
                this.sessionId = data.sessionId;
            });
    
            if (callback) {
                callback(data.sessionId);
            }
    
            if (extension) {
                this.sessionExtension[this.sessionId] = extension;
            }
        });        
    }

    async reload(
        pkgId: string,
        appId: string,
        sessionId: string,
        packageName: string,
        extension: string,
        callback?: (sessionId: string, packageId: string, packageName: string) => void
    ) {
        this.sessionToComplete = sessionId;
        this.setReloadInProgress(true);
        await this.startSession(pkgId, appId, packageName, extension, callback);
    }

    async reloadPackageSession(
        sessionId: string,
        pkgSetId: string,
        appId: string,
        extension: string,
        setName: string,
        callback?: (sessionId: string, setName: string, setId: string) => void,
        isBulk: boolean = true
    ) {
        this.sessionToComplete = sessionId;
        this.setReloadInProgress(true);
        await this.startSessionForPackageSet(pkgSetId, appId, extension, setName, callback, isBulk);
    }

    async updateSession(sessionId: string, state: SessionStates) {
        return this.sessionService.updateSession(sessionId, state);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async getSessionState( runtimeSessionId: string, resolve: any, reject: any,  extension: string | undefined = undefined ): Promise<any> {
        if (!runtimeSessionId) {
            return;
        }
        
        setTimeout(async () => {
            let resp = !extension ? await this.sessionService.getSessionState(runtimeSessionId) : await this.sessionService.getSessionStateFromIota(runtimeSessionId, extension);
            const that = this;

            resp.mapErr(() => {
                if (this.sessionGetRetryCount < GET_SESSION_STATE_RETRY_COUNT) {
                    this.sessionGetRetryCount++;
                    return that.getSessionState(runtimeSessionId, resolve, reject, extension);
                } else {
                    return reject();
                }
            }).map(state => {
                if (!state && this.sessionGetRetryCount < GET_SESSION_STATE_RETRY_COUNT) {
                    this.sessionGetRetryCount++;
                    return this.getSessionState(runtimeSessionId, resolve, reject, extension);
                } else if (!state.inProgress) {
                    this.sessionGetRetryCount = 0;
                    return resolve(state);
                } else if (!this.isPdfHandleInProgress) {
                    this.sessionGetRetryCount = 0;
                    return resolve(state);
                } else if (this.sessionGetRetryCount < GET_SESSION_STATE_RETRY_COUNT) {
                    this.sessionGetRetryCount++;
                    return this.getSessionState(runtimeSessionId, resolve, reject, extension);
                } else {
                    this.sessionGetRetryCount = 0;
                    return resolve();
                }
            });                      
            
        },  GET_SESSION_SATE_TIMEOUT);
    }

    async continueSession(values: {}, actionId: string, runtimeSessionId: string, extension: string) {
        this.dynamicUi?.setInProgress(true);
        this.isPdfHandleInProgress = true;

        this.sessionExtension[runtimeSessionId] = extension;

        await this.sessionService.continueSession(values, actionId, runtimeSessionId, extension);
    }

    async loadSessionUiModel(
        runtimeSessionId: string,
        response: FormInputModels | undefined = undefined,
        inProgress: boolean = false
    ) {
        const extension = this.sessionExtension[runtimeSessionId];
        if (extension) {
            this.uiUpdateSubject.next(undefined);
            
            if (!this.isPdfHandleInProgress) {
                return;
            }
    
            if (response) {
                this.dynamicUi = new DynamicUiModel(response, this.sessionService, this.userProfileStore, this);
                this.dynamicUi.setWidgetCurrentValues();
                this.dynamicUi.setApplicationData(this.preservedAppData);
                this.dynamicUi.setInProgress(inProgress);
                await this.dynamicUi.loadValuesForWidgets(this.sessionId);
            } else {
                const res = await this.sessionService.getSessionStateFromIota(runtimeSessionId, extension);
                await res.asyncMap(async (state) => {
                    await this.createUiModel(state);
                });                
            }

            this.uiUpdateSubject.next(this.dynamicUi);

            if (!inProgress) {
                runInAction(() => this.isPdfHandleInProgress = false);
                delete this.sessionExtension[runtimeSessionId];
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async createUiModel(state: any) {
        this.dynamicUi = new DynamicUiModel(state.response, this.sessionService, this.userProfileStore, this);
        this.dynamicUi.setWidgetCurrentValues();
        this.dynamicUi.setApplicationData(this.preservedAppData);
        await this.dynamicUi.loadValuesForWidgets(this.sessionId);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async getUiModel(state: any, applicationData: types.TabApplicationData) {
        const dynamicUi = new DynamicUiModel(state.response, this.sessionService, this.userProfileStore, this);
        dynamicUi.setApplicationData(applicationData);
        dynamicUi.setWidgetCurrentValues();
        this.preservedAppData = toJS(applicationData);
        await dynamicUi.loadValuesForWidgets(this.sessionId);
        dynamicUi.setValidateOnLoad(dynamicUi.uiModel.inputs.some(i => i.behavior?.validationAction));
        return dynamicUi;
    }
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async handleFormSubmit(widgetValues: {}, actionid: string, tabMetadata: any) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!tabMetadata.dynamicUI.actions.find((x: any) => x.id === actionid)) {
            tabMetadata.freezeUi = true;
        }
        await this.continueSession(widgetValues, actionid, tabMetadata.sessionId, tabMetadata.applicationData.appExtension);
    }

    async loadApplicationSessions(filter?: SessionFilter) {
        if (filter && !this.userProfileStore.hasAccessToAllEntities) {
            filter.userId = this.userProfileStore.currentUserProfileInfo.userId;
        }

        return await this.sessionService.getSessionsForProjects(filter);
    }

    async switchToSession(sessionId: string, extension: string) {
        runInAction(() => {
            this.renderScanResults = true;
            this.isPdfHandleInProgress = true;
            this.sessionId = sessionId;
        });

        const state: SessionState = await new Promise((resolve, reject) => {
            this.getSessionState(sessionId, resolve, reject, extension);
        });

        if (state) {
            await this.createUiModel(state);
        }

        runInAction(() => {
            this.isPdfHandleInProgress = false;
        });
    }   

    async getFileFromIotaTempStorage(path: string) {
        await this.sessionService.getFileFromIotaTempStorage(path);
    }

    async getSessionUiModel(
        runtimeSessionId: string,
        sessionId: string,
        applicationData: types.TabApplicationData
    ) {
        const {appExtension} = applicationData;
        runInAction(() => {
            this.renderScanResults = true;
            this.isPdfHandleInProgress = true;
            this.sessionId = sessionId;
        });
        // const state: SessionState = await new Promise((resolve, reject) => {

        const promises = [new Promise((resolve, reject) => {
            this.getSessionState(runtimeSessionId, resolve, reject, appExtension);
        }), this.sessionService.getComments(sessionId)] as [Promise<SessionState>, Promise<IotaAppComment[]>];
        const res = await Promise.all(promises);
        let uiModel: DynamicUiModel | undefined = undefined;
        if (res[0]) {
            uiModel = await this.getUiModel(res[0], applicationData);
            uiModel.setComments(res[1]);
        }

        runInAction(() => {
            this.isPdfHandleInProgress = false;
        });

        return uiModel;
    }
}
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import { FullScreenIcon } from '../../../custom_shared/icons';
import './FullScreenButton.less';

const FullScreenButton: React.FC = () => {
    const { documentPreviewStore } = useAppStore();

    return (
        <div
            className="full-screen-button"
            onClick={e => {
                e.stopPropagation();
                documentPreviewStore.setPreviewPosition('fullScreen');
            }}
        >
            <FullScreenIcon />
            <span>Open in full screen</span>
        </div>
    );
};

export default observer(FullScreenButton);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores';
import { useStore } from '../../../common/stores';
import { Utils } from '../../../common/misc/Utils';
import { DocumentExcel } from '../../../custom_shared/components';
import DocumentXml from '../../../documents/components/DocumentXml';
import DocumentWithMinimap from '../../../documents/components/DocumentWithMinimap';
import useDocumentBlocks from '../../../custom_shared/hooks/useDocumentBlocks';
import './DocumentPreview.less';

const PdfPreview: React.FC = observer(() => {
    const { projectsStore } = useStore();

    const { tab, sessionStore, documentVisualStore } = useAppStore();

    useDocumentBlocks({ documentVisualStore, inputs: sessionStore.initialInputs, packageId: tab.metadata.packageId });

    return (
        <div className="demo-app-pdf-preview">
            <DocumentWithMinimap
                paginationPosition="74px"
                packageId={tab.metadata.packageId}
                sessionId={sessionStore.sessionId}
                documentVisualStore={documentVisualStore}
                projectsStore={projectsStore}
                modelIsLoading={sessionStore.isLoading}
                hasLeftContainer
            />
        </div>
    );
});

const ExcelPreview: React.FC = observer(() => {
    const { tab } = useAppStore();

    return (
        <div className="demo-app-excel-preview">
            <DocumentExcel packageId={tab.metadata.packageId} packageName={tab.metadata.packageName} />
        </div>
    );
});

const XmlPreview: React.FC = observer(() => {
    const { tab, documentVisualStore } = useAppStore();

    return <DocumentXml packageId={tab.metadata.packageId} store={documentVisualStore} />;
});

const DocumentPreview: React.FC = () => {
    const { tab } = useAppStore();

    const isExcel = Utils.isExcelByExtension(tab.metadata.packageName);

    const isXml = tab.metadata.packageName.endsWith('xml');

    if (isExcel) {
        return <ExcelPreview />;
    }

    if (isXml) {
        return <XmlPreview />;
    }

    return <PdfPreview />;
};

export default observer(DocumentPreview);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import './CopyMessageButton.less';

interface Props {
    message: string;
    isVisible: boolean
}

const CopyMessageButton: React.FC<Props> = ({ message, isVisible }) => {
    if (!isVisible) {
        return null;
    }

    return (
        <div className="copy-message-button">
            <Tooltip title="Copy">
                <CopyOutlined className="copy-icon" onClick={() => navigator.clipboard.writeText(message)} />
            </Tooltip>
        </div>
    );
};

export default observer(CopyMessageButton);

import * as React from 'react';
import { useState } from 'react';
import { DynamicControlProps } from './types';
import { DatePicker, Tooltip, Form } from 'antd';

import { getConfLevelColor, ConfidenceLevel } from './ConfidenceLevel';
import { Utils } from '../../../common/misc/Utils';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { CommentOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Subscription } from 'rxjs';

export const DynamicDatePicker: React.FC<DynamicControlProps> = ({ inputParams, onHighlightBlock, highlightInputSubject, getGearIcon, handleCommentFieldClick }) => {
    let val = null;
    const currentVal = inputParams.value as string;
    if (currentVal) {
        try {
            moment.tz.setDefault('Etc/UTC');
            val = moment.tz(currentVal, 'Etc/UTC');
        } catch {
            val = undefined;
        }
        
    }
    
    const [isHighlighted, setIsHighlighted] = useState(false);

    let sub: React.MutableRefObject<Subscription | undefined> = React.useRef();
    
    React.useEffect(() => {
        if (highlightInputSubject) {
            sub.current = highlightInputSubject.subscribe(id => {
                if (id === inputParams.id) {
                    setIsHighlighted(true);
                } else {
                    setIsHighlighted(false);
                }
            });
        }
  
        return (() => {
            if (sub.current) {
                sub.current.unsubscribe();
            }
        });
    },              [highlightInputSubject, inputParams.id]);

    const [currentDate, setCurrentDate] = useState(val);

    const handleDateChange = (momentObj: Moment, dateString: string) => {
        if (dateString && dateString.length) { 
            const format = Utils.getDateFormat();
            const newMoment = moment.utc(dateString, format);
            setCurrentDate(newMoment);
            return newMoment;
        }
        return undefined;
    };

    const highlightField = () => {
        const fieldData = Utils.extractFieldData(inputParams);
        if (fieldData) {
            onHighlightBlock(fieldData, inputParams.id);
        }
    };

    const getTooltip = () => {
        if (!inputParams.behavior || !inputParams.behavior.inputTooltip) {
            return null;
        }
        return(
            <Tooltip overlayClassName="alpha-input-tooltip" title={inputParams.behavior.inputTooltip} placement="top">
                <QuestionCircleOutlined style={{marginLeft: 6, verticalAlign: 'middle'}}/>
            </Tooltip>
        );
    };

    const label = (
        <>
            <span className="alpha-doc-control-label with-controls">
                <Tooltip title={inputParams.name}>
                    <span className="alpha-doc-control-name">
                        {inputParams.name}
                    </span>
                </Tooltip>
                <span className="controls-container">
                    {inputParams.behavior && inputParams.behavior.readonly 
                        ? <></> 
                        : <ConfidenceLevel input={inputParams} /> } 
                    
                    { inputParams.behavior?.comments && (
                        <CommentOutlined className='comments-icon' onClick={() => handleCommentFieldClick!(inputParams.id)}/>
                    )}         
                     
                    {inputParams.meta && inputParams.meta.field && inputParams.value != null && (
                        <Tooltip title="View in document">
                            <i className="alpha-icon xs form-anchor" onClick={highlightField}/>
                        </Tooltip>
                    )  || getGearIcon(inputParams)}
                    {getTooltip()}
                </span>
            </span>
        </>
    );

    const getInputCssClass = () => {
        return isHighlighted ? ' iota-contract-shadow' : '';
    };

    const getPopupContainer = (trigger: HTMLElement) => {
        let container = trigger.closest('.leftColumnContainer') as HTMLElement;

        if (!container) {
            return trigger.closest('.bottomContainer') as HTMLElement;
        }

        return container;
    };

    return (
        <Form.Item
            data-id={inputParams.id}
            data-type={inputParams.controlType}
            label={label}
            colon={false}
            labelCol={{span: 24}}
            name={inputParams.id}
            rules={[{
                required: inputParams.validation && inputParams.validation.required && inputParams.validation.required.value,
                message: inputParams.validation && inputParams.validation.required && inputParams.validation.required.message || `${inputParams.name} is required`
            }]}
            initialValue={currentDate ||  null}
            getValueFromEvent={handleDateChange}
        >
            <DatePicker
                autoComplete="off"
                // eslint-disable-next-line max-len
                className={currentDate ? getConfLevelColor(inputParams) + getInputCssClass() : getInputCssClass()}
                disabled={inputParams.behavior && inputParams.behavior.readonly}
                style={Utils.buildDynamicInputStyles(inputParams)}
                getPopupContainer={getPopupContainer}
                suffixIcon={<i className="alpha-icon xs calendar-icon" style={{margin: 0}}/>}
                format={Utils.getDateFormat()}
            />
        </Form.Item>
    );
};

export default DynamicDatePicker;
import * as React from 'react';
import { SearchVisualStore } from '../../stores';
import BasicSearchResult from './BasicSearchResult';
import PeopleSearchResult from './PeopleSearchResult';
import { observer } from 'mobx-react-lite';
import FileSearchResult from './FileSearchResult';
import MessageSearchResult from './MessageSearchResult';

type Props = {
    store: SearchVisualStore;
    index: number;
    style?: React.CSSProperties
};

const SearchResultsRenderer: React.FC<Props> = ({store, index, style}) => {
    const item = store.searchResults.results[index];
    if (!item) {
        return null;
    }

    switch (item.type) {
    case 'BASIC':
        return (<BasicSearchResult store={store} result={item} style={style} />);
    case 'PEOPLE':
        return (<PeopleSearchResult store={store} result={item} style={style} />);
    case 'FILES':
        return (<FileSearchResult store={store} result={item} style={style} />);
    case 'MESSAGES':
        return (<MessageSearchResult store={store} result={item} style={style} />);
    default: 
        return null;
    }
};

export default observer(SearchResultsRenderer);
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { findLast } from 'lodash';
import { InputBaseModel } from '../../models';
import { InputRenderer, AddDeductionButton } from '../../components';
import { TabProps } from '../../componentTypes';
import {
    CommissionInput,
    BrokerageInput,
    TaxOnPremiumInput,
    DeductionTypeInput,
    DeductionValueInput
} from '../../tab_inputs/DeductionsTabInputs';
import { LayoutRenderer } from '../../layout_renderers';
import { Utils } from '../../misc';
import { useTabForm } from '../../hooks';
import { ActionParams } from '../../types';

const addDeductionButtonKey = 'add-deduction-button';

const InputIds = {
    Commission: 'Commission',
    Brokerage: 'Brokerage',
    TaxOnPremium: 'TaxOnPremium',
    DeductionType: 'DeductionType',
    DeductionValue: 'DeductionValue'
} as const;

const InputComponents = {
    [InputIds.Commission]: CommissionInput,
    [InputIds.Brokerage]: BrokerageInput,
    [InputIds.TaxOnPremium]: TaxOnPremiumInput,
    [InputIds.DeductionType]: DeductionTypeInput,
    [InputIds.DeductionValue]: DeductionValueInput
} as const;

const isDeductionInput = (input: InputBaseModel) => {
    return input.id.startsWith(InputIds.DeductionType) || input.id.startsWith(InputIds.DeductionValue);
};

const DeductionsTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, formKey, onValuesChange } = useTabForm(tab, addFormRef);

    const getComponentRows = React.useCallback((inputs: InputBaseModel[], actionParams: ActionParams) => {
        const { basicInputs, deductionInputs } = inputs.reduce<{
            basicInputs: InputBaseModel[];
            deductionInputs: InputBaseModel[]
        }>(
            (acc, input) => {
                if (isDeductionInput(input)) {
                    acc.deductionInputs.push(input);
                } else {
                    acc.basicInputs.push(input);
                }

                return acc;
            },
            { basicInputs: [], deductionInputs: [] }
        );

        const basicInputRows = Utils.groupArray(basicInputs, 4).map(inputGroup =>
            inputGroup.map(input => (
                <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
            ))
        );

        const deductionInputRows = Utils.groupArray(deductionInputs, 2).map(inputGroup =>
            inputGroup.map(input => (
                <InputRenderer
                    key={input.id}
                    input={input}
                    component={input.id.startsWith(InputIds.DeductionType) ? DeductionTypeInput : DeductionValueInput}
                />
            ))
        );

        const rows = [...basicInputRows, ...deductionInputRows];

        const lastRow = findLast(rows, row => row.length < 4);

        const addDeductionButton = <AddDeductionButton key={addDeductionButtonKey} actionParams={actionParams} />;

        if (lastRow) {
            lastRow.push(addDeductionButton);
        } else {
            rows.push([addDeductionButton]);
        }

        return rows;
    }, []);

    return (
        <LayoutRenderer
            tab={tab}
            form={form}
            formKey={formKey}
            onValuesChange={onValuesChange}
            getComponentRows={getComponentRows}
        />
    );
};

export default observer(DeductionsTabRenderer);

import { makeObservable, observable, action } from 'mobx';
import { SectionDto } from '../../types';
import { SourceModelBase, InputBaseModel, TabBaseModel } from '..';
import { InputFactory } from '../../misc';

export default class SectionModel {
    readonly id: string;

    readonly name: string;

    inputs: InputBaseModel[] = [];

    expanded: boolean = false;

    constructor(private readonly tab: TabBaseModel, data: SectionDto, sources: SourceModelBase[]) {
        this.id = data.id;
        this.name = data.name;
        this.inputs = InputFactory.createInputs(this.tab, data.inputs, sources);

        makeObservable(this, {
            inputs: observable,
            expanded: observable,
            setExpanded: action.bound,
            toggleExpanded: action.bound,
            update: action
        });

        this.setExpanded(this.inputs.some(input => input.hasValue));
    }

    setExpanded(expanded: boolean) {
        this.expanded = expanded;
    }

    toggleExpanded() {
        this.setExpanded(!this.expanded);
    }

    getInputByGuid(guid: string) {
        return this.inputs.find(input => input.guid === guid);
    }

    update(data: SectionDto, sources: SourceModelBase[]) {
        this.inputs = InputFactory.createOrUpdateInputs(this.tab, this.inputs, data.inputs, sources);
    }

    get dto(): SectionDto {
        return {
            id: this.id,
            name: this.name,
            inputs: this.inputs.map(input => input.dto)
        };
    }
}

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../common/stores';
import { useAppStore } from '../../stores';
import DocumentWithMinimap from '../../../documents/components/DocumentWithMinimap';

interface Props {
    externalWindow?: Window
}

const DocumentViewer: React.FC<Props> = ({ externalWindow }: Props) => {
    const { projectsStore } = useStore();

    const { tab, sessionStore, documentVisualStore, documentPreviewStore } = useAppStore();

    return (
        <DocumentWithMinimap
            packageId={tab.metadata.packageId}
            sessionId={sessionStore.sessionId}
            documentVisualStore={documentVisualStore}
            projectsStore={projectsStore}
            modelIsLoading={sessionStore.isLoading}
            defaultScale={0.5}
            minScale={-1.3}
            newPageNumberOffset={40}
            targetWindow={externalWindow ?? window}
            usePageNumberInput
            showMinimapToggle
            showMinimap={documentPreviewStore.showMinimap}
            setShowMinimap={documentPreviewStore.setShowMinimap}
            fieldSearchMode={documentPreviewStore.searchInputVisible}
        />
    );
};

export default observer(DocumentViewer);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { GenericSelect } from '../../components';
import { InputModelWithSource } from '../../models';
import { InputWithSource } from '../../componentTypes';

const config = {
    label: 'Insured'
};

const InsuredInput: InputWithSource = ({ input }) => <GenericSelect label={config.label} input={input} />;

InsuredInput.meta = {
    inputType: InputModelWithSource.name
};

export default observer(InsuredInput);

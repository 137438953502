/* eslint-disable @typescript-eslint/member-ordering */
import { TabsStore, UserProfileStore } from '../../common/stores';
import { action, computed, makeObservable } from 'mobx';

export default class HomeVisualStore {
    get welcomeMsg() {
        const {firstName, lastName} = this.userProfileStore.userProfile || {};
        const userDisplayName = firstName && lastName && `${firstName} ${lastName}`;
        return userDisplayName ? `Welcome, ${userDisplayName}!` : 'Welcome!';
    }

    constructor(private tabStore: TabsStore, private userProfileStore: UserProfileStore) {
        makeObservable(this, {
            welcomeMsg: computed,
            resetTabSelection: action.bound
        });
    }

    handleTeachAlphaClick() {
        window.open(`${process.env.REACT_APP_PORTAL_URL}/projects`, '_blank');
    }

    resetTabSelection() {
        this.tabStore.resetSelection();
    }
}
import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import security from '../../../common/services/SecurityService';
import { WidgetValues } from '../../services/WidgetValuesProvider';

type Props = {
    isVisible: boolean;
    setVisible: (visible: boolean) => void;
    title?: string;
    sessionId: string;
    handleSubmit: (values: WidgetValues, action: string, username: string, pwd: string) => Promise<void>;
    widgetValues: WidgetValues | undefined;
    action: string | undefined;
    passwordOnly: boolean
};

const PreSubmitAuthorizationDialog: React.FC<Props> = ({isVisible, setVisible, sessionId, handleSubmit, widgetValues, action, passwordOnly, title = 'Credentials'}) => {
    const formId = `${sessionId}-pre-submit-form`;
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = React.useState(false);
    const usernameStorageKey = 'presubmit_dialog_username';
    const passwordStorageKey = 'presubmit_dialog_password';

    React.useEffect(() => {
        if (!fetchInfoFromLocalStorage() && passwordOnly) {
            setIsLoading(true);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            security.loadUserInfo().then((r: any) => {
                const obj = {
                    username: r.preferred_username
                };
                form.setFieldsValue(obj);
                setIsLoading(false);
            }).catch(e => {
                console.log(e);
                setIsLoading(false);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchInfoFromLocalStorage = () => {
        const username = sessionStorage.getItem(usernameStorageKey);
        const password = sessionStorage.getItem(passwordStorageKey);
        if (username) {
            const obj = {
                username,
                password
            };
            form.setFieldsValue(obj);
            return true;
        }

        return false;
    };

    const handleCancel = () => {
        setVisible(false);
        form.resetFields(['password']);
    };

    const getLabel = (name: string) => {
        return (<div className="input-label"><span className="label-title">{name}</span></div>);
    };
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFormSubmit = () => {
        form.validateFields().then((values) => {
            if (action && widgetValues) {
                sessionStorage.setItem(usernameStorageKey, values.username);
                sessionStorage.setItem(passwordStorageKey, values.password);
                handleSubmit(widgetValues, action, values.username, values.password);
            }
        });
    };

    return (
        <Modal
            title={title}
            visible={isVisible}
            closable={false}
            maskClosable={false}
            centered
            className="dynamic-row-edit-modal alpha-modal"
            onCancel={handleCancel}
            zIndex={20000}
            footer={[(
                <Button 
                    key="cancel-button"
                    className="light"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            ), (
                <Button 
                    key="submit-button"
                    type="primary" 
                    htmlType="submit"
                    form={formId}
                    disabled={isLoading}
                >
                    Submit
                </Button>
            )
            ]}
        >
            <Form className="dynamic-row-edit-form" form={form} layout="vertical" id={formId} onFinish={handleFormSubmit}>
                <div className="input-wrapper">
                    <Form.Item 
                        name="username" 
                        label={getLabel('Username')} 
                        rules={[{
                            required: true, message: 'Username is required'
                        }]}>
                        <Input readOnly={passwordOnly} disabled={isLoading} />
                    </Form.Item>
                </div>
                <div className="input-wrapper">
                    <Form.Item 
                        name="password" 
                        label={getLabel('Password')} 
                        rules={[{
                            required: true, message: 'Password is required'
                        }]}
                    >
                        <Input.Password disabled={isLoading} />
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    );
};

export default observer(PreSubmitAuthorizationDialog);
import * as React from 'react';
import { Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useSelectedElementStatus } from '../../hooks';
import { CustomInputLabel } from '..';
import './CustomSelect.less';

type CustomProps = {
    label: string;
    required?: boolean;
    topRightNode?: React.ReactNode;
    containerStyle?: React.CSSProperties;
    hasError?: boolean;
    getContainerRef?: (containerRef: React.RefObject<HTMLDivElement>) => void
};

export type CustomSelectProps = CustomProps & Omit<SelectProps<SelectValue>, 'mode' | 'placeholder'>;

const CustomSelect: React.FC<CustomSelectProps> = (props: CustomSelectProps) => {
    const { label, required, topRightNode, containerStyle, hasError, getContainerRef, ...selectProps } = props;

    const containerRef = React.useRef<HTMLDivElement>(null);

    const { hasSelectedElement } = useSelectedElementStatus(containerRef, '.ant-select-selection-item');

    React.useEffect(() => {
        if (getContainerRef) {
            getContainerRef(containerRef);
        }
    }, [getContainerRef]);

    const getContainerClassName = () => {
        let className = 'custom-select-container';

        if (hasError) {
            className += ' has-error';
        }

        return className;
    };

    return (
        <div ref={containerRef} className={getContainerClassName()} style={containerStyle}>
            {!!topRightNode && <div className="top-right-node-container">{topRightNode}</div>}

            <Select className={`custom-select ${hasSelectedElement ? 'not-empty' : ''}`} {...selectProps} />

            <CustomInputLabel label={label} required={required} />
        </div>
    );
};

export default CustomSelect;

import { z } from 'zod';
import moment from 'moment';

export default class TypeUtils {
    private static stringSchema = z.string();
    private static numberSchema = z.number();
    private static booleanSchema = z.boolean();
    private static objectSchema = z.object({});
    private static functionSchema = z.function();
    private static arraySchema = z.array(z.any());
    private static stringArraySchema = z.array(z.string());
    private static dateSchema = z.any().refine(
        value => {
            if (value instanceof Date && !isNaN(value.getTime())) {
                return true;
            }

            if (typeof value === 'string' && !isNaN(Date.parse(value))) {
                return true;
            }

            return false;
        },
        {
            message: 'Expected a valid date or date string'
        }
    );

    static isString(value: unknown): value is string {
        return TypeUtils.stringSchema.safeParse(value).success;
    }

    static isNumber(value: unknown): value is number {
        return TypeUtils.numberSchema.safeParse(value).success;
    }

    static isBoolean(value: unknown): value is boolean {
        return TypeUtils.booleanSchema.safeParse(value).success;
    }

    static isObject(value: unknown): value is object {
        return TypeUtils.objectSchema.safeParse(value).success;
    }

    static isFunction(value: unknown): value is Function {
        return TypeUtils.functionSchema.safeParse(value).success;
    }

    static isDate(value: unknown): value is Date {
        return TypeUtils.dateSchema.safeParse(value).success;
    }

    static isMoment(value: unknown): value is moment.Moment {
        return moment.isMoment(value);
    }

    static isArray(value: unknown): value is unknown[] {
        return TypeUtils.arraySchema.safeParse(value).success;
    }

    static isStringArray(value: unknown): value is string[] {
        return TypeUtils.stringArraySchema.safeParse(value).success;
    }
}

import * as React from 'react';
import { useLocalStore } from 'mobx-react-lite';
import { useStore } from '../../common/stores/context';
import { ErrorStore } from '../../common/stores';
import SearchVisualStore from './SearchVisualStore';
import { SearchService } from '../services';

function createStore(
    errorStore: ErrorStore
) {
    const searchService = new SearchService();
    const searchVisualStore = new SearchVisualStore(searchService, errorStore);

    return {
        searchVisualStore
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(null);
type Props = {
    children: React.ReactNode
};

export const SearchStoreProvider: React.FC<Props> = ({ children }) => {
    const root = useStore();
    const store = useLocalStore(() => createStore(root.errorStore));
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useSearchStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('You have forgot to use StoreProvider, shame on you.');
    }
    
    return store;
};
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, FormInstance, message } from 'antd';
import { useAppStore } from '../../stores';

interface Props {
    formRefs: React.MutableRefObject<Map<string, FormInstance<unknown>>>
}

const SendContractButton: React.FC<Props> = ({ formRefs }) => {
    const { tabStore, actionStore } = useAppStore();

    const onSubmit = async () => {
        const validations = Array.from(formRefs.current.entries()).map(async ([tabId, form]) => {
            try {
                const values = await form.validateFields();
                return { tabId, success: true, values };
            } catch (error) {
                return { tabId, success: false, error };
            }
        });

        const results = await Promise.all(validations);

        const allValid = results.every(result => result.success);

        if (allValid) {
            return actionStore.sendContract();
        }

        const firstTabId = results.find(result => !result.success)?.tabId;

        if (firstTabId) {
            tabStore.setActiveTabId(firstTabId);
        }

        message.error('Please complete all required fields to proceed');
    };

    return (
        <Button type="primary" onClick={onSubmit} style={{ height: 40 }} disabled={actionStore.actionsDisabled}>
            Submit
        </Button>
    );
};

export default observer(SendContractButton);

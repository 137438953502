import * as React from 'react';
import HomePage from '../screens/Home';
import { Route } from 'react-router';
import { context } from '../stores';
// import { context as sessionContext } from '../../sessions/stores';
// import { context as documentsContext } from '../../documents/stores';

const homeRoute = () => {
    return (
        <Route
            path={'/'}
            element={
                <context.HomeStoreProvider>
                    <HomePage />
                </context.HomeStoreProvider>
            }
        />
    );
};

export default homeRoute;
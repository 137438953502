import * as React from 'react';
import { useState, useEffect } from 'react';
import { DynamicControlProps } from './types';
import { FormInputParams, WidgetDataItem } from '../../../common/services/types';
import { Tooltip, Form } from 'antd';
import { reaction } from 'mobx';
import { getConfLevelColor, ConfidenceLevel } from './ConfidenceLevel';
import { Rule } from 'antd/lib/form';
import { Utils } from '../../../common/misc/Utils';
import { DynamicModalForm } from './DynamicModalForm';
import { CommentOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Subscription } from 'rxjs';
import { SelectWithTextSelection } from '../../../common/components/SelectWithTextSelection';
import * as _ from 'lodash';

export const DynamicDropDown: React.FC<DynamicControlProps> = ({ inputParams, onAction, ui, 
    form, onHighlightBlock, highlightInputSubject, getGearIcon, handleCommentFieldClick }) => {

    const [allItems, setAllItems] = useState<WidgetDataItem[]>([]);

    const [currentValue, setCurrentValue] = useState<{ value: string; text: string } | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [isHighlighted, setIsHighlighted] = useState(false);

    let sub: React.MutableRefObject<Subscription | undefined> = React.useRef();
    
    React.useEffect(() => {
        if (highlightInputSubject) {
            sub.current = highlightInputSubject.subscribe(id => {
                if (id === inputParams.id) {
                    setIsHighlighted(true);
                } else {
                    setIsHighlighted(false);
                }
            });
        }
  
        return (() => {
            if (sub.current) {
                sub.current.unsubscribe();
            }
        });
    },              [highlightInputSubject, inputParams.id]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const pagePromise = useRef<any>(undefined);

    useEffect(() => {
        setLoading(true);
        ui.getAllWidgetValues(inputParams.source.id).then(r => {
            setAllItems(r.data);
            setLoading(false);
        });
    
        ui.getCurrentWidgetValue(inputParams.id, inputParams.source.id).then(r => {
            const obj = {};
            obj[inputParams.id] = r === undefined ? r : ((r instanceof Array) ? (r.length ? r[0] ? r[0].value : undefined : undefined) : r.value);
            form.setFieldsValue(obj); 
            setCurrentValue(r === undefined ? r : ((r instanceof Array) ? (r.length ? r[0] : undefined) : r));
        });

        const disposer = inputParams.parentInputId && reaction(() => ui.parentBasedInputValues[inputParams.parentInputId!], () => {
            const obj = {};
            obj[inputParams.id] = undefined;
            form.setFieldsValue(obj);
  
        }) || null;
        return () => {
            if (disposer) {
                disposer();
            }
        };

    },        [form, inputParams.id, inputParams.parentInputId, inputParams.source.id, ui]);

    const highlightField = () => {
        const fieldData = Utils.extractFieldData(inputParams);
        if (fieldData) {
            onHighlightBlock(fieldData, inputParams.id);
        }     
    };

    const getModalFormControl = (params: FormInputParams) => {
        if (!params.modalForm || !ui.actions || !ui.actions.length) {
            return null;
        }

        return (
            <DynamicModalForm 
                inputParams={inputParams}
                form={form}
                getGearIcon={getGearIcon}
                onAction={onAction}
                onHighlightBlock={onHighlightBlock}
                ui={ui}
                highlightInputSubject={highlightInputSubject}
            />
        );
    };

    const getTooltip = () => {
        if (!inputParams.behavior || !inputParams.behavior.inputTooltip) {
            return null;
        }
        return(
            <Tooltip overlayClassName="alpha-input-tooltip" title={inputParams.behavior.inputTooltip} placement="top">
                <QuestionCircleOutlined style={{marginLeft: 6, verticalAlign: 'middle'}}/>
            </Tooltip>
        );
    };

    const label = (
        <>
            <span className="alpha-doc-control-label with-controls">
                <Tooltip title={inputParams.name}>
                    <span className="alpha-doc-control-name">{inputParams.name}</span>
                </Tooltip>
                <span className="controls-container">
                    {getModalFormControl(inputParams)}

                    {inputParams.behavior && inputParams.behavior.readonly 
                        ? <></> 
                        : <ConfidenceLevel input={inputParams} /> } 
                    
                    { inputParams.behavior?.comments && (
                        <CommentOutlined className='comments-icon' onClick={() => handleCommentFieldClick!(inputParams.id)}/>
                    )}
                                    
                    {inputParams.meta && inputParams.meta.field && inputParams.value != null && (
                        <Tooltip title="View in document">
                            <i className="alpha-icon xs form-anchor" onClick={highlightField}/>
                        </Tooltip>
                    )  || getGearIcon(inputParams)}
                    {getTooltip()}
                </span>
            </span>
        </>
    );

    const getInputCssClass = () => {
        let styleClass = '';
        if (currentValue) {
            styleClass += getConfLevelColor(inputParams);
        }

        if (inputParams.behavior && inputParams.behavior.style) {
            styleClass += ' colored-select';
        }

        styleClass += isHighlighted ? ' iota-contract-shadow' : '';
        return styleClass;
    };

    const setupRules = () => {
        var rules: Rule[] = [];
        rules.push({
            validator: (rule, value) => {
                if (Array.isArray(value) && value.length > 1) {
                    return Promise.reject('No more than 1 value');
                } else {
                    return Promise.resolve();
                }
            }
        });
        if (!inputParams.validation) {
            return rules; 
        }
        if (inputParams.validation.required) {
            rules.push({
                required: inputParams.validation && inputParams.validation.required && inputParams.validation.required.value,
                message: inputParams.validation && inputParams.validation.required && inputParams.validation.required.message || `${inputParams.name} is required`,
            });
        }

        if (inputParams.validation.regex) {
            rules.push({
                pattern: inputParams.validation && inputParams.validation.regex && new RegExp(inputParams.validation.regex.value),
                message: inputParams.validation && inputParams.validation.regex && inputParams.validation.regex.message || 'Invalid input format'
            });
        }

        return rules;
    };

    const actionId = inputParams.behavior && inputParams.behavior.onchange;
    let actionHandler = (value: string[] | string) => {
        if (Array.isArray(value) && value.length !== 1) {
            return;
        }
        if (actionId) {
            const actionValue = actionId.value || actionId;
            onAction(inputParams.id, actionValue, value);
        }
    };

    if (actionId && actionId.delay) {
        actionHandler = _.throttle(actionHandler, actionId.delay, {leading: false});
    }

    return (
        <>
            {inputParams.behavior?.newLine && <div className='new-line'/>}
            <Form.Item
                className={inputParams.behavior && inputParams.behavior.width === 1 ? 'wide' : ''}
                data-id={inputParams.id}
                data-type={inputParams.controlType}
                label={label}
                colon={false}
                labelCol={{span: 24}}
                name={inputParams.id}
                rules={setupRules()}
                initialValue={currentValue ? currentValue.value : undefined}
            >
                <SelectWithTextSelection
                    allowClear={inputParams.validation?.required?.value === true ? false : true}
                    mode={inputParams.behavior && inputParams.behavior.customSingleSelect ? 'tags' : undefined}
                    className={getInputCssClass()}
                    disabled={(inputParams.behavior && inputParams.behavior.readonly) || loading}
                    loading={loading}
                    showSearch
                    filterOption
                    optionFilterProp="label"
                    placeholder={''}
                    style={Utils.buildDynamicInputStyles(inputParams)}
                    getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                    onChange={actionHandler}
                    options={allItems.map(i => {
                        return {label: i.text, value: i.value}; 
                    })}
                    suffixIcon={<i className="alpha-icon xxs arrow-down-icon" style={{margin: 0, width: 8}}/>}
                />
            </Form.Item>
        </>
    );
};

export default DynamicDropDown;
